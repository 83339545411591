import { FontSizes, IStyle, mergeStyleSets } from '@fluentui/react';

const msUnit = 4;
const msGridUnit = msUnit * 2;

export const pageHeaderStyles = mergeStyleSets({
  pageHeader: {
    display: 'flex',
    flex: 'none',
    alignItems: 'flex-start',
    paddingTop: msGridUnit * 1,
    paddingLeft: msGridUnit * 3,
    paddingRight: msGridUnit * 3,
    paddingBottom: msGridUnit * 1,
    fontSize: FontSizes.size26
  } as IStyle,
  header: {
    fontSize: '24px',
    fontWeight: '700',
    margin: '8px 0px 20px 0px'
  } as IStyle
});
