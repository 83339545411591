export const API_STATUS = {
  SUCCESS: 'API Succeeded',
  FAIL: 'API Failed'
};

export const EVENTS = {
  API_CALL: 'API Call',
  PAGE_SIZE_CHANGE: 'Page Size Changed',
  SORTING_COLUMN: 'Sorting Column',
  ATTESTATION: 'Attestation',
  BUTTON_CLICK: 'Button clicked',
  LINK_CLICK: 'Link clicked',
  USER_LOGIN: 'User Login'
};

export const ATTESTATION_TYPES = {
  ENVIRONMENT: 'Environment Attestation',
  APP: 'App Attestation'
};
