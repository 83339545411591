/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AjaxResponse } from 'rxjs/internal/observable/dom/AjaxObservable';
import { Observable } from 'rxjs/internal/Observable';
import { map, mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { ajaxHttpGet, ajaxHttpGetV1, ajaxHttpPost, ajaxHttpDelete } from '../utils/ajaxUtils';
import { authenticateToken } from '../../msalConfig';
import { AUTH_SCOPE } from '../constants';

export const httpGet = (url: string): Observable<AjaxResponse> => {
  const observableFromPromise = from(authenticateToken(AUTH_SCOPE.BUILDERS_HUB));
  const ajaxOb = observableFromPromise.pipe(mergeMap((token) => ajaxHttpGet(url, token))).pipe(
    map((ajaxResponse: AjaxResponse) => {
      return ajaxResponse;
    })
  );
  return ajaxOb;
};

export const httpDelete = (url: string): Observable<AjaxResponse> => {
  const observableFromPromise = from(authenticateToken(AUTH_SCOPE.BUILDERS_HUB));
  const ajaxOb = observableFromPromise.pipe(mergeMap((token) => ajaxHttpDelete(url, token))).pipe(
    map((ajaxResponse: AjaxResponse) => {
      return ajaxResponse;
    })
  );
  return ajaxOb;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const httpPost = (url: string, body: any): Observable<AjaxResponse> => {
  const observableFromPromise = from(authenticateToken(AUTH_SCOPE.BUILDERS_HUB));
  const ajaxOb = observableFromPromise.pipe(mergeMap((token) => ajaxHttpPost(url, body, token))).pipe(
    map((ajaxResponse: AjaxResponse) => {
      return ajaxResponse;
    })
  );
  return ajaxOb;
};

export const httpGetV1 = (request: any): Observable<AjaxResponse> => {
  const observableFromPromise = from(authenticateToken(request.scope));
  const ajaxOb = observableFromPromise.pipe(mergeMap((token) => ajaxHttpGet(request.url, token))).pipe(
    map((ajaxResponse: AjaxResponse) => {
      return ajaxResponse;
    })
  );
  return ajaxOb;
};

export const httpGetV1WithGovAndAttestationURI = (request: any): Observable<AjaxResponse> => {
  const observableFromAttestScopePromise = from(authenticateToken(request.scope));
  const observableFromGovScopePromise = from(authenticateToken(request.govScope));
  let tokens: (string | null)[] = [];
  const ajaxOb = observableFromAttestScopePromise
    .pipe(
      mergeMap((attest_scope) =>
        observableFromGovScopePromise.pipe(
          mergeMap((gov_scope) => {
            tokens.push(attest_scope);
            tokens.push(gov_scope);
            return ajaxHttpGetV1(request.url, tokens);
          })
        )
      )
    )
    .pipe(
      map((ajaxResponse: AjaxResponse) => {
        return ajaxResponse;
      })
    );
  return ajaxOb;
};

export const httpDeleteV1 = (request: any): Observable<AjaxResponse> => {
  const observableFromPromise = from(authenticateToken(request.scope));
  const ajaxOb = observableFromPromise.pipe(mergeMap((token) => ajaxHttpDelete(request.url, token))).pipe(
    map((ajaxResponse: AjaxResponse) => {
      return ajaxResponse;
    })
  );
  return ajaxOb;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const httpPostV1 = (request: any, body: any): Observable<AjaxResponse> => {
  const observableFromPromise = from(authenticateToken(request.scope));
  const ajaxOb = observableFromPromise.pipe(mergeMap((token) => ajaxHttpPost(request.url, body, token))).pipe(
    map((ajaxResponse: AjaxResponse) => {
      return ajaxResponse;
    })
  );
  return ajaxOb;
};
