import { ILoading } from '../ILoading';

export interface IAppMetadata {
  displayName: string;
  createdtime: Date | null;
  appName: string;
  appType: string;
  purpose: string;
  description: string;
  justification: string;
  audience: string;

  Owner: string;
  CanEdit: string;
  primaryFTEContacts: string;
  owningTeam: string;
  businessSponsor: string;

  dataClassification: string;
  isHIPAA: boolean;
  isGDPR: boolean;
  isTented: boolean;
  isSOX: boolean;
  serviceTreeService: string;

  modifiedtime: Date | null;
  modifiedBy: string;
}

export interface IAppMetadataRequest extends ILoading {
  data: IAppMetadata;
}

export interface ISetAppMetadataRequest extends ILoading {}

export const initialAppMetadata: IAppMetadata = {
  displayName: '',
  createdtime: null,
  appName: '',
  appType: '',
  purpose: '',
  description: '',
  justification: '',
  audience: '',
  Owner: '',
  CanEdit: '',
  primaryFTEContacts: '',
  owningTeam: '',
  businessSponsor: '',
  dataClassification: '',
  isHIPAA: false,
  isGDPR: false,
  isTented: false,
  isSOX: false,
  serviceTreeService: '',
  modifiedtime: null,
  modifiedBy: ''
}
