/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { ITag } from '@fluentui/react';
import { FieldProps, getIn } from 'formik';
import { IServiceTreeObject } from '../../../shared/models/ServiceTree/IServiceTreeObject';
import { IServiceTreeOrganization } from '../../../shared/models/ServiceTree/IServiceTreeOrganization';

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

// tslint:disable-next-line:no-any
export function getErrorMessage<T = any>({ field, form }: FieldProps<T>) {
  const error = form && form.errors ? getIn(form.errors, field.name) : undefined;
  const touched = form && form.touched ? getIn(form.touched, field.name) : false;
  return touched ? error : undefined;
}

// tslint:disable-next-line:no-any
export function invokeAll(...callbacks: any[]) {
  return () => {
    for (const callback of callbacks) {
      if (callback && typeof callback === 'function') {
        callback();
      }
    }
  };
}

export function createFakeEvent({ name }: { name: string }) {
  return { target: { name } };
}

export function convertServiceTreeOrganizations_to_Tags(objects: IServiceTreeOrganization[]): ITag[] {
  if (!objects) {
    return [];
  }

  const copy = Array.from(objects);
  return copy.map((obj) => {
    return {
      name: obj.parentName + ' > ' + obj.organizationName,
      key: obj.organizationId
    } as ITag;
  });
}

export function convertServiceTreeObjects_to_Tags(objects: IServiceTreeObject[]): ITag[] {
  if (!objects) {
    return [];
  }

  const copy = Array.from(objects);
  return copy.map((obj) => {
    return {
      name: obj.serviceName + ' (' + obj.serviceId + ')',
      key: obj.serviceId
    } as ITag;
  });
}

export function containsTag(tag: ITag, tags: ITag[]) {
  if (!tags || !tags.length || tags.length === 0) {
    return false;
  }
  return tags.filter((item) => item.key === tag.key).length > 0;
}

export function removeDuplicateTags(a: ITag[], b: ITag[]): ITag[] {
  return a.filter((tag) => !containsTag(tag, b));
}
