import { Field, FieldAttributes } from 'formik';
import React from 'react';
import { css } from '@fluentui/react';
import { ButtonIconWithTooltip } from '../InfoIcon/ButtonIconWithTooltip';
import { FieldStyle } from './FormField.styles';

export interface FormFieldTooltipProps extends FieldAttributes<any> {
  tooltip: string | JSX.Element | JSX.Element[];
}

/*
 * The purpose of this component is to wrap Field components while rendering our own Label with a tooltip included.
 */
export const FormFieldWithTooltip: React.FC<FormFieldTooltipProps> = ({
  tooltip,
  label,
  name,
  required,
  ...otherProps
}) => {
  return (
    <div>
      <label htmlFor={name} style={{ fontSize: '14px', fontWeight: 600, padding: '5px 0px' }}>
        {label}
      </label>
      {required && <span style={{ color: 'rgb(164, 38, 44)', paddingRight: '2px' }}> *</span>}
      <ButtonIconWithTooltip tooltipContent={tooltip} />
      <Field className={css(FieldStyle.fieldContent)} name={name} id={name} {...otherProps} />
    </div>
  );
};
