import { createAction } from 'typesafe-actions';
import { IListApprvalMailboxSSSRequestsState } from '../../../models/MailboxSSS/IListApprvalMailboxSSSRequestsState';
import {
  COMPLETE_LIST_APPROVAL_MAILBOX_SSS_REQUESTS_STATE,
  INIT_LIST_APPROVAL_MAILBOX_SSS_REQUESTS_STATE
} from '../../constants';

export const initListApprvalMailboxSSSRequestsState = createAction(
  INIT_LIST_APPROVAL_MAILBOX_SSS_REQUESTS_STATE,
  (stateApproval: IListApprvalMailboxSSSRequestsState) => stateApproval
)<IListApprvalMailboxSSSRequestsState>();

export const completeListApprvalMailboxSSSRequestsState = createAction(
  COMPLETE_LIST_APPROVAL_MAILBOX_SSS_REQUESTS_STATE,
  () => {}
)();
