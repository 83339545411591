import React, { useEffect } from 'react';
import { IBasePickerSuggestionsProps, ITag, Label, TagPicker } from '@fluentui/react';
import { useField } from 'formik';
import { AjaxResponse } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import {
  convertServiceTreeObjects_to_Tags,
  convertServiceTreeOrganizations_to_Tags,
  removeDuplicateTags
} from './utils';
import { getServiceTreeOrganizationSearchUrl, getServiceTreeSearchUrl, httpGet } from '../../../shared/services';
import { IServiceTreeObject } from '../../../shared/models/ServiceTree/IServiceTreeObject';
import { IServiceTreeOrganization } from '../../../shared/models/ServiceTree/IServiceTreeOrganization';

export enum ServiceTreeSearchType {
  Service,
  Organization
}

export const FormServiceTreePicker: React.ReactNode = ({ ...props }) => {
  const [field, meta, helpers] = useField(props.name!);
  const picker = React.useRef(null);
  const searchType = props.searchType ? (props.searchType as ServiceTreeSearchType) : ServiceTreeSearchType.Service;
  const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: props.suggestionsHeader ? props.suggestionsHeader : 'Suggested Service Tree Objects',
    mostRecentlyUsedHeaderText: props.suggestionsHeader ? props.suggestionsHeader : 'Suggested Service Tree Objects',
    noResultsFoundText: 'No Service Tree Objects Found',
    loadingText: 'Loading',
    showRemoveButtons: false,
    suggestionsAvailableAlertText: 'Service Tree Suggestions available',
    suggestionsContainerAriaLabel: 'Suggested Service Tree Objects'
  };

  const initialItems = [] as ITag[];
  // To Do We have to explore how can we leverage state
  useEffect(() => {
    if (props.value) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      httpGet(getServiceTreeOrganizationSearchUrl(props.value))
        .pipe(
          map((res) => {
            const owningTeamTag = convertServiceTreeOrganizations_to_Tags(res.response as IServiceTreeOrganization[]);
            owningTeamTag?.forEach((element: any) => {
              initialItems.push({
                key: element.key,
                name: element.name
              } as ITag);
            });
          })
        )
        .toPromise()
        .catch(() => {});
    }
  });

  // Do all your switching up in this object for cleanliness
  const switchableData = {
    apiPromise: (input: string, callBack: (res: AjaxResponse) => ITag[]) => {
      switch (searchType) {
        case ServiceTreeSearchType.Service: {
          return httpGet(getServiceTreeSearchUrl(input)).pipe(map(callBack)).toPromise();
        }
        case ServiceTreeSearchType.Organization: {
          return httpGet(getServiceTreeOrganizationSearchUrl(input)).pipe(map(callBack)).toPromise();
        }
        default: {
          return httpGet(getServiceTreeSearchUrl(input)).pipe(map(callBack)).toPromise();
        }
      }
    },
    conversionFunction: (response: any) => {
      switch (searchType) {
        case ServiceTreeSearchType.Service: {
          return convertServiceTreeObjects_to_Tags(response as IServiceTreeObject[]);
        }
        case ServiceTreeSearchType.Organization: {
          return convertServiceTreeOrganizations_to_Tags(response as IServiceTreeOrganization[]);
        }
        default: {
          return convertServiceTreeObjects_to_Tags(response as IServiceTreeObject[]);
        }
      }
    }
  };

  const loadDataPromise = (input: string, currentTags: ITag[] | undefined): ITag[] | Promise<ITag[]> => {
    const callBack = (res: AjaxResponse) => {
      if (input && input.length > 1) {
        const limitResults = props.limitResults;
        const convertedList = switchableData.conversionFunction(res.response);
        let filteredTags: ITag[] = convertedList;
        filteredTags = removeDuplicateTags(filteredTags, currentTags!);
        filteredTags = limitResults ? filteredTags.slice(0, limitResults) : filteredTags;
        return filteredTags;
      }
      return [];
    };
    return switchableData.apiPromise(input, callBack);
  };

  const getTextFromItem = (item: ITag): string => {
    return String(item.key);
  };

  return (
    <div>
      {props.label && <Label required={props.required}>{props.label}</Label>}
      <TagPicker
        {...field}
        {...props}
        defaultSelectedItems={initialItems}
        removeButtonAriaLabel="Remove"
        onResolveSuggestions={loadDataPromise}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={suggestionProps}
        itemLimit={1}
        componentRef={picker}
        resolveDelay={200}
        onChange={(tags) => {
          const valueToSet = tags!.map((tag) => tag.key).join(';');
          helpers.setValue(valueToSet);
          helpers.setTouched(true, false); // make error show up, but dont double trigger validation
        }}
        inputProps={{
          'aria-label': props.label,
          'aria-disabled': 'false',
          'aria-readonly': 'false',
          'aria-required': props.ariaRequired
        }}
        styles={{
          text: {
            borderColor: 'rgb(100, 100, 100)'
          }
        }}
      />
      {meta.touched && meta.error ? (
        <div
          className="error"
          style={{
            fontSize: '12px',
            fontWeight: 400,
            color: 'rgb(168, 0, 0)',
            margin: '0px,0px,0px,0px',
            paddingTop: '5px',
            display: 'flex',
            alignItems: 'center'
          }}
          role='alert'
          aria-live='assertive'
        >
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};
