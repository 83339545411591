import { ICommandBarStyles, IStyle, mergeStyleSets } from '@fluentui/react';

export const MailboxSSSGAApprovalPageStyle = mergeStyleSets({
  pageBackground: {
    selectors: {
      ':global(.ms-DetailsList)': {
        overflow: 'visible'
      }
    }
  } as IStyle
});

export const MailboxSSSGAApprovalPageCommandBarStyle = {
  // root: { backgroundColor: CoherenceTheme.palette.neutralPrimaryAlt }
} as ICommandBarStyles;

export const MailboxSSSGAApprovalPageContentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    position: 'fixed',
    top: '25vh',
    width: '50vw'
  },
  submit: {
    left: '23vw',
    marginBottom: '1.5vw'
  },
  input: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px'
  }
});
