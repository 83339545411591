import { createAction } from 'typesafe-actions';
import { IEnvironmentAttestation } from '../../../models/Environment/IEnvironmentAttestationRequest';
import { IHttpError } from '../../../models/IHttpError';
import {
  SET_ENVIRONMENT_ATTESTATION,
  SET_ENVIRONMENT_ATTESTATION_FAIL,
  SET_ENVIRONMENT_ATTESTATION_SUCCESS
} from '../../constants';

export const setEnvironmentAttestation = createAction(
  SET_ENVIRONMENT_ATTESTATION,
  (data: IEnvironmentAttestation, operationType: string, assetType: string, actionType: string) => {
    return { data: data, operationType: operationType, assetType: assetType, actionType: actionType };
  }
)();

export const setEnvironmentAttestationFailed = createAction(
  SET_ENVIRONMENT_ATTESTATION_FAIL,
  (err: IHttpError) => err
)<IHttpError>();
export const setEnvironmentAttestationSucceeded = createAction(SET_ENVIRONMENT_ATTESTATION_SUCCESS, (data) => {return {data}})();
