import { isActionOf } from 'typesafe-actions';
import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, catchError, mergeMap } from 'rxjs/operators';
import { IRootState } from '../../IRootState';
import { RootActions } from '../../RootActions';
import { setEnvironmentAttestationUrl, httpPostV1 } from '../../../services';
import {
  setEnvironmentAttestation,
  setEnvironmentAttestationFailed,
  setEnvironmentAttestationSucceeded
} from '../../actions/environment/environmentAttestation';
import { AUTH_SCOPE } from '../../../constants';
import { toastSuccess } from '../../../utils/tools/toast';

export const SetEnvironmentAttestationEpic: Epic<RootActions, RootActions, IRootState> = (_action$, _store) =>
  _action$.pipe(
    filter(isActionOf(setEnvironmentAttestation)),
    mergeMap((_action$) => {
      const request = {
        url: setEnvironmentAttestationUrl(_action$.payload.assetType),
        scope: AUTH_SCOPE.BUILDERS_HUB
      };
      return from(
        httpPostV1(request, _action$.payload.data).pipe(
          mergeMap(() => {
            toastSuccess(
              `${_action$.payload.operationType} for Environment : ${_action$.payload.data?.environmentName} has been ${
                _action$.payload.actionType === 'submit' ? 'submitted' : 'saved'
              } successfully.`
            );
            return of(setEnvironmentAttestationSucceeded(_action$.payload.data));
          }),
          catchError((error) => {
            return of(setEnvironmentAttestationFailed(error));
          })
        )
      );
    })
  );

export default [SetEnvironmentAttestationEpic];
