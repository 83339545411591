import { createReducer } from 'typesafe-actions';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { getSharePointDetail, getSharePointDetailFailed, getSharePointDetailSucceeded } from '../../actions';
import { initialSharePointDetail } from '../../../models/SharepointSites/ISharePointDetailRequests';


export const getSharePointDetailReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(getSharePointDetail, (state) => {
    return {
      ...state,
      getSharePointDetailRequestState: {
        ...state.getSharePointDetailRequestState,
        data: initialSharePointDetail,
        isLoading: true,
        loadingState: LoadingState.loading
      }
    };
  })
  .handleAction(getSharePointDetailSucceeded, (state, action) => {
    return {
      ...state,
      getSharePointDetailRequestState: {
        ...state.getSharePointDetailRequestState,
        data: action.payload,
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(getSharePointDetailFailed, (state, action) => {
    return {
      ...state,
      getSharePointDetailRequestState: {
        ...state.getSharePointDetailRequestState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    };
  });
