import { IDropdownOption } from '@fluentui/react';

export const ENVIRONMENT_TYPE_OPTIONS: IDropdownOption[] = [
  {
    key: 'Power Platform(without Dataverse)',
    text: 'Power Platform(without Dataverse)'
  },
  {
    key: 'Dataverse Org',
    text: 'Dataverse Org'
  },
  {
    key: 'D365',
    text: 'D365'
  }
];

export const ENVIRONMENT_REGION_OPTIONS: IDropdownOption[] = [
  {
    key: 'United States',
    text: 'United States'
  },
  {
    key: 'Europe',
    text: 'Europe'
  },
  {
    key: 'Asia',
    text: 'Asia'
  },
  {
    key: 'Australia',
    text: 'Australia'
  },
  {
    key: 'India',
    text: 'India'
  },
  {
    key: 'Japan',
    text: 'Japan'
  },
  {
    key: 'Canada',
    text: 'Canada'
  },
  {
    key: 'United Kingdom',
    text: 'United Kingdom'
  },
  {
    key: 'Preview (United States)',
    text: 'Preview (United States)'
  },
  {
    key: 'South America',
    text: 'South America'
  },
  {
    key: 'France',
    text: 'France'
  },
  {
    key: 'Germany',
    text: 'Germany'
  },
  {
    key: 'Switzerland',
    text: 'Switzerland'
  },
  {
    key: 'Norway',
    text: 'Norway'
  },
  {
    key: 'Korea',
    text: 'Korea'
  },
  {
    key: 'South Africa',
    text: 'South Africa'
  },
  {
    key: 'United Arab Emirates(UAE)',
    text: 'United Arab Emirates(UAE)'
  }
];

export const APP_TYPE_OPTIONS: IDropdownOption[] = [
  {
    key: 'Canvas',
    text: 'Canvas'
  },
  {
    key: 'Canvas - Sharepoint',
    text: 'Canvas - Sharepoint'
  },
  {
    key: 'Model Driven',
    text: 'Model Driven'
  }
];

export const ENVIRONMENT_PURPOSE_OPTIONS: IDropdownOption[] = [
  {
    key: 'Pre-Production',
    text: 'Pre-Production'
  },
  {
    key: 'Production',
    text: 'Production'
  }
];

export const ENVIRONMENT_SKU_OPTIONS: IDropdownOption[] = [
  {
    key: 'Sandbox',
    text: 'Sandbox'
  },
  {
    key: 'Production',
    text: 'Production'
  }
];

export const ENVIRONMENT_DLP_POLICY_OPTIONS: IDropdownOption[] = [
  {
    key: 'Enterprise Standard Developer',
    text: 'Enterprise Standard Developer'
  }
  // {
  //   key: 'CSEO UltraLow',
  //   text: 'CSEO UltraLow'
  // },
  // {
  //   key: 'CSEO Low',
  //   text: 'CSEO Low'
  // },
  // {
  //   key: 'CSEO Medium',
  //   text: 'CSEO Medium'
  // },
  // {
  //   key: 'CSEO Medium Isolated',
  //   text: 'CSEO Medium Isolated'
  // }
];

export const ENVIRONMENT_CURRENCY_OPTIONS: IDropdownOption[] = [
  {
    key: 'USD',
    text: 'USD'
  },
  {
    key: 'AED',
    text: 'AED'
  },
  {
    key: 'AFN',
    text: 'AFN'
  },
  {
    key: 'ALL',
    text: 'ALL'
  },
  {
    key: 'XDR',
    text: 'XDR'
  },
  {
    key: 'XCD',
    text: 'XCD'
  },
  {
    key: 'AMD',
    text: 'AMD'
  },
  {
    key: 'ARS',
    text: 'ARS'
  },
  {
    key: 'EUR',
    text: 'EUR'
  },
  {
    key: 'AUD',
    text: 'AUD'
  },
  {
    key: 'AZN',
    text: 'AZN'
  },
  {
    key: 'BAM',
    text: 'BAM'
  },
  {
    key: 'BDT',
    text: 'BDT'
  },
  {
    key: 'BGN',
    text: 'BGN'
  },
  {
    key: 'BHD',
    text: 'BHD'
  },
  {
    key: 'BND',
    text: 'BND'
  },
  {
    key: 'BOB',
    text: 'BOB'
  },
  {
    key: 'BRL',
    text: 'BRL'
  },
  {
    key: 'BTN',
    text: 'BTN'
  },
  {
    key: 'BWP',
    text: 'BWP'
  },
  {
    key: 'CAD',
    text: 'CAD'
  },
  {
    key: 'CNY',
    text: 'CNY'
  },
  {
    key: 'GBP',
    text: 'GBP'
  },
  {
    key: 'JPY',
    text: 'JPY'
  }
];

export const ENVIRONMENT_DATA_CLASSIFICATION_OPTIONS: IDropdownOption[] = [
  {
    key: 'General',
    text: 'General'
  },
  {
    key: 'Public',
    text: 'Public'
  },
  {
    key: 'Highly Confidential',
    text: 'Highly Confidential'
  },
  {
    key: 'Confidential',
    text: 'Confidential'
  },
  {
    key: 'Non-Business',
    text: 'Non-Business'
  }
];

export const ENVIRONMENT_LANGUAGE_OPTIONS: IDropdownOption[] = [
  {
    key: '1033',
    text: 'English (United States)'
  },
  {
    key: '1025',
    text: 'العربية (المملكة العربية السعودية)'
  },
  {
    key: '1069',
    text: 'euskara (euskara)'
  },
  {
    key: '1026',
    text: 'български (България)'
  },
  {
    key: '1027',
    text: 'català (català)'
  },
  {
    key: '3076',
    text: '中文(香港特別行政區)'
  },
  {
    key: '2052',
    text: '中文(中国)'
  },
  {
    key: '1028',
    text: '中文(台灣)'
  },
  {
    key: '1050',
    text: 'hrvatski (Hrvatska)'
  },
  {
    key: '1029',
    text: 'čeština (Česko)'
  },
  {
    key: '1030',
    text: 'dansk (Danmark)'
  },
  {
    key: '1043',
    text: 'Nederlands (Nederland)'
  },
  {
    key: '1061',
    text: 'eesti (Eesti)'
  },
  {
    key: '1035',
    text: 'suomi (Suomi)'
  },
  {
    key: '1036',
    text: 'français (France)'
  },
  {
    key: '1110',
    text: 'galego (galego)'
  },
  {
    key: '1031',
    text: 'Deutsch (Deutschland)'
  },
  {
    key: '1032',
    text: 'Ελληνικά (Ελλάδα)'
  },
  {
    key: '1037',
    text: 'עברית (ישראל)'
  },
  {
    key: '1081',
    text: 'हिन्दी (भारत)'
  },
  {
    key: '1038',
    text: 'magyar (Magyarország)'
  },
  {
    key: '1040',
    text: 'italiano (Italia)'
  },
  {
    key: '1041',
    text: '日本語 (日本)'
  },
  {
    key: '1087',
    text: 'қазақ тілі (Қазақстан)'
  },
  {
    key: '1042',
    text: '한국어(대한민국)'
  },
  {
    key: '1062',
    text: 'latviešu (Latvija)'
  },
  {
    key: '1063',
    text: 'lietuvių (Lietuva)'
  },
  {
    key: '1044',
    text: 'norsk bokmål (Norge)'
  },
  {
    key: '1045',
    text: 'polski (Polska)'
  },
  {
    key: '1046',
    text: 'português (Brasil)'
  },
  {
    key: '2070',
    text: 'português (Portugal)'
  },
  {
    key: '1048',
    text: 'română (România)'
  },
  {
    key: '1049',
    text: 'русский (Россия)'
  },
  {
    key: '2074',
    text: 'srpski (Srbija i Crna Gora (Bivša))'
  },
  {
    key: '1051',
    text: 'slovenčina (Slovensko)'
  },
  {
    key: '1060',
    text: 'slovenščina (Slovenija)'
  },
  {
    key: '3082',
    text: 'español (España, alfabetización internacional)'
  },
  {
    key: '1053',
    text: 'svenska (Sverige)'
  },
  {
    key: '1054',
    text: 'ไทย (ไทย)'
  },
  {
    key: '1055',
    text: 'Türkçe (Türkiye)'
  },
  {
    key: '1058',
    text: 'українська (Україна)'
  },
  {
    key: '1066',
    text: 'Tiếng Việt (Việt Nam)'
  },
  {
    key: '3098',
    text: 'српски (Србија и Црна Гора (Бивша))'
  },
  {
    key: '1086',
    text: 'Melayu (Malaysia)'
  },
  {
    key: '1057',
    text: 'Indonesia (Indonesia)'
  }
];
