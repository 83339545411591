import { IColumn } from '@fluentui/react';
import { IMailboxSSSRequest } from '../../shared/models/MailboxSSS/IMailboxSSSRequest';

export interface ITableListMailboxSSSRequestsColumn extends IColumn {
  fieldName: keyof IMailboxSSSRequest;
  onRender?(item: IMailboxSSSRequest): React.ReactNode;
}

export const getTableListMailboxSSSRequestsColumns = () => {
  const columns: ITableListMailboxSSSRequestsColumn[] = [
    {
      key: 'mailboxEmail',
      name: 'Mailbox Email',
      fieldName: 'mailboxEmail',
      headerClassName: 'tbl-header',
      minWidth: 80,
      maxWidth: 240,
      isResizable: true
    }
  ];
  columns.push({
    key: 'crmOrganizationUrl',
    name: 'D365 URL',
    fieldName: 'crmOrganizationUrl',
    headerClassName: 'tbl-header',
    minWidth: 80,
    maxWidth: 300,
    isResizable: true
  });
  columns.push({
    key: 'enableIncomingEmailDelivery',
    name: 'Enable IncomingEmail',
    fieldName: 'enableIncomingEmailDelivery',
    headerClassName: 'tbl-header',
    minWidth: 80,
    maxWidth: 160,
    isResizable: true
  });
  columns.push({
    key: 'enableOutgoingEmailDelivery',
    name: 'Enable OutgoingEmail',
    fieldName: 'enableOutgoingEmailDelivery',
    headerClassName: 'tbl-header',
    minWidth: 80,
    maxWidth: 160,
    isResizable: true
  });
  columns.push({
    key: 'enableActDeliveryMethod',
    name: 'Enable ActDelivery',
    fieldName: 'enableActDeliveryMethod',
    headerClassName: 'tbl-header',
    minWidth: 80,
    maxWidth: 160,
    isResizable: true
  });
  columns.push({
    key: 'processEmailFrom',
    name: 'Process Email From',
    fieldName: 'processEmailFrom',
    headerClassName: 'tbl-header',
    minWidth: 80,
    maxWidth: 240,
    isResizable: true
  });
  columns.push({
    key: 'description',
    name: 'Description',
    fieldName: 'description',
    headerClassName: 'tbl-header',
    minWidth: 80,
    maxWidth: 240,
    isResizable: true
  });
  return columns;
};

// crmOrganizationUrl: string;
// description: string;
// emailServerProfileValidated: boolean;
// enableActDeliveryMethod: boolean;
// enableIncomingEmailDelivery: boolean;
// enableOutgoingEmailDelivery: boolean;
// mailboxEmail: string;
// mailboxOwnerApproval: boolean;
// processEmailFrom: Date;
// requestAction: string;
// singleMultipleRequestType: string
// createdOn: Date;
// updatedOn: Date;
