import { Field, FieldAttributes } from 'formik';
import React from 'react';
import { css } from '@fluentui/react';
import { FieldStyle } from './FormField.styles';

export interface FormFieldTooltipProps extends FieldAttributes<any> {
  tooltip: string | JSX.Element | JSX.Element[];
}

export const FormFieldForChoiceBox: React.FC<FormFieldTooltipProps> = ({
  tooltip,
  label,
  name,
  required,
  ...otherProps
}) => {
  return (
    <div>
      <Field className={css(FieldStyle.fieldContent)} name={name} id={name} label={label} tooltip={tooltip} {...otherProps} />
    </div>
  );
};
