import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { getPolicies, getPoliciesFail, getPoliciesSuccess } from '../../actions';
import { IRootState } from '../../IRootState';
import { RootActions } from '../../RootActions';
import { getPoliciesUrl, httpGetV1 } from '../../../services';
import { IPolicy } from '../../../models/policies/IPolicies';
import { AUTH_SCOPE } from '../../../constants';

export const GetPoliciesEpic: Epic<RootActions, RootActions, IRootState> = (action$, _store) =>
  action$.pipe(
    filter(isActionOf(getPolicies)),
    mergeMap((_action$) => {
      const request = {
        url: getPoliciesUrl(),
        scope: AUTH_SCOPE.GOVERNANCE
      };
      return from(httpGetV1(request))
        .pipe(
          map((res) => {
            return res.response as IPolicy[];
          })
        )
        .pipe(
          mergeMap((res) => {
            return of(getPoliciesSuccess(res));
          }),
          catchError((error) => {
            return of(getPoliciesFail(error));
          })
        );
    })
  );

export default [GetPoliciesEpic];
