export const checkUnsavedChanges = (isFormValuesChanged: boolean) => {
  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (isFormValuesChanged) {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = ''; // This line is required for Chrome browser, because Chrome requires a string to be returned to show a message
    }
  };

  window.addEventListener('beforeunload', handleBeforeUnload);

  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };
};
