import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import {
  getRequestStatusEnvironment,
  getRequestStatusEnvironmentFail,
  getRequestStatusEnvironmentSuccess
} from '../../actions';
import { IRootState } from '../../IRootState';
import { RootActions } from '../../RootActions';
import { getRequestStatusEnvironmentUrl, httpGet } from '../../../services';
import { IRequestStatusEnvironment } from '../../../models/myRequestStatus/IRequestStatusEnvironment';

export const GetRequestStatusEnvironmentEpic: Epic<RootActions, RootActions, IRootState> = (action$, _store) =>
  action$.pipe(
    filter(isActionOf(getRequestStatusEnvironment)),
    mergeMap((action$) => {
      return from(httpGet(getRequestStatusEnvironmentUrl(action$.payload)))
        .pipe(
          map((res) => {
            return { requestStatusEnvironment: res.response as IRequestStatusEnvironment };
          })
        )
        .pipe(
          mergeMap(({ requestStatusEnvironment }) => {
            return of(getRequestStatusEnvironmentSuccess(requestStatusEnvironment));
          }),
          catchError((error) => {
            return of(getRequestStatusEnvironmentFail(error));
          })
        );
    })
  );

export default [GetRequestStatusEnvironmentEpic];
