export interface ICheckMemberInSGResponse {
    isSecurityGroupValid: boolean;
    securityGroupErrorResponse: string | null;
    isMemberInSecurityGroup: boolean;
    nonMembersInSecurityGroup: string[] | null;
}

export const checkMemberInSGResponseInitiaValues: ICheckMemberInSGResponse = {
    isSecurityGroupValid: false,
    securityGroupErrorResponse: null,
    isMemberInSecurityGroup: false,
    nonMembersInSecurityGroup: null
}