import { IStyle, mergeStyleSets } from '@fluentui/react';

export const OneAccessRevokePageStyle = mergeStyleSets({
  pageBackground: {
    selectors: {
      ':global(.ms-DetailsList)': {
        overflow: 'visible'
      }
    }
  } as IStyle,
  powerbi: {
    backgroundColor: 'white!important',
    marginTop: '0.1vh',
    marginLeft: '2%',
    height: '93vh',
    width: '165.33vh',
    selectors: {
      ':global(.ms-DetailsList)': {
        overflow: 'visible'
      }
    }
  } as IStyle
});

export const LoadingStyle = mergeStyleSets({
  ProcessingLoading: {
    color: '#3abefc',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30vh',
  } as IStyle
});


