import { createAction } from 'typesafe-actions';
import {
  DELETE_APP,
  DELETE_APP_FAIL,
  DELETE_APP_SUCCESS,
  GET_APP_LIST_OWNED,
  GET_APP_LIST_OWNED_FAIL,
  GET_APP_LIST_OWNED_SUCCESS,
  GET_APP_METADATA,
  GET_APP_METADATA_FAIL,
  GET_APP_METADATA_SUCCESS,
  SET_APP_METADATA,
  SET_APP_METADATA_FAIL,
  SET_APP_METADATA_SUCCESS
} from '../../constants';
import { IApp } from '../../../models/AppsList/IAppsRequest';
import { IHttpError } from '../../../models/IHttpError';
import { IAppMetadata } from '../../../models/AppsList/IAppMetadataRequest';

export const getMyApps = createAction(GET_APP_LIST_OWNED, () => {})();
export const getMyAppsFailed = createAction(GET_APP_LIST_OWNED_FAIL, (err: IHttpError) => err)<IHttpError>();
export const getMyAppsSucceeded = createAction(GET_APP_LIST_OWNED_SUCCESS, (apps: IApp[]) => apps)<IApp[]>();

export const deleteMyApp = createAction(DELETE_APP, (appId: string) => appId)<string>();
export const deleteMyAppFailed = createAction(DELETE_APP_FAIL, (err: IHttpError) => err)<IHttpError>();
export const deleteMyAppSucceeded = createAction(DELETE_APP_SUCCESS, () => {})();

export const getAppMetadata = createAction(GET_APP_METADATA, (appId: string) => appId)<string>();
export const getAppMetadataFailed = createAction(GET_APP_METADATA_FAIL, (err: IHttpError) => err)<IHttpError>();
export const getAppMetadataSucceeded = createAction(GET_APP_METADATA_SUCCESS, (data: IAppMetadata) => data)<IAppMetadata>();

export const setAppMetadata = createAction(SET_APP_METADATA, (appId: string, data: IAppMetadata) => {return {appId: appId, data: data}})();
export const setAppMetadataFailed = createAction(SET_APP_METADATA_FAIL, (err: IHttpError) => err)<IHttpError>();
export const setAppMetadataSucceeded = createAction(SET_APP_METADATA_SUCCESS, () => {})();
