/* eslint-disable */
import * as Yup from 'yup';

export const EnvironmentAttestationSubmitValidation = Yup.object({
  justification: Yup.string()
    .min(5, 'Justification should be more than 5 characters.')
    .max(500, 'Justification should not be more than 500 characters.')
    .required('Justification is required.'),
  description: Yup.string()
    .min(5, 'Description should be more than 5 characters.')
    .max(500, 'Description should not be more than 500 characters.')
    .required('Description is required.'),
  audience: Yup.string()
    .min(5, 'Audience should be more than 5 characters.')
    .max(50, 'Audience should not be more than 50 characters.')
    .required('Audience is required.')
    .nullable(),
  owningTeam: Yup.string().required('Owning team must be specified.').nullable(),
  dataClassification: Yup.string().required('Data classification must be specified.').nullable(),
  isAttestationConfirmed: Yup.bool().isTrue('Attestation checkbox must be checked.')
});

export const EnvironmentAttestationSaveValidation = Yup.object({});
