import { mergeStyleSets, IStackTokens } from '@fluentui/react';
import { SCREEN_SIZE } from '../../../../shared/constants/ScreenSize';

export const violaltionStyle = mergeStyleSets({
  labelSize: {
    fontSize: '14px'
  },
  disabledLabel: {
    color: '#646464'
  },
  platformLabel: {
    backgroundColor: '#EBEBEB;',
    padding: '8px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '300'
  },
  rescanIconStyle: {
    lineHeight: '32px',
    margin: '2px',
    color: ' #0078D4',
    cursor: 'pointer'
  },
  rescanLabel: {
    margin: '2px 4px'
  },
  openAppIconStyle: {
    height: '20px',
    width: '20px',
    fontSize: '20px',
    color: '#0078D4',
    cursor: 'pointer',
    lineHeight: '22px'
  },
  detailWrapper: {
    margin: '0px 4% 0px 12px',
    padding: '0px'
  },
  p10: {
    padding: '10px'
  },
  dueDateIcon: {
    lineHeight: '22px',
    padding: '0px 3px',
    margin: '0 0 0 0.8%'
  },
  dueDate: {
    display: 'flex'
  },
  p0: {
    padding: '0px'
  },
  m5: {
    margin: '0px 5%'
  },
  distanceToSidePannel: {
    margin: '0px 12px'
  },
  dueDateValue: {
    margin: '0px',
    padding: '0px 8px'
  },
  spinnerCenter: {
    display: 'flex',
    justifyContent: 'center',
    height: '80vh',
    alignItems: 'center'
  },
  priorBreadcrumb: {
    fontWeight: '600',
    fontSize: window.innerWidth < SCREEN_SIZE.XXS ? '18px' : '15px',
    padding: window.innerWidth < SCREEN_SIZE.XXS ? '0px 6px' : '0px 4px',
    width: window.innerWidth < SCREEN_SIZE.XXS ? '100%' : '50%'
  },
  breadcrumb: {
    fontSize: window.innerWidth < SCREEN_SIZE.XXS ? '18px' : '15px',
    fontWeight: '400',
    color: '#605E5C',
    cursor: 'pointer'
  },
  // to do: Custom lable css for top header at near breadcrumb
  // breadcrumbComplianceState: {
  //   backgroundColor: '#FDE7E9',
  //   fontSize: '12px',
  //   padding: '6px',
  //   margin: '0px 3px',
  //   borderRadius: '4px',
  //   color: '#323130'
  // },
  displaySpaceBetween: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  infoLabelWrapper: {
    width: '42%',
    padding: '4px 0px',
    margin: '14px 8px'
  },
  infoIcon: {
    padding: '0px 3px'
  },
  infoLabel: {
    color: '#292827',
    fontSize: '14px'
  },
  rescan: {
    margin: '0px 6%'
  },
  noHorizontalScroll: {
    overflowX:'hidden'
  }
});

export const endSpacingTokens: IStackTokens = {
  childrenGap: 20,
  padding: 8
};
