import {
  ScrollablePane,
  ActionButton,
  FocusZone,
  Label,
  css,
  Spinner,
  SpinnerSize
} from '@fluentui/react';
import * as React from 'react';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { IDisplayItemRoleAssignment } from '../../shared/models/aadPAP/roleAssignment';
import { IOneAccessRevokeProps, IOneAccessRevokeState } from './OneAccessRevoke.types';
import { LoadingStyle, OneAccessRevokePageStyle } from './OneAccessRevoke.styles';
import { _Styles } from '../../shared/styles/Page.styles';
import { parseQueryString } from '../../shared/utils/tools/string';

export class OneAccessRevoke extends React.Component<IOneAccessRevokeProps, IOneAccessRevokeState> {
  private pageFocusRef = React.createRef<HTMLDivElement>();

  constructor(props: IOneAccessRevokeProps) {
    super(props);
    this.state = {
      message: ''
    };
  }

  // eslint-disable-next-line react/no-deprecated
  public componentWillMount(): void {
    const search = parseQueryString(this.props.location.search.substring(1, this.props.location.search.length)) as { Message: string };
    this.setState({
      message: search.Message
    });
  }

  public componentDidMount(): void {
    this._focus();
  }

  public componentDidUpdate(): void {
  }

  public render(): React.ReactNode {
    return (
      <ScrollablePane
        className={this.props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}
      >
        <main
          data-automation-id="OneAccessRevokePage"
          className={css('ms-ListPage', OneAccessRevokePageStyle.pageBackground)}
          tabIndex={-1}
        >
          <div className={'ms-ListPageHeader'}>
            <div
              className={
                'ms-PageTitle'
              }
            >
              <div
                role="heading"
                tabIndex={-1}
                aria-level={1}
                ref={this.pageFocusRef}
                className={
                  'ms-Title'}
              >
                <PageHeader pageTitle={'Revoke Assignment'} />
                <br /><br />
              </div>
            </div>
          </div>

          {!this.props.revokedRoleAssignment.isLoading ?
            <div className={css('ms-Grid')}>
              <div className={css('ms-Grid-row')}>
                <FocusZone>
                  <Label disabled={false}>
                    <b>{this.state.message} </b>
                  </Label>

                  <ActionButton
                    iconProps={{ iconName: 'send' }}
                    allowDisabledFocus={false}
                    onClick={this.onRevoke}
                  > Revoke </ActionButton>
                  <ActionButton
                    iconProps={{ iconName: 'cancel' }}
                    allowDisabledFocus={true}
                    onClick={this.onCancel}
                  >Cancel</ActionButton>
                </FocusZone>
              </div>
            </div> :
            <Spinner className={LoadingStyle.ProcessingLoading} size={SpinnerSize.large} label={'Loading ...'} />
          }
        </main >
      </ScrollablePane>
    );
  }

  private _focus = () => {
    const node = this.pageFocusRef.current;
    if (node) {
      node.focus();
    }
  }

  private onRevoke = () => {
    const params = this.props.match.params;
    this.props.deleteRoleAssignment({ id: params.id, appId: params.appId } as IDisplayItemRoleAssignment);
  }

  private onCancel = () => {
    window.location.href = '/one-access-report/app-admin?isRevokePage=true';
  }
}