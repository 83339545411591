import { ISelectionOptions, Selection } from '@fluentui/react';

interface ICustomSelectionOptions extends ISelectionOptions {
  onAnnounce?: (info: string) => void;
}
export class CustomSelection extends Selection {
  private _onAnnounce: ((info: string) => void) | undefined;

  constructor(options: ICustomSelectionOptions = {}) {
    super(options);
    this._onAnnounce = options.onAnnounce;
  }

  public toggleIndexSelected(index: number): void {
    super.toggleIndexSelected(index);
    const idx = this.getSelectedIndices().findIndex((i) => i === index);
    const isSelected = idx > -1 ? 'selected' : 'unselected';
    // const item = this.getItems()[index] as IDisplayItemRoleAssignment;
    if (this._onAnnounce) {
      this._onAnnounce(
        // `Role Assignment with Id ${item.id} and Definition Name ${
        //   item.nameRD
        // }, is ${isSelected} `
        `Row Number ${index + 1}, is ${isSelected}  `
      );
    }
  }

  public setAllSelected(isAllSelected: boolean) {
    super.setAllSelected(isAllSelected);
    const isSelected = isAllSelected ? 'selected' : 'unselected';
    if (this._onAnnounce) {
      this._onAnnounce(`All ${this.getItems().length} rows, are ${isSelected} `);
    }
  }
}
