/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Field } from 'formik';
import { css } from '@fluentui/react';
import { EnvironmentRequestBasicInfoFormStyle } from './EnvironmentRequestForm.styles';
import { EnvironmentRequestFormProps } from '../EnvironmentRequestForm.types';
import { ServiceTreeSearchType } from '../../Items/FormServiceTreePicker';
import { SCREEN_SIZE } from '../../../../shared/constants/ScreenSize';
import { FormFieldWithTooltip } from '../../FormFieldWithTooltip';

export const EnvironmentRequestBasicInfoForm = (props: EnvironmentRequestFormProps) => {
  const {
    formField: {
      requestorEmail,
      displayName,
      primaryFTEContacts,
      owningTeamGuid,
      businessJustification,
      environmentDescription
    }
  } = props;

  return (
    <div
      className={css(
        'Requester-Form-Field',
        EnvironmentRequestBasicInfoFormStyle.wrapper,
        window.innerWidth < SCREEN_SIZE.MEDIUM
          ? EnvironmentRequestBasicInfoFormStyle.smScreenWrapper
          : EnvironmentRequestBasicInfoFormStyle.lgScreenWrapper
      )}
    >
      <div className={css('Requester-Form-Field', EnvironmentRequestBasicInfoFormStyle.formColMedium)}>
        <Field
          as={requestorEmail.component}
          label={requestorEmail.label}
          name={requestorEmail.name}
          required={requestorEmail.required}
          disabled={true}
        />
      </div>
      <div className={css('Requester-Form-Field', EnvironmentRequestBasicInfoFormStyle.formColSmall)}>
        <FormFieldWithTooltip
          as={owningTeamGuid.component}
          label={owningTeamGuid.label}
          name={owningTeamGuid.name}
          aria-label={owningTeamGuid.label}
          aria-labelledby={owningTeamGuid.label}
          ariaRequired={owningTeamGuid.required}
          required={owningTeamGuid.required}
          limitResults={5}
          searchType={ServiceTreeSearchType.Organization}
          suggestionsHeader="Suggested Service Tree Objects (Division > Organization)"
          tooltip={owningTeamGuid.tooltip || ''}
        />
      </div>
      <div className={css('Requester-Form-Field', EnvironmentRequestBasicInfoFormStyle.formColMedium)}>
        <FormFieldWithTooltip
          as={displayName.component}
          label={displayName.label}
          name={displayName.name}
          ariaRequired={displayName.required}
          required={displayName.required}
          tooltip={displayName.tooltip || ''}
        />
      </div>
      <div className={css('Requester-Form-Field', EnvironmentRequestBasicInfoFormStyle.formColSmall)}>
        <FormFieldWithTooltip
          as={primaryFTEContacts.component}
          label={primaryFTEContacts.label}
          name={primaryFTEContacts.name}
          ariaRequired={primaryFTEContacts.required}
          required={primaryFTEContacts.required}
          limitResults={5}
          tooltip={primaryFTEContacts.tooltip || ''}
        />
      </div>
      <div className={css('Requester-Form-Field', EnvironmentRequestBasicInfoFormStyle.formColMedium)}>
        <FormFieldWithTooltip
          as={businessJustification.component}
          label={businessJustification.label}
          name={businessJustification.name}
          ariaRequired={businessJustification.required}
          required={businessJustification.required}
          multiline
          autoAdjustHeight
          autoComplete="off"
          tooltip={businessJustification.tooltip || ''}
        />
      </div>
      <div className={css('Requester-Form-Field', EnvironmentRequestBasicInfoFormStyle.formColMedium)}>
        <FormFieldWithTooltip
          as={environmentDescription.component}
          label={environmentDescription.label}
          name={environmentDescription.name}
          required={environmentDescription.required}
          ariaRequired={environmentDescription.required}
          multiline
          autoAdjustHeight
          autoComplete="off"
          tooltip={environmentDescription.tooltip || ''}
        />
      </div>
    </div>
  );
};
