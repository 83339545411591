import { connect } from 'react-redux';
import { AppsListPage } from '.';
import { deleteMyApp, getMyApps } from '../../shared/store/actions';
import { IRootState } from '../../shared/store/IRootState';
import { AppsListActions, AppsListReducerStates } from './AppsListTypes';

// Reducer States
const mapStateToProps = (state: IRootState): AppsListReducerStates => {
  const { lstMyAppsRequestState, deleteMyAppRequestState } = state;
  return {
    lstMyAppsRequestState: lstMyAppsRequestState,
    deleteMyAppRequestState: deleteMyAppRequestState
  };
};

// Actions
const mapDispatchToProps = (): AppsListActions => {
  return {
    getMyApps: () => getMyApps(),
    deleteMyApp: (appId: string) => deleteMyApp(appId)
  };
};

export const ConnectedAppsList = connect(mapStateToProps, mapDispatchToProps())(AppsListPage);
