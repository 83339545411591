/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, mergeMap, map, catchError } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { IQueryToSearchGraphObjectsInfo, ISearchGraphObjectsInfo } from '../../../models/graph';
import { getPrimaryFTEContactsListUrl, httpPost } from '../../../services';
import {
  getPrimaryFTEContactsList,
  getPrimaryFTEContactsListSuccess,
  getPrimaryFTEContactsListFail
} from '../../actions/primaryFTEContacts';
import { IRootState } from '../../IRootState';
import { RootActions } from '../../RootActions';

export const GetPrimaryFTEContactsEpic: Epic<RootActions, RootActions, IRootState> = (action$, _store) =>
  action$.pipe(
    filter(isActionOf(getPrimaryFTEContactsList)),
    mergeMap((action$) => {
      const query: IQueryToSearchGraphObjectsInfo = {
        objectType: action$.payload.objectType,
        keyWords: action$.payload.keyWords === '' ? null : action$.payload.keyWords,
        skipToken: null
      };
      return from(httpPost(getPrimaryFTEContactsListUrl(), query))
        .pipe(
          map((res) => {
            return res.response as ISearchGraphObjectsInfo;
          })
        )
        .pipe(
          mergeMap((result) => {
            return of(getPrimaryFTEContactsListSuccess(result));
          }),
          catchError((error) => {
            return of(getPrimaryFTEContactsListFail(error));
          })
        );
    })
  );

export default [GetPrimaryFTEContactsEpic];
