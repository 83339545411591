import { ICommandBarItemProps } from '@fluentui/react';

// Getting initial CommandBar configured
export const getListMyRequestStatusCommandBarItems = (refresh: () => void): ICommandBarItemProps[] => {
  return [
    {
      key: 'newItem',
      text: 'New',
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Add' },
      subMenuProps: {
        items: [
          {
            key: 'mailboxSSS',
            text: 'Mailbox SSS',
            iconProps: { iconName: 'Mail' },
            ariaLabel: 'Mailbox SSS. Use left and right arrow keys to Create Mailbox SSS',
            onClick: () => {
              location.href = '/mailbox-request/create';
            }
          },
          {
            key: 'environment',
            text: 'Environment',
            iconProps: { iconName: 'AddOnlineMeeting' },
            ariaLabel: 'Environment. Use left and right arrow keys to Create Environment',
            onClick: () => {
              location.href = '/environment-request';
            }
          }
        ]
      }
    },
    {
      key: 'refresh',
      name: 'Refresh',
      iconProps: {
        iconName: 'Refresh'
      },
      ariaLabel: 'Refresh. Use left and right arrow keys to navigate',
      onClick: () => refresh()
    }
  ];
};
