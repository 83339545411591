import { navCollapsedWidth } from '@coherence-design-system/controls';
import { mergeStyleSets, IStyle } from '@fluentui/react';


const scrollablePaneStyles = {
  position: 'fixed',
  top: 48,
  bottom: 0,
  right: 0
};
let navScrollBarWidth = 0;
const navDefaultScrollBarWidth = 15;

export function getScrollBarWidth(isCollapsed: boolean) {
  if (navScrollBarWidth !== 0) {
    return navScrollBarWidth;
  }
  if (document.readyState === "loading") { return navDefaultScrollBarWidth; }
  // Get the browser scrollbar width because they're all different
  const scrollDiv = document.createElement("div");
  scrollDiv.setAttribute("style", "width: 100px;height: 100px;overflow: scroll;position: absolute;top: -999px;");
  const contentDiv = document.createElement("p");
  contentDiv.setAttribute("style", "width: 100px;height: 200px;");
  scrollDiv.appendChild(contentDiv);
  document.body.appendChild(scrollDiv);
  navScrollBarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return navScrollBarWidth;
}

export const _Styles = mergeStyleSets({
  scrollablePaneCollapsed: {
    ...scrollablePaneStyles,
    left: navCollapsedWidth + getScrollBarWidth(true)
  } as IStyle,
  scrollablePaneExpand: {
    ...scrollablePaneStyles,
    left: 228 + getScrollBarWidth(false)
  } as IStyle,
  rootDiv: {
    paddingRight: '30px',
    paddingLeft: '10px'
  } as IStyle,
  dividerLine: {
    width: '100%',
    height: '1px',
    backgroundColor: 'black',
    marginBottom: '20px'
  } as IStyle,
  rowGap: {
    height: '30px'
  } as IStyle
});
