/* eslint-disable react/jsx-no-bind */
import { Formik, Form, Field } from 'formik';
import React, { useEffect } from 'react';
import {
  createTheme,
  mergeStyles,
  PrimaryButton,
  Separator,
  Shimmer,
  Spinner,
  SpinnerSize,
  Text,
  TextField
} from '@fluentui/react';
import { useDispatch } from 'react-redux';
import { getAppMetadata, setAppMetadata } from '../../../shared/store/actions';
import { AppMetadataFormProps } from './AppMetadataFormTypes';
import { AppMetadataFormValidation } from './AppMetadataFormValidation';
import { FormTextField } from '../Items/FormTextField';
import { FormDropdown } from '../Items/FormDropdown';
import {
  APP_TYPE_OPTIONS,
  ENVIRONMENT_DATA_CLASSIFICATION_OPTIONS,
  ENVIRONMENT_PURPOSE_OPTIONS
} from '../EnvironmentRequestForm/EnvironmentRequestFormConstants';
import { FormCheckbox } from '../Items/FormCheckbox';
import { FormServiceTreePicker, ServiceTreeSearchType } from '../Items/FormServiceTreePicker';
import { FormPeoplePicker } from '../Items/FormPeoplePicker';
import { IAppMetadata } from '../../../shared/models/AppsList/IAppMetadataRequest';
import { LoadingState } from '../../../shared/models/ILoading';
import { FormFieldWithTooltip } from '../FormFieldWithTooltip';
import { ButtonIconWithTooltip } from '../../InfoIcon/ButtonIconWithTooltip';

const separatorStyles = {
  content: [
    {
      padding: '0px 0px 0px 0px'
    }
  ]
};
const sectionTheme = createTheme({
  fonts: {
    medium: {
      fontFamily: 'Segoe UI',
      fontSize: '26px'
    }
  }
});

export const AppMetadataForm: React.FC<AppMetadataFormProps> = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.appId !== undefined) {
      dispatch(getAppMetadata(props.appId));
    }
  }, [dispatch, props.appId]);

  function onFormSubmit(values: IAppMetadata) {
    // eslint-disable-next-line no-param-reassign
    props.getAppMetadataRequestState.data = values;
    if (props.appId !== undefined) {
      dispatch(setAppMetadata(props.appId, values));
    }
  }

  if (props.getAppMetadataRequestState.isLoading) {
    // DO NOT TRY TO WRAP THIS FORM IN THE SHIMMER IT BREAKS INITIAL DATA DISPLAY
    return (
      <div>
        <br />
        <Shimmer />
        <br />
        <Shimmer />
        <br />
        <Shimmer />
        <br />
        <Shimmer />
        <br />
        <Shimmer />
      </div>
    );
  }
  if (
    props.getAppMetadataRequestState.isLoading === false &&
    props.getAppMetadataRequestState.loadingState === LoadingState.loaded
  ) {
    const newInitialValues = props.getAppMetadataRequestState.data;
    if (newInitialValues.description == null) newInitialValues.description = '';
    if (newInitialValues.justification == null) newInitialValues.justification = '';
    if (newInitialValues.purpose == null) newInitialValues.purpose = '';
    return (
      <div>
        <Formik
          enableReinitialize={false}
          initialValues={newInitialValues}
          validationSchema={AppMetadataFormValidation}
          onSubmit={onFormSubmit}
        >
          <Form>
            <div style={{ marginTop: '14px' }} />
            <h2>
              <Separator styles={separatorStyles} theme={sectionTheme} alignContent="start">
                Section 1 - Application Information
              </Separator>
            </h2>
            <Field as={FormTextField} label="Application Name" name="displayName" required={true} autoComplete="on" />
            <TextField
              styles={{
                field: mergeStyles({
                  color: 'rgb(0,0,0,0.75)'
                })
              }}
              value={new Date(newInitialValues.createdtime!.toString() + 'Z').toLocaleString()}
              label="Created Date"
              disabled={true}
            />
            <Field as={FormTextField} label="Application ID" name="appName" disabled={true} />
            <Field
              as={FormDropdown}
              label="Application Type"
              name="appType"
              options={APP_TYPE_OPTIONS}
              disabled={true}
            />

            <Field
              as={FormDropdown}
              label="Purpose"
              required={true}
              name="purpose"
              options={ENVIRONMENT_PURPOSE_OPTIONS}
            />
            <Field
              as={FormTextField}
              label="Description"
              name="description"
              required={true}
              multiline
              autoAdjustHeight
            />
            <FormFieldWithTooltip
              as={FormTextField}
              label="Business Justification"
              tooltip="Explain what this app will be used for and why it's necessary."
              name="justification"
              required={true}
              multiline
              autoAdjustHeight
            />
            <FormFieldWithTooltip
              as={FormTextField}
              label="Target Audience"
              tooltip="Who will use this app? Ex. a specific team, an entire organization, all Microsoft FTEs, etc."
              name="audience"
              required={true}
            />

            <div style={{ marginTop: '14px' }} />
            <h2>
              <Separator styles={separatorStyles} theme={sectionTheme} alignContent="start">
                Section 2 - Ownership
              </Separator>
            </h2>
            <Field as={FormPeoplePicker} label="Owner" name="Owner" limitResults={5} />
            <Field as={FormPeoplePicker} label="Co-Owners" name="CanEdit" limitResults={5} />
            <FormFieldWithTooltip
              as={FormPeoplePicker}
              label="Primary FTE Contacts"
              tooltip="A minimum of two non-intern FTEs are required. They will serve as the main point of contact for this app. You may not enter Service Accounts."
              name="primaryFTEContacts"
              required={true}
              limitResults={5}
            />
            <FormFieldWithTooltip
              as={FormServiceTreePicker}
              label="Owning Team"
              tooltip="Select the division the engineering team owning the application belongs to."
              name="owningTeam"
              required={true}
              limitResults={5}
              searchType={ServiceTreeSearchType.Organization}
              suggestionsHeader="Suggested Service Tree Objects (Division > Organization)"
            />
            <FormFieldWithTooltip
              as={FormServiceTreePicker}
              label="Business Sponsor"
              tooltip="Select the division the business sponsor of the application belongs to, if there is one."
              name="businessSponsor"
              required={false}
              limitResults={5}
              searchType={ServiceTreeSearchType.Organization}
              suggestionsHeader="Suggested Service Tree Objects (Division > Organization)"
            />

            <div style={{ marginTop: '14px' }} />
            <h2>
              <Separator styles={separatorStyles} theme={sectionTheme} alignContent="start">
                Section 3 - Compliance
              </Separator>
            </h2>
            <FormFieldWithTooltip
              as={FormDropdown}
              label="Data Classification"
              tooltip={
                <>
                  For information on Data Classification Labels, visit{' '}
                  <a target={'_blank'} rel={'noreferrer'} href="https://aka.ms/classification">
                    aka.ms/classification
                  </a>
                </>
              }
              name="dataClassification"
              required={true}
              options={ENVIRONMENT_DATA_CLASSIFICATION_OPTIONS}
            />
            <div>
              {/* I wanted to use the wrapper component here but the way were are using 1 label for many Fields is not supported 
                  also, this span should be a label but lint refused to recognize the htmlFor attr on it so here we are. */}
              <span style={{ fontSize: '14px', fontWeight: 600, padding: '5px 0px' }}>
                Regulatory Scope (select all that apply)
              </span>
              <span style={{ color: 'rgb(164, 38, 44)', paddingRight: '2px' }}> *</span>
              <ButtonIconWithTooltip
                tooltipContent={
                  <>
                    For information on privacy policies of Microsoft, GDPR and personal data, visit{' '}
                    <a target={'_blank'} rel={'noreferrer'} href="https://aka.ms/NGP">
                      aka.ms/NGP
                    </a>
                  </>
                }
              />
              <Field as={FormCheckbox} label="HIPAA Data" name="isHIPAA" required={true} />
              <Field as={FormCheckbox} label="Personal (GDPR) Data" name="isGDPR" required={true} />
              <Field as={FormCheckbox} label="Tented or Sensitive Information" name="isTented" required={true} />
              <Field as={FormCheckbox} label="SOX Applications" name="isSOX" required={true} />
            </div>
            <FormFieldWithTooltip
              as={FormServiceTreePicker}
              label="ServiceTree ID"
              tooltip="Enter the ServiceTree Component or Service ID associated with this app, if there is one. This is required if you indicated that your app is in scope for any regulatory items above."
              name="serviceTreeService"
              required={false}
              limitResults={5}
              searchType={ServiceTreeSearchType.Service}
              suggestionsHeader="Suggested Service Tree Services"
            />

            <div style={{ marginTop: '10px' }}>
              <Text>
                Last modified by {newInitialValues.modifiedBy} on{' '}
                {new Date(newInitialValues.modifiedtime!.toString() + 'Z').toLocaleString()}
              </Text>
              <div style={{ float: 'left' }}>
                {props.setAppMetadataRequestState.loadingState === LoadingState.error && (
                  <Text style={{ color: 'red' }}>
                    Error saving application metadata: {props.setAppMetadataRequestState.loadingError?.message}
                  </Text>
                )}
              </div>
              <div style={{ float: 'right' }}>
                {props.setAppMetadataRequestState.loadingState === LoadingState.loading && (
                  <Spinner size={SpinnerSize.medium} label={'Saving...'} labelPosition="left" />
                )}
                {!props.setAppMetadataRequestState.isLoading && (
                  <PrimaryButton disabled={props.setAppMetadataRequestState.isLoading} type="submit">
                    Save
                  </PrimaryButton>
                )}
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    );
  }
  return (
    <div>
      <Spinner size={SpinnerSize.large} label={'Loading data...'} />
    </div>
  );
};
