import { createReducer } from 'typesafe-actions';
import { IEventTelemetry, IExceptionTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { getEnvironments, getEnvironmentsFail, getEnvironmentsSuccess } from '../../actions';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { telemetryConfig } from '../../../../components/TelemetryService/TelemetryConfig';
import { API_NAMES, API_STATUS, EVENTS } from '../../../constants';

TelemetryService.initialize(telemetryConfig);

export const getEnvironmentReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(getEnvironments, (state) => {
    return {
      ...state,
      getEnvironmentsState: {
        ...state.getEnvironmentsState,
        isLoading: true,
        loadingState: LoadingState.loading
      }
    };
  })
  .handleAction(getEnvironmentsSuccess, (state, action) => {

    TelemetryService.trackEvent({ name: EVENTS.API_CALL } as IEventTelemetry, {
      API: API_NAMES.GET_ENVIRONMENTS,
      Result: API_STATUS.SUCCESS
    });

    return {
      ...state,
      getEnvironmentsState: {
        ...state.getEnvironmentsState,
        environmentList: action.payload,
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(getEnvironmentsFail, (state, action) => {

    TelemetryService.trackException({
      exception: { message: API_STATUS.FAIL, name: API_NAMES.GET_ENVIRONMENTS } as Error,
      properties: { API: API_NAMES.GET_ENVIRONMENTS, Payload: action.payload },
      severityLevel: SeverityLevel.Error
    } as IExceptionTelemetry);
    return {
      ...state,
      getEnvironmentsState: {
        ...state.getEnvironmentsState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    };
  });
