/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Dropdown, mergeStyles } from '@fluentui/react';
import { useField } from 'formik';
import React from 'react';
import { createFakeEvent, invokeAll } from './utils';

export function FormDropdown({ ...props }): React.ReactNode {
  const [field, meta, helpers] = useField(props.name);
  const onDismiss = () => field.onBlur(createFakeEvent(field));
  return (
    <>
      <Dropdown
        styles={{
          title: mergeStyles({
            color: 'rgb(0,0,0,0.75)'
          }) // this is an iphone display issue
        }}
        {...field}
        {...props}
        label={props.label}
        options={props.options}
        defaultSelectedKey={props.value}
        errorMessage={meta.touched && meta.error ? meta.error : undefined} // allow for prop overwrite
        onDismiss={invokeAll(onDismiss, props.onDismiss)}
        aria-required={props.ariaRequired}
        selectedKey={field.value}
        onChange={(_, option) => {
          helpers.setValue(option!.key);
        }}
      />
    </>
  );
}
