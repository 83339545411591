import React from 'react';
import { css, Link, MessageBar, MessageBarType } from '@fluentui/react';
import ErrorLogo from "../../shared/Assets/ActionFailure.svg";
import { ErrorBannerStyles } from './ErrorBanner.styles';

function ErrorBanner() {
  return (
    <div>
      <MessageBar messageBarType={MessageBarType.error}>
        Something went wrong{' '}
        <Link
          onClick={() => {
            window.location.reload();
          }}
        >
          Click here to Retry
        </Link>
        . If the Issue persists, please :
        <Link href="https://microsoft.sharepoint.com/sites/Microsoft365Builders/SitePages/Power%20Platform%20Gov/PowerPlatform-Support.aspx" target="_blank" rel='noreferrer noopener'>
          Contact Support
        </Link>
      </MessageBar>
      <div className={css(ErrorBannerStyles.actionFailureLogo)}>
        <img src={ErrorLogo} alt="Failed to load logo" />
      </div>
    </div>
  );
}

export default ErrorBanner;
