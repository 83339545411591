import { css, Label } from '@fluentui/react';
import React from 'react';
import {
  HorizontalProgressStepperStyles,
  ProgressStepperStateStyles,
  VerticalProgressStepperStyles,
  ProgressStepperHideTextStyle
} from './ProgressStepper.styles';
import {
  ProgressStepperNodeState,
  ProgressStepperNodeTypes,
  ProgressStepperProps,
  ProgressStepperState
} from './ProgressStepper.types';
import { SCREEN_SIZE } from '../../shared/constants';

export default class ProgressStepper extends React.Component<ProgressStepperProps, ProgressStepperState> {
  constructor(props: ProgressStepperProps) {
    super(props);
    this.state = {
      steps: [] as ProgressStepperNodeState[]
    };
  }

  componentDidMount() {
    this.setState({
      steps: this.props.lstSteps
    });
  }

  componentDidUpdate(prevProps: ProgressStepperProps) {
    if (JSON.stringify(prevProps.lstSteps) !== JSON.stringify(this.props.lstSteps)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        steps: this.props.lstSteps
      });
    }
  }

  getClassName = () => {
    if (window.innerWidth > SCREEN_SIZE.MEDIUM) {
      return HorizontalProgressStepperStyles.descriptionLg;
    }
    if (window.innerWidth > SCREEN_SIZE.SMALL) {
      return HorizontalProgressStepperStyles.descriptionM;
    }
    if (window.innerWidth > SCREEN_SIZE.XS) {
      return HorizontalProgressStepperStyles.descriptionSm;
    }
    return HorizontalProgressStepperStyles.descriptionXS;
  };

  render() {
    const { steps } = this.state;
    let ariaLabel = "";
    const stepsDisplay = steps.map((step, index) => {
      let stepNumberStyle = ProgressStepperStateStyles.stepNumberEmpty;
      switch (step.nodeType) {
        case ProgressStepperNodeTypes.Empty:
          ariaLabel = "Yet to be started"
          stepNumberStyle = ProgressStepperStateStyles.stepNumberEmpty;
          break;
        case ProgressStepperNodeTypes.InProgress:
          ariaLabel = "In Progress"
          stepNumberStyle = ProgressStepperStateStyles.stepNumberInProgress;
          break;
        case ProgressStepperNodeTypes.Active:
          ariaLabel = "In progress"
          stepNumberStyle = ProgressStepperStateStyles.stepNumberActive;
          break;
        case ProgressStepperNodeTypes.Complete:
          ariaLabel = "Completed"
          stepNumberStyle = ProgressStepperStateStyles.stepNumberComplete;
          break;
        case ProgressStepperNodeTypes.Error:
          ariaLabel = "Error"
          stepNumberStyle = ProgressStepperStateStyles.stepNumberError;
          break;
        default:
          ariaLabel = "Error"
          break;
      }

      if (this.props.currentStepNumber === index) {
        if (step.nodeType === ProgressStepperNodeTypes.Error) {
          ariaLabel = "Error"
          stepNumberStyle = ProgressStepperStateStyles.stepNumberActiveError;
        } else {
          ariaLabel = "In Progress"
          stepNumberStyle = ProgressStepperStateStyles.stepNumberActive;
        }
      }

      let str = (index + 1).toString();
      if (step.nodeType === ProgressStepperNodeTypes.Complete || step.nodeType === ProgressStepperNodeTypes.Error) {
        if (step.nodeType === ProgressStepperNodeTypes.Complete) {
          str = '✓';
        } else if (this.props.currentStepNumber !== index) {
          str = '✖';
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          str = '❌';
        }
      }
      ariaLabel = "Step " + (index + 1).toString() + ariaLabel
      return (
        <div
          key={`${step.description}`}
          className={
            this.props.isVertical
              ? css(VerticalProgressStepperStyles.ProgressStepperWrapper)
              : css(HorizontalProgressStepperStyles.ProgressStepperWrapper)
          }
        >
          <div className={css(HorizontalProgressStepperStyles.iconWrapper)}>
            <div className={css(HorizontalProgressStepperStyles.horizBarWrapper)}>
              {index !== 0 ? <div className={css(HorizontalProgressStepperStyles.horizBar)} /> : <></>}
            </div>
            <div
              className={`
                  ${
                    this.props.isVertical
                      ? css(VerticalProgressStepperStyles.ProgressStepperNumber)
                      : css(HorizontalProgressStepperStyles.ProgressStepperNumber)
                  }
                  ${stepNumberStyle}
                  `}
            >
              <Label
                className={css(
                  HorizontalProgressStepperStyles.linkWithoutOnclick,
                  isNaN(parseInt(str, 10))
                    ? HorizontalProgressStepperStyles.colorCodeW
                    : HorizontalProgressStepperStyles.colorCodeB
                )}
              >
                <span aria-hidden="true">{str}</span>
                <span className={css(ProgressStepperHideTextStyle.hideText)}> {ariaLabel} </span>
              </Label>
            </div>
            <div className={css(HorizontalProgressStepperStyles.horizBarWrapper)}>
              {index !== steps.length - 1 ? <div className={css(HorizontalProgressStepperStyles.horizBar)} /> : <></>}
            </div>
          </div>
          <div className={css(this.getClassName())}>{step.description}</div>
          {this.props.enableSubDescription ? (
            <div className={css(HorizontalProgressStepperStyles.subDescription)}>
              {step.subDescription !== undefined ? step.subDescription : ' '}
            </div>
          ) : null}
        </div>
      );
    });
    return (
      <div
        className={
          this.props.isVertical
            ? css(VerticalProgressStepperStyles.ProgressStepperWrapperVertical)
            : css(HorizontalProgressStepperStyles.ProgressStepperWrapperHorizontal)
        }
      >
        {stepsDisplay}
      </div>
    );
  }
}
