/**
 * To Check whether the object is empty or not
 * @param obj
 * @returns
 */
const isEmpty = (obj: Object | null | undefined): boolean => {
  if (obj === null || obj === undefined || Object.keys(obj).length === 0) {
    return true;
  }
  return false;
};

/**
 * To convert an object where id is used as key and the whole value used as value of the new object.
 * @param arr
 * @returns
 */
const convertToObjByKeyAsId = (arr: any[]) => {
  const obj: any = {};
  for (let i = 0; i < arr.length; i += 1) {
    let key = arr[i]?.id;
    obj[key] = arr[i];
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return obj;
};

export const objectUtils = {
  isEmpty,
  convertToObjByKeyAsId
};
