import { connect } from 'react-redux';
import { IRootState } from '../../../../shared/store/IRootState';
import { EnvironmentViolations } from './EnvironmentViolation';
import { getEnvironmentDetail } from '../../../../shared/store/actions';
import { IEnvironmentDetailReducerStates, IEnvironmentDetailsActions } from './EnvironmentViolation.model';
// Reducer States
const mapStateToProps = (state: IRootState): IEnvironmentDetailReducerStates => {
  const { getEnvironmentDetailRequestState } = state;
  return {
    getEnvironmentDetailRequestState: getEnvironmentDetailRequestState
  };
};

// Actions
const mapDispatchToProps = (): IEnvironmentDetailsActions => {
  return {
    getEnvironmentDetail: (environmentId: string) => getEnvironmentDetail(environmentId)
  };
};

export const ConnectedEnvironmentViolation = connect(mapStateToProps, mapDispatchToProps())(EnvironmentViolations);
