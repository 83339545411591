import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { IRootState } from '../../IRootState';
import { RootActions } from '../../RootActions';
import { submitEnvironmentRequestUrl, httpPost } from '../../../services';
import {
  submitEnvironmentRequest,
  submitEnvironmentRequestFail,
  submitEnvironmentRequestSuccess
} from '../../actions/environment';
import { IEnvironment } from '../../../models/Environment/IEnvironmentRequest';

export const SubmitEnvironmentRequestEpic: Epic<RootActions, RootActions, IRootState> = (action$, _store) =>
  action$.pipe(
    filter(isActionOf(submitEnvironmentRequest)),
    mergeMap((action$) => {
      return from(httpPost(submitEnvironmentRequestUrl(), action$.payload))
        .pipe(
          map((res) => {
            return res.response as IEnvironment;
          })
        )
        .pipe(
          mergeMap((result) => {
            return of(submitEnvironmentRequestSuccess(result));
          }),
          catchError((error) => {
            return of(submitEnvironmentRequestFail(error));
          })
        );
    })
  );

export default [SubmitEnvironmentRequestEpic];
