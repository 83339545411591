import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { getRequestStatusMailSSS, getRequestStatusMailSSSFail, getRequestStatusMailSSSSuccess } from '../../actions';
import { IRootState } from '../../IRootState';
import { RootActions } from '../../RootActions';
import { getRequestStatusMailSSSUrl, httpGet } from '../../../services';
import { IRequestStatusMailboxSSS } from '../../../models/myRequestStatus/IRequestStatusMailboxSSS';

export const GetRequestStatusMailSSSEpic: Epic<RootActions, RootActions, IRootState> = (
  action$,
  _store
  // { getRequestStatusMailSSSApi }
) =>
  action$.pipe(
    filter(isActionOf(getRequestStatusMailSSS)),
    mergeMap((action$) => {
      return from(httpGet(getRequestStatusMailSSSUrl(action$.payload)))
        .pipe(
          map((res) => {
            return { requestStatusMailboxSSS: res.response as IRequestStatusMailboxSSS };
          })
        )
        .pipe(
          mergeMap(({ requestStatusMailboxSSS }) => {
            return of(getRequestStatusMailSSSSuccess(requestStatusMailboxSSS));
          }),
          catchError((error) => {
            return of(getRequestStatusMailSSSFail(error));
          })
        );
      // .pipe(
      //   mergeMap(
      //     (result) =>
      //       of(setExampleListCount(result), setGraphObjectCount(result), getRoleAssignmentListSuccess(result)) //you can fire multiple actions
      //     // return getRoleAssignmentListSuccess(result);
      //   ),
      //   catchError((error) => of(getRoleAssignmentListFail(error)))
      // )
    })
  );

export default [GetRequestStatusMailSSSEpic];
