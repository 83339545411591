import { ScrollablePane, css } from '@fluentui/react';
import * as React from 'react';
import { EnvironmentRequestPageProps } from './EnvironmentRequestPage.types';
import { EnvironmentRequestPageStyle } from './EnvironmentRequestPage.styles';
import { _Styles } from '../../shared/styles/Page.styles';
import { EnvironmentRequestForm } from '../../components/Forms/EnvironmentRequestForm/EnvironmentRequestForm';
import { PageHeader } from '../../components/PageHeader/PageHeader';

export const EnvironmentRequestPage: React.FC<EnvironmentRequestPageProps> = (props) => {
  return (
    <ScrollablePane className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}>
      <main
        data-automation-id="EnvironmentRequest"
        className={css(EnvironmentRequestPageStyle.pageBackground)}
        tabIndex={-1}
      >
        <PageHeader pageTitle={'Environment Request Form'} />
        <div className={'ms-DetailsContent'} style={{ overflowX: 'hidden' }}>
          <EnvironmentRequestForm />
        </div>
      </main>
    </ScrollablePane>
  );
};
