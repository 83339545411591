import {
  FontWeights,
  ILabelStyles,
  IStackTokens,
  IMessageBarStyles,
  IStackStyles,
  IStyle,
  mergeStyleSets,
  FontSizes
} from '@fluentui/react';

export const formStyles = mergeStyleSets({
  heading: {
    marginTop: '14px',
    alignContent: 'start',
    fontSize: FontSizes.size18,
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between'
  } as IStyle,
  errorIcon: {
    color: '#e74c3c',
    borderRadius: '50%',
    fontWeight: '700'
  } as IStyle,
  checkboxWrapper: {
    margin: '5px',
    display: 'flex'
  },
  fieldContent: {
    paddingTop: '10px'
  },
  staticFieldContent: {
    paddingRight: '100px'
  },
  buttonBar: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  formColSmall: {
    gridColumn: 2 / 3,
    position: 'relative'
  },
  attestationContainer: {
    overflowX: 'hidden'
  }
});

export const stackStyles: IStackStyles = {
  root: {
    padding: '0px'
  }
};

export const footerStyles: IStackStyles = {
  root: {
    marginBottom: '10px'
  }
};

export const labelStyles: ILabelStyles = {
  root: {
    fontWeight: FontWeights.regular,
    width: '80%'
  }
};

export const messageBarStyles: IMessageBarStyles = {
  root: {
    marginTop: '15px',
    width: 'auto'
  }
};

export const horizontalGapNameStackTokens: IStackTokens = {
  childrenGap: 95
};

export const horizontalGapCdStackTokens: IStackTokens = {
  childrenGap: 50
};

export const horizontalGapIdStackTokens: IStackTokens = {
  childrenGap: 120
};

export const horizontalGapPurposeStackTokens: IStackTokens = {
  childrenGap: 80
};

export const stackTokens: IStackTokens = { childrenGap: 40 };
