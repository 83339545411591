/**
 * Interface to indicate loading status on a Redux store property.
 */
export interface ILoading {
  isLoading: boolean;
  isUserError?: boolean;
  loadingState?: LoadingState;
  loadingError?: Error;
  retryCount?: number;
}

export interface IExecuting {
  isExecuting: boolean;
  isUserError?: boolean;
  executionState?: LoadingState;
  executionError?: Error;
  retryCount?: number;
}

export interface IExecutable<TValue> extends IExecuting {
  readonly value: TValue;
}

export interface ILoadable<TValue> extends ILoading {
  readonly value: TValue;
}

export interface IRetryable {
  isRetrying: boolean;
}

export enum LoadingState {
  notLoaded = 'notLoaded',
  loaded = 'loaded',
  loading = 'loading',
  refreshing = 'refreshing',
  paging = 'paging',
  error = 'error'
}
