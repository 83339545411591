export const SPS_CONST = {
  ApiStatus: {
    InViolation: 'In Violation',
    Deleted: 'Deleted',
    Quarantined: 'Quarantined',
    RemoveFromQuarantine: 'Compliant',
    Compliant: 'Compliant'
  },
  StatusStyle: {
    InViolation: 'markNonCompliant',
    Deleted: 'markDeleted',
    Quarantined: 'markQuarantined',
    RemoveFromQuarantine: 'markCompliant',
    Compliant: 'markCompliant'
  },
  ActionLabel: {
    InViolation: 'Remediate',
    Deleted: 'Restore',
    Quarantined: 'Unquarantine',
    RemoveFromQuarantine: 'Unquarantine',
    Compliant: 'Compliant',
    View: 'View'
  }
};

export const SPS_API_STATE = {
  IN_VIOLATION: 'InViolation',
  DELETED: 'Deleted',
  QUARANTINED: 'Quarantined',
  REMOVE_FROM_QUARANTINE: 'RemoveFromQuarantine',
  COMPLIANT: 'Compliant'
};

