import { createReducer } from 'typesafe-actions';
import { IEventTelemetry, IExceptionTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { getListMyRequestStatus, getListMyRequestStatusFail, getListMyRequestStatusSuccess } from '../../actions';
import { toastError } from '../../../utils/tools/toast';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { telemetryConfig } from '../../../../components/TelemetryService/TelemetryConfig';
import { API_NAMES, API_STATUS, EVENTS } from '../../../constants';

TelemetryService.initialize(telemetryConfig);

export const listMyRequestStatusReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(getListMyRequestStatus, (state) => {
    return {
      ...state,
      lstMyRequestStatusState: {
        ...state.lstMyRequestStatusState,
        lstMyRequestStatus: [],
        isLoading: true,
        loadingState: LoadingState.loading
      }
    };
  })
  .handleAction(getListMyRequestStatusSuccess, (state, action) => {
    TelemetryService.trackEvent({ name: EVENTS.API_CALL } as IEventTelemetry, {
      API: API_NAMES.GET_LIST_MY_REQUEST_STATUS,
      Result: API_STATUS.SUCCESS
    });
    return {
      ...state,
      lstMyRequestStatusState: {
        ...state.lstMyRequestStatusState,
        lstMyRequestStatus: [...action.payload.lstMyRequestStatus],
        hasMore: action.payload.hasMore,
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(getListMyRequestStatusFail, (state, action) => {
    toastError('Fail to get list request status', action.payload);
    TelemetryService.trackException({
      exception: { message: API_STATUS.FAIL, name: API_NAMES.GET_LIST_MY_REQUEST_STATUS } as Error,
      properties: { API: API_NAMES.GET_LIST_MY_REQUEST_STATUS, Payload: action.payload },
      severityLevel: SeverityLevel.Error
    } as IExceptionTelemetry);
    return {
      ...state,
      lstMyRequestStatusState: {
        ...state.lstMyRequestStatusState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    };
  });
