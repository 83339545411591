import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { IRootState } from '../../IRootState';
import { getFeatureFlags, getFeatureFlagsFailed, getFeatureFlagsSucceeded } from '../../actions';
import { RootActions } from '../../RootActions';
import { getFeatureFlagUrl, httpGet } from '../../../services';

export const GetFeatureFlagsEpic: Epic<RootActions, RootActions, IRootState> = (action$, _store) =>
  action$.pipe(
    filter(isActionOf(getFeatureFlags)),
    mergeMap((_action$) => {
      return from(
        httpGet(getFeatureFlagUrl())
          .pipe(
            map((res) => {
              return res.response as { [feature: string]: boolean };
            })
          )
          .pipe(
            mergeMap((res) => {
              return of(getFeatureFlagsSucceeded(res));
            }),
            catchError((error) => {
              return of(getFeatureFlagsFailed(error));
            })
          )
      );
    })
  );

export default [GetFeatureFlagsEpic];
