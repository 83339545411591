import { getAppMetadataReducer } from './appsList/GetAppMetadataReducer';
import { getFeatureFlagsReducer } from './featureFlags/GetFeatureFlagsReducer';
import { IRootState } from '../IRootState';
import { initialRootState } from '../initialRootState';
import { RootActions } from '../RootActions';
import { listMailboxSSSRequestsReducer } from './mailboxSSS/ListMailboxSSSRequestsReducer';
import { listApprvalMailboxSSSRequestsReducer } from './mailboxSSS/ListApprvalMailboxSSSRequestsReducer';
import { oneAccessRevokeReducer } from './oneAccessRevoke/OneAccessRevokeReducer';
import { submitEnvironmentRequestReducer } from './environment/SubmitEnvironmentRequestReducer';
import { listMyRequestStatusReducer } from './myRequestStatus/ListMyRequestStatusReducer';
import { requestStatusMailboxSSSReducer } from './myRequestStatus/RequestStatusMailboxSSSReducer';
import { requestStatusEnvironmentReducer } from './myRequestStatus/RequestStatusEnvironmentReducer';
import { getServiceTreeObjectReducer } from './serviceTree/getServiceTreeObjectReducer';
import { listMyAppsReducer } from './appsList/ListMyAppsReducer';
import { getPrimaryFTEContactsReducer } from './primaryFTEContacts/getPrimaryFTEContactsReducer';
import { deleteMyAppReducer } from './appsList/DeleteMyAppReducer';
import { setAppMetadataReducer } from './appsList/SetAppMetadataReducer';
import { getEnvironmentReducer } from './environment/GetEnvironmentReducer';
import { getEnvironmentDetailReducer } from './environment/GetEnvironmentDetailReducer';
import { setEnvironmentAttestationReducer } from './environment/SetEnvironmentAttestationReducer';
import { getPoliciesReducer } from './policies/GetPoliciesReducer';
import { SubmitMailboxSSSRequestReducer } from './mailboxSSS/SubmitMailboxSSSRequestReducer';
import { SubmitEnvironmentRescanReducer } from './environment/SubmitEnvironmentRescanReducer';
import { getSharePointSitesReducer } from './SharePointSites/GetSharePointSitesReducer';
import { GetSharepointPoliciesReducer } from './policies/GetSharepointPoliciesReducer';
import { getSharePointDetailReducer } from './SharePointSites/GetSharePointDetailReducer';

const reducers = (state: IRootState | undefined = initialRootState, action: RootActions) => {
  return {
    lstMailboxSSSRequestsState: listMailboxSSSRequestsReducer(state, action).lstMailboxSSSRequestsState,
    lstApprvalMailboxSSSRequestsState: listApprvalMailboxSSSRequestsReducer(state, action)
      .lstApprvalMailboxSSSRequestsState,
    revokedRoleAssignment: oneAccessRevokeReducer(state, action).revokedRoleAssignment,
    submitEnvironmentRequestState: submitEnvironmentRequestReducer(state, action).submitEnvironmentRequestState,
    submitMailboxSSSRequestState: SubmitMailboxSSSRequestReducer(state, action).submitMailboxSSSRequestState,
    lstMyRequestStatusState: listMyRequestStatusReducer(state, action).lstMyRequestStatusState,
    requestStatusMailboxSSSState: requestStatusMailboxSSSReducer(state, action).requestStatusMailboxSSSState,
    requestStatusEnvironmentState: requestStatusEnvironmentReducer(state, action).requestStatusEnvironmentState,
    serviceTreeState: getServiceTreeObjectReducer(state, action).serviceTreeState,
    lstMyAppsRequestState: listMyAppsReducer(state, action).lstMyAppsRequestState,
    deleteMyAppRequestState: deleteMyAppReducer(state, action).deleteMyAppRequestState,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    primaryFTEContactsState: getPrimaryFTEContactsReducer(state, action).primaryFTEContactsState,
    featureFlagsRequestState: getFeatureFlagsReducer(state, action).featureFlagsRequestState,
    setAppMetadataRequestState: setAppMetadataReducer(state, action).setAppMetadataRequestState,
    getAppMetadataRequestState: getAppMetadataReducer(state, action).getAppMetadataRequestState,
    getEnvironmentsState: getEnvironmentReducer(state, action).getEnvironmentsState,
    setEnvironmentAttestationRequestState: setEnvironmentAttestationReducer(state, action)
      .setEnvironmentAttestationRequestState,
    getEnvironmentDetailRequestState: getEnvironmentDetailReducer(state, action).getEnvironmentDetailRequestState,
    policiesRequestState: getPoliciesReducer(state, action).policiesRequestState,
    sharePointPoliciesRequestState: GetSharepointPoliciesReducer(state, action).sharePointPoliciesRequestState,
    getSharePointDetailRequestState: getSharePointDetailReducer(state, action).getSharePointDetailRequestState,
    setEnvironmentRescanRequestState: SubmitEnvironmentRescanReducer(state, action).setEnvironmentRescanRequestState,
    getSharePointSitesState: getSharePointSitesReducer(state, action).getSharePointSitesState
  };
};

export default reducers;
