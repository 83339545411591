import { IStyle, mergeStyleSets } from '@fluentui/react';

export const MailboxSSSRequestPageStyle = mergeStyleSets({
  pageBackground: {
    // backgroundColor: CoherenceTheme.palette.neutralLighterAlt,
    selectors: {
      ':global(.ms-DetailsList)': {
        overflow: 'visible'
        // boxShadow: DefaultEffects.elevation64
      }
    }
  } as IStyle,
  powerapp: {
    backgroundColor: 'white!important',
    marginTop: '0.1vh',
    '@media (min-width: 1025px) and (max-width: 3500px)' : {
      marginLeft: '18%',
      width: '80vw'
    },
    '@media (min-width: 102px) and (max-width: 1024px)' : {
      marginLeft: '13%',
      width: '85vw'
    },
    height: '88vh'
  } as IStyle
});
