/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Checkbox } from '@fluentui/react';
import { useField } from 'formik';
import React from 'react';
// import { createFakeEvent } from './utils';

export function FormCheckbox({ ...props }): React.ReactNode {
  const [field, meta, helpers] = useField(props.name!);

  return (
    <>
      <Checkbox
        label={props.label}
        // {...meta}
        onChange={(_, checked) => {
          helpers.setValue(checked);
          // field.onBlur(createFakeEvent(field));
          // helpers.setTouched(false, false);
        }}
        checked={field.value}
      />
      {meta.touched && meta.error ? (
        <div
          className="error"
          style={{
            fontSize: '12px',
            fontWeight: 400,
            color: 'rgb(168, 0, 0)',
            margin: '0px,0px,0px,0px',
            paddingTop: '5px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {meta.error}
        </div>
      ) : null}
    </>
  );
}
