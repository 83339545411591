import {
  ScrollablePane,
  CommandBar,
  css,
  ShimmeredDetailsList,
  CheckboxVisibility,
  DetailsListLayoutMode,
  MessageBar
} from '@fluentui/react';
import * as React from 'react';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { ListMyRequestStatusProps, ListMyRequestStatusState } from './ListMyRequestStatus.types';
import { getListMyRequestStatusCommandBarItems } from './ListMyRequestStatus.commands';
import { getTableListMyRequestStatusColumns } from './ListMyRequestStatus.columns';
import {
  ListMyRequestStatusPageCommandBarStyle,
  ListMyRequestStatusPageContentStyles,
  ListMyRequestStatusPageStyle
} from './ListMyRequestStatus.styles';
import { _Styles } from '../../../shared/styles/Page.styles';
import { renderEveryOtherRow } from '../../../shared/utils/fabric/renderEveryOtherRow';
import { isNullOrUndefined } from '../../../shared/utils/tools/object';

export class ListMyRequestStatus extends React.Component<ListMyRequestStatusProps, ListMyRequestStatusState> {
  constructor(props: ListMyRequestStatusProps) {
    super(props);
    this.state = {
      // filter: '',
      lstMyRequestStatus: [],
      columns: getTableListMyRequestStatusColumns(this.clickLink)
    };
  }

  public componentDidMount() {
    if (
      !isNullOrUndefined(this.props.lstMyRequestStatusState.lstMyRequestStatus) &&
      this.props.lstMyRequestStatusState.lstMyRequestStatus.length > 0
    ) {
      this.setState({
        lstMyRequestStatus: this.props.lstMyRequestStatusState.lstMyRequestStatus
      });
      return;
    }
    this.refresh();
  }

  public componentDidUpdate(preProps: ListMyRequestStatusProps, _preState: ListMyRequestStatusState) {
    if (preProps.lstMyRequestStatusState.isLoading === true && this.props.lstMyRequestStatusState.isLoading === false) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        lstMyRequestStatus: [...this.props.lstMyRequestStatusState.lstMyRequestStatus]
      });
    }
  }

  public render(): React.ReactNode {
    const commands = getListMyRequestStatusCommandBarItems(this.refresh);
    return (
      <ScrollablePane
        className={this.props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}
      >
        <main
          data-automation-id="ListMyRequestStatus"
          className={css(ListMyRequestStatusPageStyle.pageBackground)}
          tabIndex={-1}
        >
          <PageHeader pageTitle={'List Request Status'} />
          <div className={ListMyRequestStatusPageContentStyles.filter}>
            <MessageBar>
              Note: Environment provisioning can take upto 60-90 minutes post managers approval. Please come back for accessing environment post suggested timeframe
            </MessageBar>
          </div>
          <CommandBar
            styles={ListMyRequestStatusPageCommandBarStyle}
            items={commands}
            ariaLabel={'List my request status Commands'}
          />
          {/* <SearchBox
            className={ListMyRequestStatusPageContentStyles.filter}
            value={this.state.filter}
            placeholder="Filter by request type or status or create on"
            ariaLabel="Filter by request type or status or create on"
            iconProps={{ iconName: 'Filter' } as IIconProps}
            onChange={(_, newValue) => {
              this.onFilter(_ === undefined ? ({} as React.ChangeEvent<HTMLInputElement>) : _, newValue);
            }}
          /> */}
          <ShimmeredDetailsList
            setKey="requestId"
            ariaLabelForGrid={'My request status List'}
            ariaLabelForSelectionColumn="Toggle my request status selection"
            isHeaderVisible={true}
            checkboxVisibility={CheckboxVisibility.hidden}
            items={this.state.lstMyRequestStatus}
            // onColumnHeaderClick={this.onColumnClick}
            enableShimmer={this.props.lstMyRequestStatusState.isLoading}
            className={ListMyRequestStatusPageContentStyles.table}
            onRenderRow={renderEveryOtherRow}
            columns={this.state.columns}
            layoutMode={DetailsListLayoutMode.fixedColumns}
          />
        </main>
      </ScrollablePane>
    );
  }

  private refresh = () => {
    // this.setState({
    //   filter: ''
    // });
    this.props.getListMyRequestStatus();
  };

  private clickLink = (requestId: string) => {
    for (let i = 0; i < this.state.lstMyRequestStatus.length; i += 1) {
      if (this.state.lstMyRequestStatus[i].requestId !== requestId) {
        // eslint-disable-next-line no-continue
        continue;
      }

      if (this.state.lstMyRequestStatus[i].requestType === 'CreateMailboxSSS') {
        location.href = `/request-status-mailbox-sss/${requestId}`;
        return;
      }

      if (this.state.lstMyRequestStatus[i].requestType === 'CreateEnvironment') {
        location.href = `/request-status-environment/${requestId}`;
        return;
      }
    }
  };

  //  private onFilter = (_ev: React.ChangeEvent<HTMLInputElement>, newValue?: string | undefined): void => {
  //    const items = newValue
  //      ? this.props.lstMyRequestStatusState.lstMyRequestStatus.filter(
  //          (i) =>
  //            i.generalStatus.toLowerCase().indexOf(newValue.toLowerCase()) > -1 ||
  //            i.requestType.toLowerCase().indexOf(newValue.toLowerCase()) > -1 ||
  //            dateTimeToLocalString(i.createdOn).indexOf(newValue.toLowerCase()) > -1
  //        )
  //      : this.props.lstMyRequestStatusState.lstMyRequestStatus;

  //    this.setState({
  //      filter: newValue === undefined ? '' : newValue,
  //      lstMyRequestStatus: items
  //    });
  //  };

  //  private onColumnClick = (_event: React.MouseEvent<HTMLElement> | undefined, _column: IColumn | undefined): void => {
  //    const column = _column === undefined ? ({} as IColumn) : _column;
  //    let isSortedDescending = column.isSortedDescending;
  //    if (column.isSorted) {
  //      isSortedDescending = !isSortedDescending;
  //    }
  //  if (
  //    column.fieldName === 'requestId' ||
  //      column.fieldName === 'requestType' ||
  //      column.fieldName === 'generalStatus' ||
  //      column.fieldName === 'createdOn'
  //    ) {
  //      const { lstMyRequestStatus, columns } = this.state;
  //      this.setState({
  //        lstMyRequestStatus: copyAndSort(lstMyRequestStatus, column.fieldName, isSortedDescending),
  //        columns: columns.map((col) => {
  //          const newCol = {
  //            ...col,
  //            isSorted: col.key === column.key
  //          } as IColumn;
  //          if (newCol.isSorted) {
  //            newCol.isSortedDescending = isSortedDescending;
  //          }
  //          return newCol;
  //        })
  //      });
  //    }
  //  };
}
