import { createAction } from 'typesafe-actions';
import { IEnvironmentsRequest } from '../../../models/Environment/IEnvironmentRequest';
import { IHttpError } from '../../../models/IHttpError';
import { GET_ENVIRONMENTS, GET_ENVIRONMENTS_SUCCESS, GET_ENVIRONMENTS_FAIL } from '../../constants';

export const getEnvironments = createAction(GET_ENVIRONMENTS, (query: string) => query)();

export const getEnvironmentsSuccess = createAction(
  GET_ENVIRONMENTS_SUCCESS,
  (IEnvironmentsRequest: IEnvironmentsRequest) => IEnvironmentsRequest
)<IEnvironmentsRequest>();

export const getEnvironmentsFail = createAction(GET_ENVIRONMENTS_FAIL, (err: IHttpError) => err)<IHttpError>();
