import React from 'react';
import ReactDOM from 'react-dom';
import { mergeStyles } from '@fluentui/react';
import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { store } from './shared/store';
import { ConnectedApp } from './App/ConnectedApp';
import { msalConfig } from './msalConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

mergeStyles({
  selectors: {
    ':global(body), :global(html), :global(#app)': {
      margin: 0,
      padding: 0,
      height: '100vh'
    }
  }
});

ReactDOM.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <ConnectedApp isNavCollapsed={false} />
    </MsalProvider>
  </Provider>,
  document.getElementById('root')
);
