export const API_NAMES = {
  GET_ENVIRONMENTS: 'Get Environments',
  GET_ENVIRONMENT_DETAIL: 'Get Environment Details',
  SET_ENVIRONMENT_ATTESTATION: 'Set Environment Attestation',
  SUBMIT_ENVIRONMENT_REQUEST: 'Submit Environment Request',
  SUBMIT_MAILBOXSSS_REQUEST:'Submit MailboxSSS Request',
  DELETE_MY_APP: 'Delete My App',
  GET_APP_METADATA: 'Get App Metadata',
  GET_MY_APPS: 'Get My Apps',
  SET_APP_METADATA: 'Set App Metadata',
  GET_LIST_MAILBOX_SSS_REQUESTS: 'Get List Mailbox SSSRequests',
  SEARCH_SERVICE_TREE_OBJECT: 'Search Service Tree Object',
  GET_PRIMARY_FTE_CONTACTS_LIST: 'Get Primary FTE Contacts List',
  DELETE_ROLE_ASSIGNMENT: 'Delete Role Assignment',
  GET_REQUEST_STATUS_MAIL_SSS: 'Get Request Status Mail SSS',
  GET_LIST_MY_REQUEST_STATUS: 'Get List My Request Status',
  GET_REQUEST_STATUS_ENVIRONMENT: 'Get Request Status Environment',
  ENSURE_SYSTEM_ADMIN: 'Ensure System Admin',
  USER_EMAIL_APPROVAL: 'User Email Approval',
  GET_FEATURE_FLAGS: 'Get Feature Flags',
  GET_POLICIES: 'Get All Policies',
  SUBMIT_ENVIRONMENT_RESCAN : 'Submit Environment Rescan',
  GET_SHAREPOINTSITES :'Get SharePoint Sites'
};
