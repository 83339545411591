import { createReducer } from 'typesafe-actions';
import { IEventTelemetry, IExceptionTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { deleteMyApp, deleteMyAppFailed, deleteMyAppSucceeded } from '../../actions';
import { toastError } from '../../../utils/tools/toast';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { telemetryConfig } from '../../../../components/TelemetryService/TelemetryConfig';
import { API_NAMES, API_STATUS, EVENTS } from '../../../constants';

TelemetryService.initialize(telemetryConfig);

export const deleteMyAppReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(deleteMyApp, (state) => {
    return {
      // initial state for the request
      ...state,
      deleteMyAppRequestState: {
        ...state.deleteMyAppRequestState,
        isLoading: true,
        loadingState: LoadingState.loading
      }
    };
  })
  .handleAction(deleteMyAppSucceeded, (state) => {
    TelemetryService.trackEvent({ name: EVENTS.API_CALL } as IEventTelemetry, {
      API: API_NAMES.DELETE_MY_APP,
      Result: API_STATUS.SUCCESS
    });
    return {
      ...state,
      deleteMyAppRequestState: {
        // Override this object in the state spread
        ...state.deleteMyAppRequestState,
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(deleteMyAppFailed, (state, action) => {
    toastError('Failed to delete app', action.payload);
    TelemetryService.trackException({
      exception: { message: API_STATUS.FAIL, name: API_NAMES.DELETE_MY_APP } as Error,
      properties: { API: API_NAMES.DELETE_MY_APP, Payload: action.payload },
      severityLevel: SeverityLevel.Error
    } as IExceptionTelemetry);
    return {
      ...state,
      deleteMyAppRequestState: {
        ...state.deleteMyAppRequestState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    };
  });
