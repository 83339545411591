import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { IRootState } from '../../IRootState';
import { RootActions } from '../../RootActions';
import { getAllEnvironments, httpGetV1 } from '../../../services';
import { getEnvironments, getEnvironmentsFail, getEnvironmentsSuccess } from '../../actions/environment';
import { IEnvironmentsRequest } from '../../../models/Environment/IEnvironmentRequest';
import { AUTH_SCOPE } from '../../../constants';

export const GetEnvironmentsEpic: Epic<RootActions, RootActions, IRootState> = (action$, _store) =>
  action$.pipe(
    filter(isActionOf(getEnvironments)),
    mergeMap((_action$) => {
      const request = {
        url: getAllEnvironments(_action$.payload),
        scope: AUTH_SCOPE.GOVERNANCE
      };
      return from(httpGetV1(request))
        .pipe(
          map((res) => {
            return res.response as IEnvironmentsRequest;
          })
        )
        .pipe(
          mergeMap((result) => {
            return of(getEnvironmentsSuccess(result));
          }),
          catchError((error) => {
            return of(getEnvironmentsFail(error));
          })
        );
    })
  );

export default [GetEnvironmentsEpic];
