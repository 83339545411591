import {
  IMetricTelemetry,
  IExceptionTelemetry,
  IEventTelemetry,
  ITraceTelemetry
} from '@microsoft/applicationinsights-web';
import { TelemetryService } from './TelemetryService';

/**
 * This interface constant helps log telemetry data through TelemetryService
 */
export const telemetryHook = {
  trackMetric: (metric: IMetricTelemetry, customProperties?: { [key: string]: unknown }) =>
    TelemetryService.trackMetric(metric, customProperties),
  trackException: (exception: IExceptionTelemetry) => TelemetryService.trackException(exception),
  trackEvent: (event: IEventTelemetry, customProperties?: { [key: string]: unknown }) =>
    TelemetryService.trackEvent(event, customProperties),
  trackTrace: (trace: ITraceTelemetry, customProperties?: { [key: string]: unknown }) =>
    TelemetryService.trackTrace(trace, customProperties)
};

/**
 * Converts input properties of { string : object } pair to flat array
 * @param inputProps input properties of { string : object } pair
 */
export function flatProperties(inputProps: { [key: string]: unknown } | null | undefined): string[] | null | undefined {
  if (!inputProps) {
    return inputProps;
  }

  const retVal: string[] = [];
  Object.keys(inputProps).forEach((key: string, index: number) => {
    retVal[index] = `${key}: ${String(inputProps[key])}`;
  });

  return retVal;
}
