import { EXECUTED_POLICIES_RESULT } from '../../../shared/constants/enums';
import { ENV_API_STATE, ADMIN_DELETED_ENV_URL } from './Environment.constants';
import { IEnvironmentList } from '../../../shared/models/Environment/IEnvironmentList';
import { IExecutedPolicies } from '../../../shared/models/Environment/IExecutedPolices';

/*
@param {IExecutedPolicies[] | undefined} The list of Executed policies from Governance
@returns {boolean} Result of Attestation
*/
export const isCompliant = (executedPolicies: IExecutedPolicies[] | undefined): boolean => {
  if (executedPolicies?.length === 0 || executedPolicies === undefined) return true;
  for (let i = 0; i < executedPolicies?.length; i += 1) {
    if (executedPolicies[i].status === EXECUTED_POLICIES_RESULT.FAIL
    ) {
      return false;
    }
  }
  return true;
};

export const getActionUrl = (item: IEnvironmentList): string => {
  if (item.complianceState === ENV_API_STATE.DELETED) {
    return ADMIN_DELETED_ENV_URL;
  }
  return `/power-platform/environments/${item.id}/violations`;
}

/*
@param {any} The Environments list from api
@returns {IEnvironmentList[]} Formatted Environments list
*/
export const formatEnvironmentsUtil = (envlist: any): IEnvironmentList[] => {
  const currentEnvironmentList: IEnvironmentList[] = [];
  if (envlist?.length) {
    for (let i = 0; i < envlist.length; i += 1) {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 1);
      if (
        !(envlist[i]?.complianceState === ENV_API_STATE.DELETED && envlist[i]?.deletedOn?.substring(0, 10) <= new Date().toISOString().substring(0, 10) && envlist[i]?.restoreUntilDate === null) &&
        !(envlist[i]?.complianceState === ENV_API_STATE.DELETED && envlist[i]?.restoreUntilDate?.substring(0, 10) <= currentDate.toISOString().substring(0, 10))
      ) {
        const singleEnvironment: IEnvironmentList = {
          id: envlist[i]?.id,
          displayName: envlist[i]?.displayName,
          lastCrawlDate: envlist[i]?.lastCrawlDate,
          violationCount: envlist[i]?.violationCount,
          created: envlist[i]?.created?.substring(0, 10) ?? '',
          quarantineDate: envlist[i]?.quarantineDate?.substring(0, 10) ?? '',
          complianceState: envlist[i]?.complianceState,
          executedPolicies: envlist[i]?.executedPolicies?.map((policy: any) => {
        return {
          policyId: policy.policyId,
          status: policy.status
        };
          }) ?? []
        };
        currentEnvironmentList.push(singleEnvironment);
      }
    }
  }
  return currentEnvironmentList;
};
