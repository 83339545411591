import { determineEnvironment, EnvironmentType } from '../utils/environment';

/**
 * To get the base url for attestation api call
 * @return {string} base-url
 */
const getAttestationBaseUrl = () => {
  const environment = determineEnvironment(location);
  switch (environment) {
    case EnvironmentType.localhost:
      return `${process.env.REACT_APP_ATTESTATION_API_BASE_URL_LOCAL!}`;
    case EnvironmentType.PROD:
      return `${process.env.REACT_APP_ATTESTATION_API_BASE_URL_PROD!}`;
    default:
      return `${process.env.REACT_APP_ATTESTATION_API_BASE_URL_LOCAL!}`;
  }
};

/**
 * To get the base url for governace api call
 * @return {string} base-url
 */
const getGovernanceAPIBaseUrl = () => {
  const environment = determineEnvironment(location);
  switch (environment) {
    case EnvironmentType.localhost:
      return `${process.env.REACT_APP_GOVERNANCE_API_BASE_URL_LOCAL!}`;
    case EnvironmentType.PROD:
      return `${process.env.REACT_APP_GOVERNANCE_API_BASE_URL_PROD!}`;
    default:
      return `${process.env.REACT_APP_GOVERNANCE_API_BASE_URL_LOCAL!}`;
  }
};

/**
 * To get the base url for basic api call.
 * @return {string} base-url
 */
const getBaseUrlBasedOnEnv = (): string => {
  const environment = determineEnvironment(location);
  switch (environment) {
    case EnvironmentType.localhost:
      return `${process.env.REACT_APP_API_BASE_URL_LOCAL!}`;
    case EnvironmentType.PROD:
      return `${process.env.REACT_APP_API_BASE_URL_PROD!}`;
    default:
      return `${process.env.REACT_APP_API_BASE_URL_LOCAL!}`;
  }
};

/**
 * To get the base url for environment request api's call.
 * @return {string} base-url
 */
const getEnvironmentRequestUrl = (): string => {
  const environment = determineEnvironment(location);
  let url: string;
  if (environment === EnvironmentType.PROD) {
    url = process.env.REACT_APP_ENVIRONMENT_REQUEST_URL_PROD!;
  } else {
    url = process.env.REACT_APP_ENVIRONMENT_REQUEST_URL_LOCAL!;
  }
  return url;
};

/**
 * To Do: this method is not in use in whole application we have to understand the purpose
 *  of this method if not required will remove this in refactoring phase.
 * @return {string} base-url
 */
const getOneAccessUrl = (isRevokePage: boolean, pageTag: string): string => {
  const environment = determineEnvironment(location);
  let url: string;
  let pageName: string;
  if (environment === EnvironmentType.PROD) {
    switch (pageTag) {
      case 'app-admin':
        url = process.env.REACT_APP_ONE_ACCESS_APP_ADMIN_URL_PROD!;
        break;
      case 'security-owner':
        url = process.env.REACT_APP_ONE_ACCESS_SECURITY_OWNER_URL_PROD!;
        break;
      case 'compliance-manager':
        url = process.env.REACT_APP_ONE_ACCESS_COMPLIANCE_MANAGER_URL_PROD!;
        break;
      case 'one-finance':
        url = process.env.REACT_APP_ONE_ACCESS_ONE_FINANCE_URL_PORD!;
        break;
      case 'realtime-report':
        url = process.env.REACT_APP_ONE_ACCESS_REALTIME_REPORT_URL_PORD!;
        break;
      case 'ccm-delta':
        url = process.env.REACT_APP_ONE_ACCESS_CCM_DELTA_URL_PORD!;
        break;
      default:
        url = process.env.REACT_APP_ONE_ACCESS_APP_ADMIN_URL_PROD!;
        break;
    }
    pageName = isRevokePage ? process.env.REACT_APP_ONE_ACCESS_REVOKE_PAGENAME_PROD! : '';
  } else {
    switch (pageTag) {
      case 'app-admin':
        url = process.env.REACT_APP_ONE_ACCESS_APP_ADMIN_URL_LOCAL!;
        break;
      case 'security-owner':
        url = process.env.REACT_APP_ONE_ACCESS_SECURITY_OWNER_URL_LOCAL!;
        break;
      case 'compliance-manager':
        url = process.env.REACT_APP_ONE_ACCESS_COMPLIANCE_MANAGER_URL_LOCAL!;
        break;
      case 'one-finance':
        url = process.env.REACT_APP_ONE_ACCESS_ONE_FINANCE_URL_LOCAL!;
        break;
      case 'realtime-report':
        url = process.env.REACT_APP_ONE_ACCESS_REALTIME_REPORT_URL_LOCAL!;
        break;
      case 'ccm-delta':
        url = process.env.REACT_APP_ONE_ACCESS_CCM_DELTA_URL_LOCAL!;
        break;
      default:
        url = process.env.REACT_APP_ONE_ACCESS_APP_ADMIN_URL_LOCAL!;
        break;
    }
    pageName = isRevokePage ? process.env.REACT_APP_ONE_ACCESS_REVOKE_PAGENAME_LOCAL! : '';
  }
  url += pageName;
  return url;
};

export {
  getAttestationBaseUrl,
  getGovernanceAPIBaseUrl,
  getEnvironmentRequestUrl,
  getBaseUrlBasedOnEnv,
  getOneAccessUrl
};
