import { IStyle, mergeStyleSets } from '@fluentui/react';

export const EnvironmentRequestPageStyle = mergeStyleSets({
  pageBackground: {
    selectors: {
      ':global(.ms-DetailsList)': {
        overflow: 'visible'
      }
    }
  } as IStyle,
  scroll: {
    overflowX: 'hidden'
  } as IStyle,
  powerapp: {
    backgroundColor: 'white!important',
    marginTop: '0.1vh',
    marginLeft: '2%',
    height: '93vh',
    width: '165.33vh'
  } as IStyle
});
