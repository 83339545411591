// import { ScrollablePane, FocusZone, css } from '@fluentui/react';
import * as React from 'react';
// import { OneAccessReportPageStyle } from './OneAccessReport.styles';
// import { getOneAccessUrl } from '../../shared/services/Api';
// import { parseQueryString } from '../../shared/utils/tools/string';
import { IOneAccessReportProps, IOneAccessReportState } from './OneAccessReport.types';
import { _Styles } from '../../shared/styles/Page.styles';

export class OneAccessReport extends React.Component<IOneAccessReportProps, IOneAccessReportState> {
  // private pageFocusRef = React.createRef<HTMLDivElement>();

  // public componentDidMount() {
  //   this._focus();
  // }

  // public render(): React.ReactNode {
  //   return (
  //     <ScrollablePane
  //       className={this.props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}
  //     >
  //       <main data-automation-id="OneAccessReport" className={css(OneAccessReportPageStyle.pageBackground)} tabIndex={-1}>
  //         <div className={'ms-DetailsContent'}>
  //           <div className={'Search-Bar-Row'}>
  //             <FocusZone>
  //               <iframe
  //                 title="oneaccessview"
  //                 className={css(OneAccessReportPageStyle.powerbi)}
  //                 frameBorder="0"
  //                 src={this.getUrl()}
  //                 allow="geolocation; microphone; camera"
  //                 sandbox="allow-scripts allow-same-origin"
  //               />
  //             </FocusZone>
  //           </div>
  //         </div>
  //       </main>
  //     </ScrollablePane>
  //   );
  // }

  // private getUrl = (): string => {
  //   const search = parseQueryString(this.props.location.search.substring(1, this.props.location.search.length)) as {
  //     isRevokePage: string;
  //   };
  //   return getOneAccessUrl(search.isRevokePage === undefined ? false : search.isRevokePage === 'true', 
  //   this.props.location.pathname.substring(19,this.props.location.pathname.length));
  // };

  // private _focus = () => {
  //   const node = this.pageFocusRef.current;
  //   if (node) {
  //     node.focus();
  //   }
  // };
}
