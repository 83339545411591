import { css } from '@fluentui/react';
import * as React from 'react';
import { MailboxSSSRequestPageStyle } from './MailboxSSSRequest.styles';
import { IMailboxSSSRequestProps, IMailboxSSSRequestState } from './MailboxSSSRequest.types';
import { _Styles } from '../../shared/styles/Page.styles';
import {MailboxSSSRequestForms} from '../../components/Forms/MailboxSSSRequestForm/MailboxSSSRequestForms';

export class MailboxSSSRequest extends React.Component<IMailboxSSSRequestProps, IMailboxSSSRequestState> {

  render(){
    return (
      <main
        data-automation-id="MailboxSSSRequest"
        className={css(MailboxSSSRequestPageStyle.pageBackground)}
        tabIndex={-1}
      >
        <div className={'ms-DetailsContent'}>
          <div className={'Search-Bar-Row'}>
              <div className={css(MailboxSSSRequestPageStyle.powerapp)}> 
                <MailboxSSSRequestForms submitMailboxSSSRequestState={this.props.submitMailboxSSSRequestState}/>
              </div>
          </div>
        </div>
      </main>
    );
}
}
