import { connect } from 'react-redux';
import { getEnvironments } from '../../../shared/store/actions';
import { IRootState } from '../../../shared/store/IRootState';
import { EnvironmentListActions, EnvironmentsReducerStates } from './EnvironmentList.types';
import { Environments } from './Environments';

// Reducer States
const mapStateToProps = (state: IRootState): EnvironmentsReducerStates => {
  const { getEnvironmentsState } = state;
  return {
    getEnvironmentsState: getEnvironmentsState
  };
};

// Actions
const mapDispatchToProps = (): EnvironmentListActions => {
  return {
    getEnvironments: (query: string) => getEnvironments(query)
  };
};

export const ConnectedEnvironmentList = connect(mapStateToProps, mapDispatchToProps())(Environments);
