import { createReducer } from "typesafe-actions";
import { IRootState } from "../../IRootState";
import { RootActions } from "../../RootActions";
import { initialRootState } from "../../initialRootState";
import { getSharePointPolicies, getSharePointPoliciesFail, getSharePointPoliciesSuccess } from "../../actions/policies/getSharepointPolicies";
import { LoadingState } from "../../../models/ILoading";
import { objectUtils } from "../../../utils";

export const GetSharepointPoliciesReducer = createReducer<IRootState, RootActions>(initialRootState)
.handleAction(getSharePointPolicies, (state) => {
    return {
        ...state,
        sharePointPoliciesRequestState: {
            ...state.sharePointPoliciesRequestState,
            isLoading: true,
            loadingState: LoadingState.loading
        }
    };
})
.handleAction(getSharePointPoliciesSuccess, (state, action) => {
    return {
        ...state,
        sharePointPoliciesRequestState: {
            ...state.sharePointPoliciesRequestState,
            data: objectUtils.convertToObjByKeyAsId(action.payload),
            isLoading: false,
            loadingState: LoadingState.loaded
        }
    };
})
.handleAction(getSharePointPoliciesFail, (state, action) => {
    return {
        ...state,
        sharePointPoliciesRequestState: {
            ...state.sharePointPoliciesRequestState,
            isLoading: false,
            loadingState: LoadingState.error,
            loadingError: action.payload
        }
    };
})