import { isNullOrUndefined } from './tools/object';

export const GUID_PATTERN = '^[a-fA-F0-9]{8}-([a-fA-F0-9]{4}-){3}[a-fA-F0-9]{12}$';

export const isGuid = (str?: string) => {
  if (isNullOrUndefined(str)) {
    return false;
  }

  const pattern = new RegExp(GUID_PATTERN);
  return pattern.test(str || '');
};

export const newGuid = () => {
  // tslint:disable-next-line:no-shadowed-variable
  function newGuid(): string {
    function s4(): string {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }
  return newGuid();
};
