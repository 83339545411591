import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { getSharePointDetail, getSharePointDetailFailed, getSharePointDetailSucceeded } from '../../actions';
import { IRootState } from '../../IRootState';
import { RootActions } from '../../RootActions';
import { httpGetV1 } from '../../../services';
import { AUTH_SCOPE } from '../../../constants';
import { getSharePointDetailUrl } from '../../../services/Api';
import { ISharePointDetail } from '../../../models/SharepointSites/ISharePointDetailRequests';


export const GetSharePointDetailEpic: Epic<RootActions, RootActions, IRootState> = (action$, _store) =>
  action$.pipe(
    filter(isActionOf(getSharePointDetail)),
    mergeMap((_action$) => {
      const request = {
        url: getSharePointDetailUrl(_action$.payload),
        scope: AUTH_SCOPE.GOVERNANCE
      };
      return from(
        httpGetV1(request)
          .pipe(
            map((res) => {
              return res.response as ISharePointDetail;
            })
          )
          .pipe(
            mergeMap((res) => {
              return of(getSharePointDetailSucceeded(res));
            }),
            catchError((error) => {
              return of(getSharePointDetailFailed(error));
            })
          )
      );
    })
  );

export default [GetSharePointDetailEpic];