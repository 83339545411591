import { IconButton, IIconProps, initializeIcons, ITooltipHostStyles, TooltipHost } from '@fluentui/react';
import * as React from 'react';
import { useId } from '@uifabric/react-hooks';

export interface IButtonIconWithTooltipProps {
  tooltipContent: string | JSX.Element | JSX.Element[] | undefined;
}
// Initialize icons in case this example uses them
initializeIcons();

const infoIcon: IIconProps = { iconName: 'Info' };

const calloutProps = { gapSpace: 0 };
// The TooltipHost root uses display: inline by default.
// If that's causing sizing issues or tooltip positioning issues, try overriding to inline-block.
const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: 'inline-block' }
};

export const ButtonIconWithTooltip: React.FunctionComponent<IButtonIconWithTooltipProps> = (props) => {
  // Use useId() to ensure that the ID is unique on the page.
  // (It's also okay to use a plain string and manually ensure uniqueness.
  const tooltipId = useId('tooltip');
  const { tooltipContent } = props;

  return (
    <TooltipHost
      content={tooltipContent}
      // This id is used on the tooltip itself, not the host
      // (so an element with this id only exists when the tooltip is shown)
      id={tooltipId}
      calloutProps={calloutProps}
      styles={hostStyles}
      setAriaDescribedBy={false}
    >
      <IconButton
        iconProps={infoIcon}
        title="Info"
        ariaLabel={'Tooltip: ' + String(tooltipContent)}
        style={{ height: '24px' }}
      />
    </TooltipHost>
  );
};
