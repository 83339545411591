import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IRootState } from '../../shared/store/IRootState';
import { getFeatureFlags } from '../../shared/store/actions';

import { MailboxSSSRequest } from './MailboxSSSRequest';
import {
  IMailboxSSSRequestActions,
  IMailboxSSSRequestMappedProps,
  IConnectedMailboxSSSRequestProps
} from './MailboxSSSRequest.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the MailboxSSSRequest control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IMailboxSSSRequestProps) for the MailboxSSSRequest control
 */
const mapStateToProps = (
  _state: IRootState,
  _ownProps: IConnectedMailboxSSSRequestProps
): IMailboxSSSRequestMappedProps => {
  const { featureFlagsRequestState, submitMailboxSSSRequestState } = _state;
  return {
    featureFlagsState: featureFlagsRequestState,
    submitMailboxSSSRequestState: submitMailboxSSSRequestState
  };
};

/**
 * Maps the dispatch actions to the props for the MailboxSSSRequest control.
 * @param dispatch Dispatches actions to the store
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IMailboxSSSRequestProps) for the MailboxSSSRequest control
 */
const mapDispatchToProps = (dispatch: any): IMailboxSSSRequestActions => {
  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
    getFeatureFlags: () => dispatch(getFeatureFlags())
  };
};

/**
 * A redux connected MailboxSSSRequest control.
 * @description Supports translation
 */
// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export const ConnectedMailboxSSSRequest = withRouter(connect(mapStateToProps, mapDispatchToProps)(MailboxSSSRequest));
