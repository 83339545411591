import { createReducer } from 'typesafe-actions';
import { IEventTelemetry, IExceptionTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { telemetryConfig } from '../../../../components/TelemetryService/TelemetryConfig';
import { API_NAMES, API_STATUS, EVENTS } from '../../../constants';
import { getSharePointSites, getSharePointSitesFail, getSharePointSitesSuccess } from '../../actions/sharePointSites/getSharePointSites';

TelemetryService.initialize(telemetryConfig);

export const getSharePointSitesReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(getSharePointSites, (state) => {
    return {
      ...state,
      getSharePointSitesState: {
        ...state.getSharePointSitesState,
        isLoading: true,
        loadingState: LoadingState.loading
      }
    };
  })
  .handleAction(getSharePointSitesSuccess, (state, action) => {

    TelemetryService.trackEvent({ name: EVENTS.API_CALL } as IEventTelemetry, {
      API: API_NAMES.GET_SHAREPOINTSITES,
      Result: API_STATUS.SUCCESS
    });

    return {
      ...state,
      getSharePointSitesState: {
        ...state.getSharePointSitesState,
        sharePointSitesData: action.payload,
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(getSharePointSitesFail, (state, action) => {

    TelemetryService.trackException({
      exception: { message: API_STATUS.FAIL, name: API_NAMES.GET_SHAREPOINTSITES } as Error,
      properties: { API: API_NAMES.GET_SHAREPOINTSITES, Payload: action.payload },
      severityLevel: SeverityLevel.Error
    } as IExceptionTelemetry);
    return {
      ...state,
      getSharePointSitesState: {
        ...state.getSharePointSitesState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    };
  });
