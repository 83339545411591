import { getAttestationBaseUrl, getGovernanceAPIBaseUrl, getBaseUrlBasedOnEnv } from './BaseURL';

const getListMyRequestStatusUrl = (): string => {
  return `${getBaseUrlBasedOnEnv()}/GovRequest/ListMyRequestStatuses/`;
};

const getRequestStatusMailSSSUrl = (requestId: string): string => {
  return `${getBaseUrlBasedOnEnv()}/GovRequest/RequestStatusMailboxSSS/${requestId}`;
};

const getRequestStatusEnvironmentUrl = (requestId: string): string => {
  return `${getBaseUrlBasedOnEnv()}/GovRequest/RequestStatusEnvironment/${requestId}`;
};

const getListMailboxSSSRequestsUrl = (): string => {
  return `${getBaseUrlBasedOnEnv()}/MailboxSSS/GetListMailboxesForApproval/`;
};

const ensureSystemAdminUrl = (): string => {
  return `${getBaseUrlBasedOnEnv()}/MailboxSSS/EnsureUserAssignedSystemAdmin`;
};

const userEmailApprovalUrl = (): string => {
  return `${getBaseUrlBasedOnEnv()}/MailboxSSS/MailboxSSSUserEmailRouterAccessApproval`;
};

const revokeRoleAssignmentUrl = (id: string, appId?: string) => {
  return `${getBaseUrlBasedOnEnv()}/AzureADPAP/RoleAssignment/${appId}/${id}`;
};

const getListOwnedAppsUrl = (): string => {
  return `${getBaseUrlBasedOnEnv()}/PowerApps/MyApps`;
};

const getAppMetadataUrl = (appId: string): string => {
  return `${getBaseUrlBasedOnEnv()}/PowerApps/MyApps/${appId}/metadata`;
};

const getDeleteAppUrl = (appId: string): string => {
  return `${getBaseUrlBasedOnEnv()}/PowerApps/MyApps/${appId}`;
};

const getFeatureFlagUrl = (): string => {
  return `${getBaseUrlBasedOnEnv()}/FeatureFlag`;
};

const submitEnvironmentRequestUrl = (): string => {
  return `${getBaseUrlBasedOnEnv()}/EnvironmentLifeCycle/CreateRequest/`;
};

const getServiceTreeObjectUrl = (id: string): string => {
  return `${getBaseUrlBasedOnEnv()}/ServiceTree/${id}`;
};

const getServiceTreeSearchUrl = (query: string): string => {
  return `${getBaseUrlBasedOnEnv()}/ServiceTree/Services/Search?query=${query}`;
};

const getServiceTreeOrganizationSearchUrl = (query: string): string => {
  return `${getBaseUrlBasedOnEnv()}/ServiceTree/Organizations/Search?query=${query}`;
};

const getPrimaryFTEContactsListUrl = (): string => {
  return `${getBaseUrlBasedOnEnv()}/MSGraph/SearchListObjectsInformation/`;
};

const checkIfAServiceAccountUrl = (address: string): string => {
  return `${getBaseUrlBasedOnEnv()}/MSGraph/IsServiceAccountChecking/${address}`;
};

const checkMembersInSecurityGroupUrl = (): string => {
  return `${getBaseUrlBasedOnEnv()}/MSGraph/ValidateMembersOfSecurityGroup/`;
};

const getAllEnvironments = (query: string): string => {
  return `${getGovernanceAPIBaseUrl()}/v1/assets/ppenvironment?${query}`;
};

const getEnvironmentAttestationUrl = (assetId: string): string => {
  return `${getAttestationBaseUrl()}/attestation/environment/detail/${assetId}`;
};

const setEnvironmentAttestationUrl = (assetType: string): string => {
  return `${getAttestationBaseUrl()}/submit-attestation/${assetType}`;
};

const getPoliciesUrl = (): string => {
  return `${getGovernanceAPIBaseUrl()}/v1/policies/ppenvironments`;
};
const getSharePointPoliciesUrl = (): string => {
  return `${getGovernanceAPIBaseUrl()}/v1/policies/m365sharepoints`;
};
const getSharePointDetailUrl = (assetId: string): string => {
  const mockServiceFlagStatus = `${process.env.REACT_APP_MOCK_FLAG_STATUS}`;
  return mockServiceFlagStatus === 'true'
    ? `${getGovernanceAPIBaseUrl()}/v1/assets/m365sharepoints/${assetId}?Mockflag=true`
    : `${getGovernanceAPIBaseUrl()}/v1/assets/m365sharepoints/${assetId}`;
};
const submitMailboxSSSRequestUrl = (): string => {
  return `${getBaseUrlBasedOnEnv()}/MailboxSSS/SubmitRequest/`;
};
const rescanAssetUrl = (): string => {
  return `${getGovernanceAPIBaseUrl()}/v1/scan`;
};
const getAllSharePointSites = (query: string): string => {
  const mockServiceFlagStatus = `${process.env.REACT_APP_MOCK_FLAG_STATUS}`;
  return mockServiceFlagStatus === 'true'
  ? `${getGovernanceAPIBaseUrl()}/v1/assets/m365sharepoints?Mockflag=true&${query}`
  : `${getGovernanceAPIBaseUrl()}/v1/assets/m365sharepoints?${query}`;
};
export {
  setEnvironmentAttestationUrl,
  getEnvironmentAttestationUrl,
  getAllEnvironments,
  checkIfAServiceAccountUrl,
  getPrimaryFTEContactsListUrl,
  getServiceTreeOrganizationSearchUrl,
  getServiceTreeSearchUrl,
  getServiceTreeObjectUrl,
  submitEnvironmentRequestUrl,
  getFeatureFlagUrl,
  getDeleteAppUrl,
  getAppMetadataUrl,
  getListOwnedAppsUrl,
  revokeRoleAssignmentUrl,
  userEmailApprovalUrl,
  ensureSystemAdminUrl,
  getListMailboxSSSRequestsUrl,
  getRequestStatusEnvironmentUrl,
  getRequestStatusMailSSSUrl,
  getListMyRequestStatusUrl,
  getPoliciesUrl,
  submitMailboxSSSRequestUrl,
  rescanAssetUrl,
  getAllSharePointSites,
  getSharePointPoliciesUrl,
  getSharePointDetailUrl,
  checkMembersInSecurityGroupUrl
};
