import { Icon, Link, Stack, Text, initializeIcons } from '@fluentui/react';
import React from 'react';

interface SuccessBannerProps {
  requestName: string;
  newRequest: string;
  [key: string]: any;
}

initializeIcons();

const SuccessBanner: React.FC<SuccessBannerProps> = ({ requestName, newRequest, ...customProps }) => {
  const isReflowMode = window.innerWidth <= 600; 
  const iconStyles = {
    fontSize: isReflowMode ? '50px' : '100px',
    color: 'green',
  };
  const linkStyles ={
    color: '#1b78b9',
    textDecoration: 'underline'
  };
  const EnvironmentCustomMessage = requestName==='Environment' ? `and will be sent for approval to "${customProps.managerAlias}"` : '';
  return (
    <Stack
      verticalAlign="center"
      horizontalAlign="center"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      tokens={{ childrenGap: 20 }}
      styles={{
        root: {
          height: isReflowMode ? 'auto' : '70vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: isReflowMode ? '20px' : '0',
          marginTop: isReflowMode ? '20px' : '0',
        }
      }}
    >
      <Icon iconName="CompletedSolid" style={iconStyles} aria-label='Green Tick Icon'/>
      <Text variant={'xLarge'}>{`${requestName} Request has been submitted successfully ${EnvironmentCustomMessage}`}</Text>
      <Text variant={'mediumPlus'}>
        Please visit <Link href="/list-request-status" style ={linkStyles} aria-label={`View request status`}>Request status</Link> to track the status of the request
      </Text>
      <Text variant={'mediumPlus'}>
        <Link href={newRequest} style ={linkStyles} aria-label={`submit new ${requestName} request`}>Click here</Link> to submit New {requestName} request
      </Text>
    </Stack>
  );
};

export default SuccessBanner;
