import { createAction } from 'typesafe-actions';
import { IHttpError } from '../../../models/IHttpError';
import { IServiceTreeObject } from '../../../models/ServiceTree/IServiceTreeObject';
import {
  GET_SERVICE_TREE_OBJECT,
  GET_SERVICE_TREE_OBJECT_SUCCESS,
  GET_SERVICE_TREE_OBJECT_FAIL
} from '../../constants';

export const searchServiceTreeObject = createAction(
  GET_SERVICE_TREE_OBJECT,
  (serviceTreeId: string, fieldName?: string) => {
    return { serviceTreeId, fieldName };
  }
)<{ serviceTreeId: string; fieldName: string | undefined }>();

export const searchServiceTreeObjectSuccess = createAction(
  GET_SERVICE_TREE_OBJECT_SUCCESS,
  (serviceTreeResult: IServiceTreeObject[], fieldName?: string) => ({ serviceTreeResult, fieldName })
)<{ serviceTreeResult: IServiceTreeObject[]; fieldName: string | undefined }>();

export const searchServiceTreeObjectFail = createAction(
  GET_SERVICE_TREE_OBJECT_FAIL,
  (err: IHttpError) => err
)<IHttpError>();
