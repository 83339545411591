import { createReducer } from 'typesafe-actions';
import { IEventTelemetry, IExceptionTelemetry, SeverityLevel } from '@microsoft/applicationinsights-common';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { EVENTS, API_NAMES, API_STATUS } from '../../../constants';
import { telemetryConfig } from '../../../../components/TelemetryService/TelemetryConfig';
import { rescanEnvironment, rescanEnvironmentFailed, rescanEnvironmentSucceeded } from '../../actions';

TelemetryService.initialize(telemetryConfig);

export const SubmitEnvironmentRescanReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(rescanEnvironment, (state) => {
    return {
      // initial state for the request
      ...state,
      setEnvironmentRescanRequestState : {
        ...state.setEnvironmentRescanRequestState,
        isLoading: false,
        loadingState: LoadingState.loading
      }
    };
  })
  .handleAction(rescanEnvironmentSucceeded, (state) => {
    TelemetryService.trackEvent({ name: EVENTS.API_CALL } as IEventTelemetry, {
      API: API_NAMES.SUBMIT_ENVIRONMENT_RESCAN,
      Result: API_STATUS.SUCCESS
    });

    return {
      ...state,
      setEnvironmentRescanRequestState: {
        ...state.setEnvironmentRescanRequestState,
        isLoading: true,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(rescanEnvironmentFailed, (state, action) => {
    TelemetryService.trackException({
      exception: { message: API_STATUS.FAIL, name: API_NAMES.SUBMIT_ENVIRONMENT_RESCAN } as Error,
      properties: { API: API_NAMES.SUBMIT_ENVIRONMENT_RESCAN, Payload: action.payload },
      severityLevel: SeverityLevel.Error
    } as IExceptionTelemetry);
    return {
      ...state,
      set: {
        ...state
      }
    };
  });
