import { createAction } from 'typesafe-actions';
import { GET_SHAREPOINTSITES, GET_SHAREPOINTSITES_FAIL, GET_SHAREPOINTSITES_SUCCESS} from '../../constants';
import {ISharePointSitesLists } from '../../../models/SharepointSites/ISharePointSitesList';
import { IHttpError } from '../../../models/IHttpError';

export const getSharePointSites = createAction(GET_SHAREPOINTSITES, (query: string) => query)();

export const getSharePointSitesSuccess = createAction(
    GET_SHAREPOINTSITES_SUCCESS,
    (ISharePointSitesList: ISharePointSitesLists) => ISharePointSitesList
  )<ISharePointSitesLists>();

export const getSharePointSitesFail = createAction(GET_SHAREPOINTSITES_FAIL, (err: IHttpError) => err)<IHttpError>();


