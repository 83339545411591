import * as Yup from 'yup';

export const AppMetadataFormValidation = Yup.object({
  purpose: Yup.string().required('Purpose must be specified.'),
  justification: Yup.string().required('Justification field is required.'),
  description: Yup.string().required('Description field is required.'),
  audience: Yup.string().required('Audience is required.'),
  displayName: Yup.string().required('Name is required.'),
  primaryFTEContacts: Yup.string().required('2 FTE contacts are required.').nullable(),
  owningTeam: Yup.string().required('Owning team must be specified.').nullable(),
  dataClassification: Yup.string().required('Data classification must be specified.').nullable()
});
