import {
  ScrollablePane,
  CommandBar,
  css,
  ShimmeredDetailsList,
  CheckboxVisibility,
  DetailsListLayoutMode,
  Pivot,
  PivotItem,
  Label
} from '@fluentui/react';
import * as React from 'react';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { RequestStatusEnvironmentProps, RequestStatusEnvironmentState } from './RequestStatusEnvironment.types';
import { getRequestStatusEnvironmentCommandBarItems } from './RequestStatusEnvironment.commands';
import { getTableRequestStatusEnvironmentColumns } from './RequestStatusEnvironment.columns';
import {
  RequestStatusEnvironmentPageCommandBarStyle,
  RequestStatusEnvironmentPageContentStyles,
  RequestStatusEnvironmentPageStyle,
  RequestStatusEnvironmentPivotStyle
} from './RequestStatusEnvironment.styles';
import { _Styles } from '../../../shared/styles/Page.styles';
import { renderEveryOtherRow } from '../../../shared/utils/fabric/renderEveryOtherRow';
import { isNullOrUndefined } from '../../../shared/utils/tools/object';
import ProgressStepper from '../../../components/ProgressStepper/ProgressStepper';
import {
  ProgressStepperNodeState,
  ProgressStepperNodeTypes
} from '../../../components/ProgressStepper/ProgressStepper.types';
import { dateTimeToLocalDateString } from '../../../shared/utils/tools/string';
import Loading from '../../../components/Loading/Loading';

const EmptyMailboxSSSSteps = [
  {
    description: 'Submission',
    nodeType: ProgressStepperNodeTypes.Empty
  } as ProgressStepperNodeState,
  {
    description: 'Manager Approval',
    nodeType: ProgressStepperNodeTypes.Empty
  } as ProgressStepperNodeState,
  // {
  //   description: 'Admin Approval',
  //   nodeType: ProgressStepperNodeTypes.Empty
  // } as ProgressStepperNodeState,
  {
    description: 'Environment Provision',
    nodeType: ProgressStepperNodeTypes.Empty
  } as ProgressStepperNodeState
];

export class RequestStatusEnvironment extends React.Component<
  RequestStatusEnvironmentProps,
  RequestStatusEnvironmentState
> {
  constructor(props: RequestStatusEnvironmentProps) {
    super(props);
    this.state = {
      columns: getTableRequestStatusEnvironmentColumns(),
      currentStepNumber: -1,
      lstSteps: [...EmptyMailboxSSSSteps]
    };
  }

  public componentDidMount() {
    this.refresh();
  }

  public componentDidUpdate(preProps: RequestStatusEnvironmentProps, _preState: RequestStatusEnvironmentState) {
    if (
      preProps.requestStatusEnvironmentState.isLoading === true &&
      this.props.requestStatusEnvironmentState.isLoading === false
    ) {
      if (this.props.requestStatusEnvironmentState.lstAuditLogs === null) {
        return;
      }

      const lstAuditLogs = [...this.props.requestStatusEnvironmentState.lstAuditLogs];
      // eslint-disable-next-line react/no-access-state-in-setstate
      const lstSteps = [...this.state.lstSteps];
      for (let i = 0; i < lstAuditLogs.length; i += 1) {
        switch (lstAuditLogs[i].footPace) {
          case 'RequestSubmit':
            lstSteps[0].nodeType = ProgressStepperNodeTypes.Complete;
            lstSteps[0].subDescription = `Complete ${dateTimeToLocalDateString(lstAuditLogs[i].timeStamp)}`;
            break;
          case ('Stage1ApprovalReplied' || 'EnvRequestApproved'):
            lstSteps[1].nodeType = ProgressStepperNodeTypes.Complete;
            lstSteps[1].subDescription = `Complete ${dateTimeToLocalDateString(lstAuditLogs[i].timeStamp)}`;
            break;
          case ('Stage1ApprovalRejected' || 'EnvRequestRejected'):
            lstSteps[1].nodeType = ProgressStepperNodeTypes.Error;
            lstSteps[1].subDescription = `Rejected ${dateTimeToLocalDateString(lstAuditLogs[i].timeStamp)}`;
            break;
          // case 'Stage2ApprovalReplied':
          //   lstSteps[2].nodeType = ProgressStepperNodeTypes.Complete;
          //   lstSteps[2].subDescription = `Complete ${dateTimeToLocalDateString(lstAuditLogs[i].timeStamp)}`;
          //   break;
          // case 'Stage2ApprovalRejected':
          //   lstSteps[2].nodeType = ProgressStepperNodeTypes.Error;
          //   lstSteps[2].subDescription = `Rejected ${dateTimeToLocalDateString(lstAuditLogs[i].timeStamp)}`;
          //   break;
          case 'RequestComplete':
            if (lstAuditLogs[i].status === 'Created Power Platform Environment') {
              lstSteps[2].nodeType = ProgressStepperNodeTypes.Complete;
              lstSteps[2].subDescription = `Complete ${dateTimeToLocalDateString(lstAuditLogs[i].timeStamp)}`;
            }
            break;
          case 'RequestErrorWithNotification':
            for (let j = 0; lstSteps.length; j += 1) {
              if (lstSteps[j].subDescription === undefined) {
                if (j === 1) {
                  j = 0; // Submit environment error
                }
                lstSteps[j].nodeType = ProgressStepperNodeTypes.Error;
                lstSteps[j].subDescription = `Error ${dateTimeToLocalDateString(lstAuditLogs[i].timeStamp)}`;
                break;
              }
            }
            break;
          default:
            break;
        }
      }

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        lstSteps: lstSteps
      });
    }
  }

  public render(): React.ReactNode {
    const commands = getRequestStatusEnvironmentCommandBarItems(this.refresh);
    return (
      <ScrollablePane
        className={this.props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}
      >
        <main
          data-automation-id="RequestStatusEnvironment"
          className={css(RequestStatusEnvironmentPageStyle.pageBackground)}
          tabIndex={-1}
        >
          <PageHeader pageTitle={'Request Status Create Environment'} />
          <CommandBar
            styles={RequestStatusEnvironmentPageCommandBarStyle}
            items={commands}
            ariaLabel={'Request status detail Commands'}
          />
          <ProgressStepper
            lstSteps={this.state.lstSteps}
            enableSubDescription={true}
            isVertical={false}
            currentStepNumber={this.state.currentStepNumber}
            onStepClicked={this.onStepClicked}
          />
          {this.props.requestStatusEnvironmentState.isLoading ? (
            <Loading />
          ) : (
            <Pivot aria-label="Mailbox SSS detail request status" styles={RequestStatusEnvironmentPivotStyle}>
              <PivotItem
                headerText="Request Details"
                headerButtonProps={{
                  'data-order': 2,
                  'data-title': 'Request Details'
                }}
              >
                {this.props.requestStatusEnvironmentState.isLoading === false &&
                this.props.requestStatusEnvironmentState.environmentRequest !== null ? (
                  <div className={RequestStatusEnvironmentPageContentStyles.detailInfo}>
                    <div className={RequestStatusEnvironmentPageContentStyles.detailLine}>
                      <Label className={RequestStatusEnvironmentPageContentStyles.detailLabel}>Request Email</Label>
                      <div className={RequestStatusEnvironmentPageContentStyles.detailData}>
                        {this.props.requestStatusEnvironmentState.environmentRequest.requestorEmail}
                      </div>
                    </div>
                    <div className={RequestStatusEnvironmentPageContentStyles.detailLine}>
                      <Label className={RequestStatusEnvironmentPageContentStyles.detailLabel}>Environment Name</Label>
                      <div className={RequestStatusEnvironmentPageContentStyles.detailData}>
                        {this.props.requestStatusEnvironmentState.environmentRequest.displayName}
                      </div>
                    </div>
                    <div className={RequestStatusEnvironmentPageContentStyles.detailLine}>
                      <Label className={RequestStatusEnvironmentPageContentStyles.detailLabel}>URL</Label>
                      <div className={RequestStatusEnvironmentPageContentStyles.detailData}>
                        {this.props.requestStatusEnvironmentState.environmentRequest.CDSinstanceURL}
                      </div>
                    </div>
                    <div className={RequestStatusEnvironmentPageContentStyles.detailLine}>
                      <Label className={RequestStatusEnvironmentPageContentStyles.detailLabel}>
                        Environment Description
                      </Label>
                      <div className={RequestStatusEnvironmentPageContentStyles.detailData}>
                        {this.props.requestStatusEnvironmentState.environmentRequest.environmentDescription}
                      </div>
                    </div>
                    <div className={RequestStatusEnvironmentPageContentStyles.detailLine}>
                      <Label className={RequestStatusEnvironmentPageContentStyles.detailLabel}>SKU</Label>
                      <div className={RequestStatusEnvironmentPageContentStyles.detailData}>
                        {this.props.requestStatusEnvironmentState.environmentRequest.sku}
                      </div>
                    </div>
                    <div className={RequestStatusEnvironmentPageContentStyles.detailLine}>
                      <Label className={RequestStatusEnvironmentPageContentStyles.detailLabel}>
                        Environment Purpose
                      </Label>
                      <div className={RequestStatusEnvironmentPageContentStyles.detailData}>
                        {this.props.requestStatusEnvironmentState.environmentRequest.environmentPurpose}
                      </div>
                    </div>
                    <div className={RequestStatusEnvironmentPageContentStyles.detailLine}>
                      <Label className={RequestStatusEnvironmentPageContentStyles.detailLabel}>Currency</Label>
                      <div className={RequestStatusEnvironmentPageContentStyles.detailData}>
                        {this.props.requestStatusEnvironmentState.environmentRequest.currency}
                      </div>
                    </div>
                    <div className={RequestStatusEnvironmentPageContentStyles.detailLine}>
                      <Label className={RequestStatusEnvironmentPageContentStyles.detailLabel}>Language</Label>
                      <div className={RequestStatusEnvironmentPageContentStyles.detailData}>
                        {this.props.requestStatusEnvironmentState.environmentRequest.language}
                      </div>
                    </div>
                    <div className={RequestStatusEnvironmentPageContentStyles.detailLine}>
                      <Label className={RequestStatusEnvironmentPageContentStyles.detailLabel}>Region</Label>
                      <div className={RequestStatusEnvironmentPageContentStyles.detailData}>
                        {this.props.requestStatusEnvironmentState.environmentRequest.region}
                      </div>
                    </div>
                    <div className={RequestStatusEnvironmentPageContentStyles.detailLine}>
                      <Label className={RequestStatusEnvironmentPageContentStyles.detailLabel}>
                        Requested DLP Policy
                      </Label>
                      <div className={RequestStatusEnvironmentPageContentStyles.detailData}>
                        {this.props.requestStatusEnvironmentState.environmentRequest.requestedDLPPolicy}
                      </div>
                    </div>
                    <div className={RequestStatusEnvironmentPageContentStyles.detailLine}>
                      <Label className={RequestStatusEnvironmentPageContentStyles.detailLabel}>
                        Data Classification
                      </Label>
                      <div className={RequestStatusEnvironmentPageContentStyles.detailData}>
                        {this.props.requestStatusEnvironmentState.environmentRequest.dataClassification}
                      </div>
                    </div>
                    <div className={RequestStatusEnvironmentPageContentStyles.detailLine}>
                      <Label className={RequestStatusEnvironmentPageContentStyles.detailLabel}>
                        Primary FTE Contacts
                      </Label>
                      <div className={RequestStatusEnvironmentPageContentStyles.detailData}>
                        {this.props.requestStatusEnvironmentState.environmentRequest.primaryFTEContacts}
                      </div>
                    </div>
                    <div className={RequestStatusEnvironmentPageContentStyles.detailLine}>
                      <Label className={RequestStatusEnvironmentPageContentStyles.detailLabel}>Owning Team ID</Label>
                      <div className={RequestStatusEnvironmentPageContentStyles.detailData}>
                        {this.props.requestStatusEnvironmentState.environmentRequest.owningTeamGuid}
                      </div>
                    </div>
                    <div className={RequestStatusEnvironmentPageContentStyles.detailLine}>
                      <Label className={RequestStatusEnvironmentPageContentStyles.detailLabel}>Business Team ID</Label>
                      <div className={RequestStatusEnvironmentPageContentStyles.detailData}>
                        {this.props.requestStatusEnvironmentState.environmentRequest.bussinessTeamGuid}
                      </div>
                    </div>
                    <div className={RequestStatusEnvironmentPageContentStyles.detailLine}>
                      <Label className={RequestStatusEnvironmentPageContentStyles.detailLabel}>
                        Existing Security Group
                      </Label>
                      <div className={RequestStatusEnvironmentPageContentStyles.detailData}>
                        {this.props.requestStatusEnvironmentState.environmentRequest.existingSecurityGroup}
                      </div>
                    </div>
                    <div className={RequestStatusEnvironmentPageContentStyles.detailLine}>
                      <Label className={RequestStatusEnvironmentPageContentStyles.detailLabel}>
                        Personal (GDPR) Data
                      </Label>
                      <div className={RequestStatusEnvironmentPageContentStyles.detailData}>
                        {this.props.requestStatusEnvironmentState.environmentRequest.gdpr ? 'True' : 'False'}
                      </div>
                    </div>
                    <div className={RequestStatusEnvironmentPageContentStyles.detailLine}>
                      <Label className={RequestStatusEnvironmentPageContentStyles.detailLabel}>
                        Tented Information
                      </Label>
                      <div className={RequestStatusEnvironmentPageContentStyles.detailData}>
                        {this.props.requestStatusEnvironmentState.environmentRequest.tentedInformation
                          ? 'True'
                          : 'False'}
                      </div>
                    </div>
                    <div className={RequestStatusEnvironmentPageContentStyles.detailLine}>
                      <Label className={RequestStatusEnvironmentPageContentStyles.detailLabel}>SOX Applications</Label>
                      <div className={RequestStatusEnvironmentPageContentStyles.detailData}>
                        {this.props.requestStatusEnvironmentState.environmentRequest.soxApplication ? 'True' : 'False'}
                      </div>
                    </div>
                    <div className={RequestStatusEnvironmentPageContentStyles.detailLine}>
                      <Label className={RequestStatusEnvironmentPageContentStyles.detailLabel}>HIPAA Data</Label>
                      <div className={RequestStatusEnvironmentPageContentStyles.detailData}>
                        {this.props.requestStatusEnvironmentState.environmentRequest.hipaa ? 'True' : 'False'}
                      </div>
                    </div>
                    <div className={RequestStatusEnvironmentPageContentStyles.detailLine}>
                      <Label className={RequestStatusEnvironmentPageContentStyles.detailLabel}>
                        Business Justification
                      </Label>
                      <div className={RequestStatusEnvironmentPageContentStyles.detailData}>
                        {this.props.requestStatusEnvironmentState.environmentRequest.businessJustification}
                      </div>
                    </div>
                  </div>
                ) : null}
              </PivotItem>
              <PivotItem
                headerText="History"
                headerButtonProps={{
                  'data-order': 1,
                  'data-title': 'History'
                }}
              >
                <ShimmeredDetailsList
                  setKey="requestId"
                  className={RequestStatusEnvironmentPageContentStyles.table}
                  ariaLabelForGrid={'My Environment Requests Statuses'}
                  ariaLabelForSelectionColumn="Toggle my request status selection"
                  isHeaderVisible={true}
                  checkboxVisibility={CheckboxVisibility.hidden}
                  items={
                    !isNullOrUndefined(this.props.requestStatusEnvironmentState.lstAuditLogs) &&
                    !this.props.requestStatusEnvironmentState.isLoading
                      ? this.props.requestStatusEnvironmentState.lstAuditLogs
                      : []
                  }
                  // onColumnHeaderClick={this.onColumnClick}
                  enableShimmer={this.props.requestStatusEnvironmentState.isLoading}
                  onRenderRow={renderEveryOtherRow}
                  columns={this.state.columns}
                  layoutMode={DetailsListLayoutMode.fixedColumns}
                />
              </PivotItem>
            </Pivot>
          )}
        </main>
      </ScrollablePane>
    );
  }

  private refresh = () => {
    const lstSteps = [...this.state.lstSteps];
    for (let i = 0; i < lstSteps.length; i += 1) {
      lstSteps[i].subDescription = undefined;
      lstSteps[i].nodeType = ProgressStepperNodeTypes.Empty;
    }

    this.setState({
      lstSteps: [...lstSteps]
    });
    this.props.getRequestStatusMailSSS(
      this.props.match.params.requestId === undefined ? '' : this.props.match.params.requestId
    );

    this.props.getRequestStatusEnvironment(
      this.props.match.params.requestId === undefined ? '' : this.props.match.params.requestId
    );
  };

  private onStepClicked = (index: number) => {
    this.setState({
      currentStepNumber: index
    });
  };
}
