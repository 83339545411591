import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import {
  getListMailboxSSSRequests,
  getListMailboxSSSRequestsFail,
  getListMailboxSSSRequestsSuccess
} from '../../actions';
import { IRootState } from '../../IRootState';
import { RootActions } from '../../RootActions';
import { IMailboxSSSRequest } from '../../../models/MailboxSSS/IMailboxSSSRequest';
import { getListMailboxSSSRequestsUrl, httpGet } from '../../../services';

export const GetListMailboxSSSRequestsEpic: Epic<
  RootActions,
  RootActions,
  IRootState
  // ,typeof API
> = (
  action$,
  _store
  // { getListMailboxSSSRequestsApi }
) =>
  action$.pipe(
    filter(isActionOf(getListMailboxSSSRequests)),
    mergeMap((_action$) => {
      // var a=action$.payload.appId
      return from(httpGet(getListMailboxSSSRequestsUrl()))
        .pipe(
          map((res) => {
            return res.response as IMailboxSSSRequest[];
          })
        )
        .pipe(
          mergeMap((result) => {
            return of(getListMailboxSSSRequestsSuccess(result));
          }),
          catchError((error) => {
            return of(getListMailboxSSSRequestsFail(error));
          })
        );
      // .pipe(
      //   mergeMap(
      //     (result) =>
      //       of(setExampleListCount(result), setGraphObjectCount(result), getRoleAssignmentListSuccess(result)) //you can fire multiple actions
      //     // return getRoleAssignmentListSuccess(result);
      //   ),
      //   catchError((error) => of(getRoleAssignmentListFail(error)))
      // )
    })
  );

export default [GetListMailboxSSSRequestsEpic];
