import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { userEmailApproval, userEmailApprovalFail, userEmailApprovalSuccess } from '../../actions';
import { IRootState } from '../../IRootState';
import { RootActions } from '../../RootActions';
import { httpPost, userEmailApprovalUrl } from '../../../services';

export const UserEmailApprovalEpic: Epic<RootActions, RootActions, IRootState> = (action$, _store) => {
  return action$.pipe(
    filter(isActionOf(userEmailApproval)),
    mergeMap((action$) => {
      const body = {
        isApproved: action$.payload.isApproved,
        businessJustification: action$.payload.businessJustification,
        request: action$.payload.request
      };
      return from(httpPost(userEmailApprovalUrl(), body))
        .pipe(
          map((res) => {
            return res.response as number;
          })
        )
        .pipe(
          mergeMap((result) => {
            return of(userEmailApprovalSuccess(result));
          }),
          catchError((error) => {
            return of(userEmailApprovalFail(error));
          })
        );
    })
  );
};

export default [UserEmailApprovalEpic];
