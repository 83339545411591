import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { IRootState } from '../../IRootState';
import { RootActions } from '../../RootActions';
import { httpGetV1 } from '../../../services';
import { AUTH_SCOPE } from '../../../constants';
import { getSharePointSites, getSharePointSitesFail, getSharePointSitesSuccess } from '../../actions';
import { getAllSharePointSites } from '../../../services/Api';
import { ISharePointSitesLists } from '../../../models/SharepointSites/ISharePointSitesList';

export const GetSharePointSitesEpic: Epic<RootActions, RootActions, IRootState> = (action$, _store) =>
  action$.pipe(
    filter(isActionOf(getSharePointSites)),
    mergeMap((_action$) => {
      const request = {
        url: getAllSharePointSites(_action$.payload),
        scope: AUTH_SCOPE.GOVERNANCE
      };
      return from(httpGetV1(request))
        .pipe(
          map((res) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return res.response as ISharePointSitesLists;
          })
        )
        .pipe(
          mergeMap((result) => {
            return of(getSharePointSitesSuccess(result));
          }),
          catchError((error) => {
            return of(getSharePointSitesFail(error));
          })
        );
    })
  );

export default [GetSharePointSitesEpic];
