import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { getAppMetadata, getAppMetadataFailed, getAppMetadataSucceeded } from '../../actions';
import { IRootState } from '../../IRootState';
import { RootActions } from '../../RootActions';
import { getAppMetadataUrl, httpGet } from '../../../services';
import { IAppMetadata } from '../../../models/AppsList/IAppMetadataRequest';

export const GetAppMetadataEpic: Epic<RootActions, RootActions, IRootState> = (action$, _store) =>
  action$.pipe(
    filter(isActionOf(getAppMetadata)),
    mergeMap((_action$) => {
      return from(
        httpGet(getAppMetadataUrl(_action$.payload))
          .pipe(
            map((res) => {
              return res.response as IAppMetadata;
            })
          )
          .pipe(
            mergeMap((res) => {
              return of(getAppMetadataSucceeded(res));
            }),
            catchError((error) => {
              return of(getAppMetadataFailed(error));
            })
          )
      );
    })
  );

export default [GetAppMetadataEpic];
