import { createAction } from 'typesafe-actions';
import { IEnvironmentDetail } from '../../../models/Environment/IEnvironmentDetailRequest';
import { IHttpError } from '../../../models/IHttpError';
import { GET_ENVIRONMENT_DETAIL, GET_ENVIRONMENT_DETAIL_SUCCESS, GET_ENVIRONMENT_DETAIL_FAIL } from '../../constants';

export const getEnvironmentDetail = createAction(
  GET_ENVIRONMENT_DETAIL,
  (environmentId: string) => environmentId
)<string>();
export const getEnvironmentDetailFailed = createAction(
  GET_ENVIRONMENT_DETAIL_FAIL,
  (err: IHttpError) => err
)<IHttpError>();
export const getEnvironmentDetailSucceeded = createAction(
  GET_ENVIRONMENT_DETAIL_SUCCESS,
  (data: IEnvironmentDetail) => data
)<IEnvironmentDetail>();
