import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { getListMyRequestStatus, getListMyRequestStatusFail, getListMyRequestStatusSuccess } from '../../actions';
import { IRootState } from '../../IRootState';
import { RootActions } from '../../RootActions';
import { getListMyRequestStatusUrl, httpPost } from '../../../services';
import { IMyRequestStatus } from '../../../models/myRequestStatus/IMyRequestStatus';

export const GetListMyRequestStatusEpic: Epic<RootActions, RootActions, IRootState> = (
  action$,
  _store
  // { getListMyRequestStatusApi }
) =>
  action$.pipe(
    filter(isActionOf(getListMyRequestStatus)),
    mergeMap((_action$) => {
      // var a=action$.payload.appId
      const body = {};
      return from(httpPost(getListMyRequestStatusUrl(), body))
        .pipe(
          map((res) => {
            const lst = res.response.listMyRequestStatuses as IMyRequestStatus[];
            const hasMore = res.response.hasMore as boolean;
            return { lstMyRequestStatus: lst, hasMore: hasMore };
          })
        )
        .pipe(
          mergeMap(({ lstMyRequestStatus, hasMore }) => {
            return of(getListMyRequestStatusSuccess(lstMyRequestStatus, hasMore));
          }),
          catchError((error) => {
            return of(getListMyRequestStatusFail(error));
          })
        );
      // .pipe(
      //   mergeMap(
      //     (result) =>
      //       of(setExampleListCount(result), setGraphObjectCount(result), getRoleAssignmentListSuccess(result)) //you can fire multiple actions
      //     // return getRoleAssignmentListSuccess(result);
      //   ),
      //   catchError((error) => of(getRoleAssignmentListFail(error)))
      // )
    })
  );

export default [GetListMyRequestStatusEpic];
