import { getTheme, IDetailsRowStyles, IDetailsRowProps, DetailsRow } from '@fluentui/react';
import * as React from 'react';

export const renderEveryOtherRow = (props: IDetailsRowProps | undefined): JSX.Element => {
  const customStyles: Partial<IDetailsRowStyles> = {};
  const theme = getTheme();
  if (props) {
    if (props.itemIndex % 2 === 0) {
      // Every other row renders with a different background color
      customStyles.root = { backgroundColor: theme.palette.themeLighterAlt };
    }

    return <DetailsRow {...props} styles={customStyles} />;
  }
  return <div />;
};
