import * as React from 'react';
import { IPersonaProps } from '@fluentui/react/lib/Persona';
import { IBasePickerSuggestionsProps, NormalPeoplePicker } from '@fluentui/react/lib/Pickers';
import { useField } from 'formik';
import { Label } from '@fluentui/react';
import { map } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { GraphObjectTypes, IGraphObjectInfo, IQueryToSearchGraphObjectsInfo } from '../../../shared/models/graph';
import { getPrimaryFTEContactsListUrl, httpPost } from '../../../shared/services';
import { IUser } from '../../../shared/models/Environment/IEnvironmentAttestationRequest';

function convertGraphObjectToPersona(objectList: IGraphObjectInfo[]): IPersonaProps[] {
  if (!objectList) {
    return [];
  }

  // eslint-disable-next-line no-param-reassign
  objectList = Array.from(objectList);
  return objectList.map((graphObject) => {
    // Custom Props used to store additional info in Persona object
    const customProps = {
      id: graphObject?.id,
      accountType: null, // passing null since Graph is not returning Graph object
      displayName: graphObject?.displayName,
      emailAddress: graphObject?.mail,
      userPrincipalName: graphObject?.userPrincipalName
    };
    return {
      key: graphObject.mail,
      text: graphObject.mail,
      secondaryText: graphObject.mail,
      ...customProps
    } as IPersonaProps;
  });
}
const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Suggested FTE Contacts',
  mostRecentlyUsedHeaderText: 'Suggested FTE Contacts',
  noResultsFoundText: 'No FTE contacts results found',
  loadingText: 'Loading',
  showRemoveButtons: false,
  suggestionsAvailableAlertText: 'People Picker Suggestions available',
  suggestionsContainerAriaLabel: 'Suggested contacts'
};

export function FormPeoplePickerV1({ ...props }): React.ReactNode {
  const [field, meta, helpers] = useField(props.name!);
  const picker = React.useRef(null);

  const initialValue = props.value;

  const initialItems = [] as IPersonaProps[];
  if (initialValue) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    initialValue?.forEach((element: { emailAddress: string }) => {
      initialItems.push({
        key: element.emailAddress,
        text: element.emailAddress,
        secondaryText: element.emailAddress
      } as IPersonaProps);
    });
  }

  const convertPersonasToIUser = (currentPersonas: IPersonaProps[] | undefined) => {
    const IUserList = [] as IUser[];
    currentPersonas?.forEach((item) => {
      IUserList.push({
        id: item?.id ? item?.id : null,
        accountType: item?.accountType ? item?.accountType : null,
        displayName: item?.displayName ? item?.displayName : null,
        emailAddress: item?.text,
        userPrincipalName: item?.userPrincipalName ? item?.userPrincipalName : null
      } as IUser);
    });
    return IUserList;
  };

  let peopleList = convertGraphObjectToPersona(props.suggestionItemList!);

  const onFilterChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    const query: IQueryToSearchGraphObjectsInfo = {
      objectType: GraphObjectTypes.User,
      keyWords: filterText,
      skipToken: null
    };
    const callBack = (res: AjaxResponse) => {
      if (filterText && filterText.length > 4) {
        const limitResults = props.limitResults;
        let filteredPersonas: IPersonaProps[] = convertGraphObjectToPersona(
          res.response.graphObjectsInfo as IGraphObjectInfo[]
        );
        filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas!);
        filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
        return filteredPersonas;
      }
      return [];
    };
    return httpPost(getPrimaryFTEContactsListUrl(), query).pipe(map(callBack)).toPromise();
  };

  const onRemoveSuggestion = (item: IPersonaProps): void => {
    const indexPeopleList: number = peopleList.indexOf(item);
    if (indexPeopleList >= 0) {
      const newPeople: IPersonaProps[] = peopleList
        .slice(0, indexPeopleList)
        .concat(peopleList.slice(indexPeopleList + 1));
      peopleList = newPeople;
    }
  };

  return (
    <div>
      {props.label && <Label required={props.required}>{props.label}</Label>}
      <NormalPeoplePicker
        {...field}
        {...props}
        defaultSelectedItems={initialItems}
        onResolveSuggestions={onFilterChanged}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={suggestionProps}
        key={'normal'}
        // eslint-disable-next-line react/jsx-no-bind
        onRemoveSuggestion={onRemoveSuggestion}
        styles={{
          text: {
            borderColor: 'rgb(100, 100, 100)'
          }
        }}
        removeButtonAriaLabel={'Remove'}
        inputProps={{
          'aria-label': props.label,
          'aria-disabled': "false",
          'aria-readonly': "false",
          'aria-required': props.required
        }}
        componentRef={picker}
        resolveDelay={200}
        onChange={(options) => {
          helpers.setValue(convertPersonasToIUser(options));
          helpers.setTouched(true, false); // make error show up, but dont double trigger validation
        }}
      />
      {meta.touched && meta.error ? (
        <div
          className="error"
          style={{
            fontSize: '12px',
            fontWeight: 400,
            color: 'rgb(168, 0, 0)',
            margin: '0px,0px,0px,0px',
            paddingTop: '5px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {meta.error}
        </div>
      ) : null}
    </div>
  );
}

function removeDuplicates(personas: IPersonaProps[], possibleDupes: IPersonaProps[]) {
  return personas.filter((persona) => !listContainsPersona(persona, possibleDupes));
}

function listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
  if (!personas || !personas.length || personas.length === 0) {
    return false;
  }
  return personas.filter((item) => item.secondaryText === persona.secondaryText).length > 0;
}

function getTextFromItem(persona: IPersonaProps): string {
  return persona.text as string;
}
