import { createAction } from 'typesafe-actions';
import { IEnvironment } from '../../../models/Environment/IEnvironmentRequest';
import { IHttpError } from '../../../models/IHttpError';
import {
  SUBMIT_ENVIRONMENT_REQUEST,
  SUBMIT_ENVIRONMENT_REQUEST_SUCCESS,
  SUBMIT_ENVIRONMENT_REQUEST_FAIL
} from '../../constants';

export const submitEnvironmentRequest = createAction(
  SUBMIT_ENVIRONMENT_REQUEST,
  (IEnvironment: IEnvironment) => IEnvironment
)<IEnvironment>();

export const submitEnvironmentRequestSuccess = createAction(
  SUBMIT_ENVIRONMENT_REQUEST_SUCCESS,
  (IEnvironment: IEnvironment) => IEnvironment
)<IEnvironment>();

export const submitEnvironmentRequestFail = createAction(
  SUBMIT_ENVIRONMENT_REQUEST_FAIL,
  (err: IHttpError) => err
)<IHttpError>();
