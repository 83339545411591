/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { mergeStyles, TextField } from '@fluentui/react';
import { useField } from 'formik';
import React from 'react';

export function FormTextField({ ...props }): React.ReactNode {
  const [field, meta, helpers] = useField(props.name!);
  return (
    <TextField
      styles={{
        field: mergeStyles({
          color: 'rgb(0,0,0,0.75)'
        })
      }}
      aria-required={props.ariaRequired}
      autoComplete='on'
      label={props.label}
      {...field}
      {...props}
      onChange={(_event, newValue) => {
        helpers.setValue(newValue);
        if (props.onChange) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          props.onChange(_event, newValue);
        }
      }}
      errorMessage={meta.touched && meta.error ? meta.error : undefined} // allow for prop 
      /* errorMessage is modified to maintain consistency with other form components */
      // errorMessage={
      //   meta.touched && meta.error ? (
      //     <div role="alert">
      //       {meta.error}
      //     </div>
      //   ) : undefined
      // }
    />
  );
}
