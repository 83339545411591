import React from 'react';
import { Field } from 'formik';
import { css, Checkbox } from '@fluentui/react';
import { ENVIRONMENT_DATA_CLASSIFICATION_OPTIONS } from '../EnvironmentRequestFormConstants';
import { EnvironmentRequestComplianceInformationFormStyle } from './EnvironmentRequestForm.styles';
import { EnvironmentRequestFormProps } from '../EnvironmentRequestForm.types';
import { ServiceTreeSearchType } from '../../Items/FormServiceTreePicker';
import { ButtonIconWithTooltip } from '../../../InfoIcon/ButtonIconWithTooltip';
import { FormFieldWithTooltip } from '../../FormFieldWithTooltip';

export const EnvironmentRequestComplianceInformationForm = (props: EnvironmentRequestFormProps) => {
  const {
    formField: { dataClassification, hipaa, gdpr, tentedInformation, soxApplication, serviceTree },
    acceptTerm,
    handleAcceptTermChange
  } = props;

  const declarationLabelContent = (
    <div style={{ display: 'inline-block', paddingLeft: '4px' }}>
      I have read and agreed to the{' '}
      <a
        target={'_blank'}
        rel={'noreferrer noopener'}
        href={
          'https://microsoft.sharepoint.com/sites/Microsoft365Builders/SitePages/Power%20Platform%20Gov/PowerPlatform-Governance.aspx#understanding-our-tenant'
        }
      >
        responsibilities outlined
      </a>{' '}
      for managing assets in the Microsoft tenant and the{' '}
      <a target={'_blank'} rel={'noreferrer noopener'} href={'https://privacy.microsoft.com/en-US/data-privacy-notice'}>
        Microsoft Global Data Privacy Notice
      </a>{' '}
    </div>
  );

  const renderDeclarationLabel = () => declarationLabelContent;

  return (
    <>
      <div
        className={css(
          'Environment-Request-Compliance-Information-Form-Field',
          EnvironmentRequestComplianceInformationFormStyle.wrapper
        )}
      >
        <div className={css('Requester-Form-Field', EnvironmentRequestComplianceInformationFormStyle.formColMedium)}>
          <FormFieldWithTooltip
            as={dataClassification.component}
            label={dataClassification.label}
            aria-label={dataClassification.label}
            aria-labelledby={dataClassification.label}
            ariaRequired={dataClassification.required}
            name={dataClassification.name}
            required={dataClassification.required}
            options={ENVIRONMENT_DATA_CLASSIFICATION_OPTIONS}
            tooltip={dataClassification.tooltip || ''}
          />
        </div>
        <div className={css(EnvironmentRequestComplianceInformationFormStyle.formColHeader)}>
          <div id="checkbox-group" className={css(EnvironmentRequestComplianceInformationFormStyle.formColCheckbox)}> Regulatory Scope (select all that apply)</div>
          <div role="group" aria-labelledby="checkbox-group">

            <div className={css('Requester-Form-Field', EnvironmentRequestComplianceInformationFormStyle.formColSmall,
              EnvironmentRequestComplianceInformationFormStyle.formColCheckbox)}>
              <Field as={hipaa.component} label={hipaa.label} name={hipaa.name} required={hipaa.required} />
            </div>
            <div className={css('Requester-Form-Field', EnvironmentRequestComplianceInformationFormStyle.formColSmall,
              EnvironmentRequestComplianceInformationFormStyle.formColCheckbox)}>
              <Field as={gdpr.component} label={gdpr.label} name={gdpr.name} required={gdpr.required} />
              <div
                style={{
                  position: 'absolute',
                  top: '0px',
                  left: '170px'
                }}
              >
                <ButtonIconWithTooltip tooltipContent={gdpr.tooltip || ''} />
              </div>
            </div>
            <div className={css('Requester-Form-Field', EnvironmentRequestComplianceInformationFormStyle.formColSmall,
              EnvironmentRequestComplianceInformationFormStyle.formColCheckbox)}>
              <Field
                as={tentedInformation.component}
                label={tentedInformation.label}
                name={tentedInformation.name}
                required={tentedInformation.required}
              />
            </div>
            <div className={css('Requester-Form-Field', EnvironmentRequestComplianceInformationFormStyle.formColSmall,
              EnvironmentRequestComplianceInformationFormStyle.formColCheckbox)}>
              <Field
                as={soxApplication.component}
                label={soxApplication.label}
                name={soxApplication.name}
                required={soxApplication.required}
              />
            </div>
          </div>
        </div>
        <div className={css('Requester-Form-Field', EnvironmentRequestComplianceInformationFormStyle.formColSmall)}>
          <FormFieldWithTooltip
            as={serviceTree.component}
            label={serviceTree.label}
            aria-label={serviceTree.label}
            aria-labelledby={serviceTree.label}
            name={serviceTree.name}
            required={serviceTree.required}
            ariaRequired={serviceTree.required}
            limitResults={5}
            searchType={ServiceTreeSearchType.Service}
            suggestionsHeader="Suggested Service Tree Services"
            tooltip={serviceTree.tooltip || ''}
          />
        </div>
        <br />
        <br />
        <br />
        <div className={css('Requester-Form-Field', EnvironmentRequestComplianceInformationFormStyle.formColSmall)}>
          <div style={{ display: 'inline-block' }}>
            <Checkbox
              name="acceptedTerms"
              ariaLabel={
                'Checkbox for I have read and agreed to the responsibilities outlined for managing assets in the Microsoft tenant and the Microsoft Global Data Privacy Notice.The terms and conditions must be accepted.'
              }
              onRenderLabel={renderDeclarationLabel}
              checked={acceptTerm}
              onChange={handleAcceptTermChange}
              required = {true}
            />
          </div>
          {!acceptTerm ? (
            <div
              className="error"
              style={{
                fontSize: '12px',
                fontWeight: 400,
                color: 'rgb(168, 0, 0)',
                margin: '0px,0px,0px,5px',
                paddingTop: '5px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {'The terms and conditions must be accepted.'}
            </div>
          ) : null}
        </div>
        <div>
          After submitting, an approval request will be sent to the first FTE manager in your management chain via Teams
          and Outlook.
        </div>
      </div>
    </>
  );
};
