import { combineEpics } from 'redux-observable';
import GetListMailboxSSSRequestsEpic from './mailboxSSS/GetListMailboxSSSRequestsEpic';
import EnsureSystemAdminEpic from './mailboxSSS/EnsureSystemAdminEpic';
import UserEmailApprovalEpic from './mailboxSSS/UserEmailApprovalEpic';
import DeleteRoleAssignmentRequestEpic from './oneAccessRevoke/DeleteRoleAssignmentRequestEpic';
import SubmitEnvironmentRequestEpic from './environment/SubmitEnvironmentRequestEpic';
import GetEnvironmentsEpic from './environment/GetEnvironmentsEpic';
import GetListMyRequestStatusEpic from './myRequestStatus/GetListMyRequestStatusEpic';
import GetRequestStatusMailSSSEpic from './myRequestStatus/GetRequestStatusMailSSSEpic';
import GetRequestStatusEnvironmentEpic from './myRequestStatus/GetRequestStatusEnvironmentEpic';
import GetServiceTreeObjectEpic from './serviceTree/GetServiceTreeObjectEpic';
import GetListMyAppsEpic from './apps/GetListMyAppsEpic';
import GetPrimaryFTEContactsEpic from './primaryFTEContacts/GetPrimaryFTEContactsEpic';
import GetFeatureFlagsEpic from './featureFlags/GetFeatureFlagsEpic';
import GetAppMetadataEpic from './apps/GetAppMetadataEpic';
import SetAppMetadataEpic from './apps/SetAppMetadataEpic';
import GetEnvironmentDetailEpic from './environment/GetEnvironmentDetailEpic';
import SetEnvironmentAttestationEpic from './environment/SetEnvironmentAttestationEpic';
import GetPoliciesEpic from './policies/GetPoliciesEpic';
import SubmitMailboxSSSRequestEpic from './mailboxSSS/SubmitMailboxSSSRequestEpic';
import SubmitEnvironmentRescanEpic from './environment/SubmitEnvironmentRescanEpic';
import GetSharePointSitesEpic from './sharePointSites/GetSharePointSitesEpic';
import GetSharePointPoliciesEpic from './policies/GetSharePointPoliciesEpic';
import GetSharePointDetailEpic from './sharePointSites/GetSharePointDetailEpic';

const epics = combineEpics(
  ...GetListMailboxSSSRequestsEpic,
  ...EnsureSystemAdminEpic,
  ...UserEmailApprovalEpic,
  ...DeleteRoleAssignmentRequestEpic,
  ...SubmitEnvironmentRequestEpic,
  ...GetListMyRequestStatusEpic,
  ...GetRequestStatusMailSSSEpic,
  ...GetRequestStatusEnvironmentEpic,
  ...GetServiceTreeObjectEpic,
  ...GetListMyAppsEpic,
  ...GetPrimaryFTEContactsEpic,
  ...GetFeatureFlagsEpic,
  ...SetAppMetadataEpic,
  ...GetAppMetadataEpic,
  ...GetEnvironmentsEpic,
  ...GetEnvironmentDetailEpic,
  ...SetEnvironmentAttestationEpic,
  ...GetPoliciesEpic,
  ...SubmitMailboxSSSRequestEpic,
  ...SubmitEnvironmentRescanEpic,
  ...GetSharePointSitesEpic,
  ...GetSharePointPoliciesEpic,
  ...GetSharePointDetailEpic
);

export default epics;
