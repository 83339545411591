export enum ProgressStepperNodeTypes {
  Empty = 0,
  InProgress,
  Active,
  Complete,
  Error
}

export interface ProgressStepperNodeState {
  description: string;
  subDescription?: string;
  nodeType: ProgressStepperNodeTypes;
}

export interface ProgressStepperProps {
  lstSteps: ProgressStepperNodeState[];
  currentStepNumber: number;
  enableSubDescription?: boolean;
  onStepClicked?: (index: number) => void;
  isVertical?: boolean;
}

export interface ProgressStepperState {
  steps: ProgressStepperNodeState[];
}
