import { createAction } from 'typesafe-actions';
import { IHttpError } from '../../../models/IHttpError';
import { GET_SHAREPOINT_DETAIL, GET_SHAREPOINT_DETAIL_FAIL, GET_SHAREPOINT_DETAIL_SUCCESS } from '../../constants';
import { ISharePointDetail } from '../../../models/SharepointSites/ISharePointDetailRequests';

export const getSharePointDetail = createAction(
  GET_SHAREPOINT_DETAIL,
  (sharePointId: string) => sharePointId
)<string>();
export const getSharePointDetailFailed = createAction(
  GET_SHAREPOINT_DETAIL_FAIL,
  (err: IHttpError) => err
)<IHttpError>();
export const getSharePointDetailSucceeded = createAction(
  GET_SHAREPOINT_DETAIL_SUCCESS,
  (data: ISharePointDetail) => data
)<ISharePointDetail>();
