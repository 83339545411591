import { FormikErrors } from 'formik';
import { IEnvironmentAttestation } from '../../../shared/models/Environment/IEnvironmentAttestationRequest';
import { ATTESTATION } from './EnvironmentAttestation.constant';
import { ISectionError } from './EnvironmentAttestation.model';

/**
 * To detect the error section wise to show the error
 * @param errors
 * @returns
 * NOTE: When redesigning this function, it is crucial to keep in mind that it should never be made needlessly complex and any use of loops should be avoided at all costs.
 */
export const detectErrorSectionWise = (errors: FormikErrors<IEnvironmentAttestation>) => {
  const error: ISectionError = {
    message: 'Missing values in section : ',
    isComplianceError: false,
    isInformationError: false,
    isOwnershipError: false,
    isAttestationConfirmationError: false,
    section: []
  };
  if (errors.description || errors.justification || errors.audience) {
    error.isInformationError = true;
    error.section.push(ATTESTATION.SECTION.INFORMATION);
  }
  if (errors.owningTeam) {
    error.isOwnershipError = true;
    error.section.push(ATTESTATION.SECTION.OWNERSHIP);
  }
  if (errors.dataClassification) {
    error.isComplianceError = true;
    error.section.push(ATTESTATION.SECTION.COMPLIANCE);
  }
  if (errors.isAttestationConfirmed) {
    error.isAttestationConfirmationError = true;
  }
  error.message = error.message + '' + error.section.join(', ');
  return error;
};
