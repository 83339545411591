import { ILoading } from '../ILoading';
import { IExecutedPolicies } from './IExecutedPolices';

export interface ISharePointDetail {
  id: string;
  displayName: string | undefined;
  lastCrawlDate: string;
  violationCount: number;
  created: string;
  executedPolicies: IExecutedPolicies[];
  quarantineDate: string;
  complianceState: string;
  assetType: string;
  deleteDate : string;
  deletedOn : string;
  restoreUntilDate: string;
  retentionPeriod: string;
  description: string;
  assetUri: string;
  dueDate: string;
}

export interface ISharePointDetailRequest extends ILoading {
  data: ISharePointDetail;
}

export interface IUser {
  id: string | null;
  displayName: string | null;
  emailAddress: string;
  userPrincipalName: string | null;
  accountType: string | null;
}

export const initialSharePointDetail: ISharePointDetail = {
  id: '',
  displayName: '',
  lastCrawlDate: '',
  violationCount: 0,
  created: '',
  executedPolicies: [],
  quarantineDate: '',
  complianceState: '',
  assetType: '',
  deleteDate : '',
  deletedOn : '',
  restoreUntilDate: '',
  retentionPeriod: '',
  description: '',
  assetUri:'',
  dueDate:''
};
