import { createReducer } from 'typesafe-actions';
import { IEventTelemetry, IExceptionTelemetry, SeverityLevel } from '@microsoft/applicationinsights-common';
import {
  setEnvironmentAttestation,
  setEnvironmentAttestationFailed,
  setEnvironmentAttestationSucceeded
} from '../../actions/environment/environmentAttestation';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { EVENTS, API_NAMES, API_STATUS } from '../../../constants';
import { telemetryConfig } from '../../../../components/TelemetryService/TelemetryConfig';

TelemetryService.initialize(telemetryConfig);

export const setEnvironmentAttestationReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(setEnvironmentAttestation, (state) => {
    return {
      // initial state for the request
      ...state,
      setEnvironmentAttestationRequestState: {
        ...state.setEnvironmentAttestationRequestState,
        isLoading: true,
        loadingState: LoadingState.loading
      }
    };
  })
  .handleAction(setEnvironmentAttestationSucceeded, (state, data) => {
    TelemetryService.trackEvent({ name: EVENTS.API_CALL } as IEventTelemetry, {
      API: API_NAMES.SET_ENVIRONMENT_ATTESTATION,
      Result: API_STATUS.SUCCESS,
      ActionType: data.payload.data?.actionType
    });

    return {
      ...state,
      setEnvironmentAttestationRequestState: {
        // Override this object in the state spread
        ...state.setEnvironmentAttestationRequestState,
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(setEnvironmentAttestationFailed, (state, action) => {
    TelemetryService.trackException({
      exception: { message: API_STATUS.FAIL, name: API_NAMES.SET_ENVIRONMENT_ATTESTATION } as Error,
      properties: { API: API_NAMES.SET_ENVIRONMENT_ATTESTATION, Payload: action.payload },
      severityLevel: SeverityLevel.Error
    } as IExceptionTelemetry);
    return {
      ...state,
      setEnvironmentAttestationRequestState: {
        ...state.setEnvironmentAttestationRequestState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    };
  });
