import { IColumn } from '@fluentui/react';

export const sharePointPolicyColumns: IColumn[] = [
  {
    key: 'policy',
    name: 'Policy',
    fieldName: 'policy',
    minWidth: 150,
    maxWidth: 150
  },
  {
    key: 'desc',
    name: 'Description',
    fieldName: 'desc',
    minWidth: 600,
    maxWidth: 600
  },
  {
    key: 'info',
    name: 'Information',
    fieldName: 'info',
    minWidth: 120,
    maxWidth: 120
  },
  {
    key: 'action',
    name: '',
    minWidth: 130,
    maxWidth: 130
  }
];
