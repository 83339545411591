import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IRootState } from '../../../shared/store/IRootState';
import { RequestStatusEnvironmentMappedProps, RequestStatusEnvironmentActions } from './RequestStatusEnvironment.types';
import { RequestStatusEnvironment } from './RequestStatusEnvironment';
import { getRequestStatusEnvironment, getRequestStatusMailSSS } from '../../../shared/store/actions';

// Reducer States
const mapStateToProps = (
  state: IRootState
  // _ownProps: ConnectedRequestStatusEnvironmentProps
): RequestStatusEnvironmentMappedProps => {
  const { requestStatusEnvironmentState } = state;
  return {
    requestStatusEnvironmentState: requestStatusEnvironmentState
  };
};

// Actions
const mapDispatchToProps = (): RequestStatusEnvironmentActions => {
  return {
    getRequestStatusEnvironment: (requestId: string) => getRequestStatusEnvironment(requestId),
    getRequestStatusMailSSS: (requestId: string) => getRequestStatusMailSSS(requestId)
  };
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export const ConnectedRequestStatusEnvironment = withRouter(
  connect(mapStateToProps, mapDispatchToProps())(RequestStatusEnvironment)
);
