import { css } from '@fluentui/react';
import React from 'react';
import { pageHeaderStyles } from './PageHeader.styles';
import { IPageHeaderProps } from './PageHeader.types';

export const PageHeader: React.FC<IPageHeaderProps> = (props) => {
  const { pageTitle } = props;
  const pageFocusRef = React.createRef<HTMLDivElement>();

  return (
    <div className={css('ms-ListPageHeader', pageHeaderStyles.pageHeader)}>
      <div
        role="heading"
        aria-level={1}
        tabIndex={-1}
        ref={pageFocusRef}
        className={css('ms-PageTitle', pageHeaderStyles.header)}
      >
        {pageTitle}
      </div>
    </div>
  );
};
