import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import * as API from '../services/Api';
import * as actions from './actions';
import epics from './epics';
import { IRootState } from './IRootState';
import reducers from './reducers';
import { RootActions } from './RootActions';

export type RootStateType = IRootState;

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose;
  }
}

const epicMiddleware = createEpicMiddleware<RootActions, RootActions, IRootState>({ dependencies: API });
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create store
function configureStore(initialState?: RootStateType) {
  // configure middlewares
  const middlewares = [epicMiddleware];
  // compose enhancers
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  // create store
  return createStore(reducers, initialState, enhancer);
}

const store = configureStore();

epicMiddleware.run(epics);

export { store, actions };
