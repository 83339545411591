import { ICommandBarStyles, IStyle, mergeStyleSets } from '@fluentui/react';

export const ListMyRequestStatusPageStyle = mergeStyleSets({
  pageBackground: {
    selectors: {
      ':global(.ms-DetailsList)': {
        overflow: 'visible'
      }
    }
  } as IStyle
});

export const ListMyRequestStatusPageCommandBarStyle = {
  // root: { backgroundColor: CoherenceTheme.palette.neutralPrimaryAlt }
} as ICommandBarStyles;

export const ListMyRequestStatusPageContentStyles = mergeStyleSets({
  filter: {
    marginLeft: '3%',
    width: '94%',
    marginBottom: '5px'
  },
  table: {
    height: '79vh',
    marginLeft: '3%',
    width: '97%',
    overflowX: 'scroll'
  }
});
