import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { getEnvironmentDetail, getEnvironmentDetailFailed, getEnvironmentDetailSucceeded } from '../../actions';
import { IRootState } from '../../IRootState';
import { RootActions } from '../../RootActions';
import { getEnvironmentAttestationUrl, httpGetV1 } from '../../../services';
import { AUTH_SCOPE } from '../../../constants';
import { IEnvironmentDetail } from '../../../models/Environment/IEnvironmentDetailRequest';

export const GetEnvironmentDetailEpic: Epic<RootActions, RootActions, IRootState> = (action$, _store) =>
  action$.pipe(
    filter(isActionOf(getEnvironmentDetail)),
    mergeMap((_action$) => {
      const request = {
        url: getEnvironmentAttestationUrl(_action$.payload),
        scope: AUTH_SCOPE.BUILDERS_HUB
      };
      return from(
        httpGetV1(request)
          .pipe(
            map((res) => {
              return res.response as IEnvironmentDetail;
            })
          )
          .pipe(
            mergeMap((res) => {
              return of(getEnvironmentDetailSucceeded(res));
            }),
            catchError((error) => {
              return of(getEnvironmentDetailFailed(error));
            })
          )
      );
    })
  );

export default [GetEnvironmentDetailEpic];
