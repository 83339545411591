import { ILoading } from '../ILoading';

export interface IEnvironmentAttestation {
  id: string;
  createdTime: Date | null;
  environmentName: string;
  purpose?: string | number;
  description: string;
  justification: string;
  audience: string;
  owners: IUser[];
  complianceState: string;
  owningTeam: string;
  businessSponsor: IUser[];
  dataClassification: string | number;
  isHIPAA: boolean;
  isGDPR: boolean;
  isTented: boolean;
  isSOX: boolean;
  serviceTreeId: string;
  modifiedTime: Date | null;
  modifiedBy: IUser;
  isAttestationConfirmed: boolean;
  lastAttestedBy: IUser;
  lastAttestionDate: Date | null;
  actionType: string;
  assetType: string;
}

export interface IEnvironmentAttestationRequest extends ILoading {
  data: IEnvironmentAttestation;
}

export interface ISetEnvironmentAttestationRequest extends ILoading {}

export interface IUser {
  id: string | null;
  displayName: string | null;
  emailAddress: string;
  userPrincipalName: string | null;
  accountType: string | null;
}

export const initialEnvironmentAttestation: IEnvironmentAttestation = {
  id: '',
  createdTime: null,
  environmentName: '',
  purpose: 0,
  description: '',
  justification: '',
  audience: '',
  owners: [],
  owningTeam: '',
  businessSponsor: [],
  dataClassification: 0,
  isHIPAA: false,
  complianceState: '',
  isGDPR: false,
  isTented: false,
  isSOX: false,
  serviceTreeId: '',
  modifiedTime: null,
  modifiedBy: {} as IUser,
  isAttestationConfirmed: false,
  lastAttestedBy: {} as IUser,
  lastAttestionDate: null,
  actionType: '',
  assetType:''
};
