import { createAction } from 'typesafe-actions';
import { IHttpError } from '../../../models/IHttpError';
import { IMailboxSSSRequest } from '../../../models/MailboxSSS/IMailboxSSSRequest';
import { ISystemUser } from '../../../models/MailboxSSS/ISystemUser';
import { ENSURE_SYSTEM_ADMIN, ENSURE_SYSTEM_ADMIN_SUCCESS, ENSURE_SYSTEM_ADMIN_FAIL } from '../../constants';

export const ensureSystemAdmin = createAction(
  ENSURE_SYSTEM_ADMIN,
  (curRequest: number, request: IMailboxSSSRequest) => ({
    curRequest,
    request
  })
)<{ curRequest: number; request: IMailboxSSSRequest }>();

export const ensureSystemAdminSuccess = createAction(
  ENSURE_SYSTEM_ADMIN_SUCCESS,
  (systemUser: ISystemUser) => systemUser
)<ISystemUser>();

export const ensureSystemAdminFail = createAction(ENSURE_SYSTEM_ADMIN_FAIL, (err: IHttpError) => err)<IHttpError>();
