/* eslint-disable react/require-default-props */
import { css, Spinner, SpinnerSize } from '@fluentui/react';
import React from 'react';

import { LoadingStyles } from './Loading.styles';

interface ILoadingProps {
  isSmallWindow?: boolean;
  label?: string;
}

export default class Loading extends React.Component<ILoadingProps, {}> {
  public render(): JSX.Element {
    const { isSmallWindow, label } = this.props;
    return (
      <Spinner
        className={
          isSmallWindow
            ? css(LoadingStyles.loadingIcon, LoadingStyles.loadingSmallWindow)
            : css(LoadingStyles.loadingIcon)
        }
        size={isSmallWindow ? SpinnerSize.small : SpinnerSize.large}
        label={label || 'Loading ...'}
        ariaLive="assertive"
        labelPosition="right"
      />
    );
  }
}
