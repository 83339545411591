const PAGINATION = {
  PAGE_SIZE_LIST: [
    { key: 10, text: '10' },
    { key: 20, text: '20' },
    { key: 30, text: '30' },
    { key: 40, text: '40' },
    { key: 50, text: '50' }
  ],
  ARIA_LABEL: 'display items per page'
};

const SORT_DIR = {
  ASC: 'SortUp',
  DESC: 'SortDown',
  DEFAULT: 'sort'
};

export const SORT_TYPE = {
  ASC : 'asc',
  DESC : 'desc'
}

export const GRID = {
  PAGINATION,
  SORT_DIR
};
