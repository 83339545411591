/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable radix */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ScrollablePane,
  css,
  ShimmeredDetailsList,
  IColumn,
  SelectionMode,
  MessageBar,
  MessageBarType,
  Stack,
  DefaultButton
} from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { IEventTelemetry } from '@microsoft/applicationinsights-common';
import { CoherencePageSize, CoherencePagination, ICoherencePageSizeProps, ICoherencePaginationProps } from '@coherence-design-system/controls';
import { _Styles } from '../../../shared/styles/Page.styles';
import { EVENTS, GRID, SCREEN_SIZE, SORT_TYPE } from '../../../shared/constants';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { SharePointSites, iconStyles, messageBarStyles } from './SharePointSites.styles';
import { getSharePointSites } from '../../../shared/store/actions';
import { formatSharePointSitesUtil, getActionUrl, isCompliant } from './SharePointSites.helper';
import { ISharePointSitesList } from '../../../shared/models/SharepointSites/ISharePointSitesList';
import { IRootState } from '../../../shared/store/IRootState';
import { TelemetryService } from '../../../components/TelemetryService/TelemetryService';
import { getCachedUser } from '../../../msalConfig';
import { telemetryConfig } from '../../../components/TelemetryService/TelemetryConfig';
import { LoadingState } from '../../../shared/models/ILoading';
import { ErrorBanner } from '../../../components/ErrorBanner';
import { SPS_API_STATE, SPS_CONST } from './SharePointSites.constants';
import { arrayUtils } from '../../../shared/utils';

export function SharepointSites(props: { isNavCollapsed: any; }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [sharePointSitesList, setSharePointSitesList] = useState<ISharePointSitesList[]>([]);
  const getSharePointSitesState = useSelector((state: IRootState) => state.getSharePointSitesState);
  const [totalItemsPerPage, setTotalItemsPerPage] = useState(10);
  const [totalSharePointSitesCount, setTotalSharePointSitesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const formatSharePointSites = useCallback(formatSharePointSitesUtil, []);
  const [sort, setSort] = useState({ sortColumn: '', sortIcon: '', sortType: '' });
  TelemetryService.initialize(telemetryConfig);
  // Prop sets for coherence pagination controls
  const paginationPageSizeProps: ICoherencePageSizeProps = {
    pageSize: totalItemsPerPage,
    pageSizeList: GRID.PAGINATION.PAGE_SIZE_LIST,
    comboBoxAriaLabel: GRID.PAGINATION.ARIA_LABEL,
    onPageSizeChange: (_totalItem) => {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      TelemetryService.trackEvent({ name: EVENTS.PAGE_SIZE_CHANGE } as IEventTelemetry, {
        pageSize: _totalItem,
        username: getCachedUser()?.name
      });
      setTotalItemsPerPage(parseInt(_totalItem.toString()));
    }
  };

  // Prop sets for coherence pagination controls
  const paginationProps: ICoherencePaginationProps = {
    pageCount: Math.ceil(totalSharePointSitesCount / totalItemsPerPage),
    selectedPage: currentPage,
    previousPageAriaLabel: 'previous page',
    nextPageAriaLabel: 'next page',
    inputFieldAriaLabel: 'page number',
    onPageChange: (
      startItemIndex: number,
      endItemIndex: number,
      selectedPage: number | undefined
    ) => {
      if (selectedPage !== undefined) {
        setCurrentPage(selectedPage);
      }
    }
  };

  const columnSortClicked = (_ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
    const newColumn: IColumn = column;
    if (newColumn.iconName === GRID.SORT_DIR.DEFAULT) {
      newColumn.iconName = GRID.SORT_DIR.ASC;
      newColumn.ariaLabel = 'Name column sorted in Ascending order'
    } else if (newColumn.iconName === GRID.SORT_DIR.ASC) {
      newColumn.iconName = GRID.SORT_DIR.DESC;
      newColumn.ariaLabel = 'Name column sorted in Descending order'
    } else {
      newColumn.iconName = GRID.SORT_DIR.DEFAULT;
      newColumn.ariaLabel = 'Name column sorted in Ascending/Descending order'
    }
    setSort({
      sortColumn: newColumn.key,
      sortIcon: newColumn.iconName,
      sortType: newColumn.iconName === GRID.SORT_DIR.ASC ? SORT_TYPE.ASC : SORT_TYPE.DESC
    });
    const columnName = column.key;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const newColumnsList: IColumn[] = arrayUtils.replace.itemByKey(COLUMNS, 'key', columnName, newColumn);
    setColumns(newColumnsList);
    TelemetryService.trackEvent({ name: EVENTS.SORTING_COLUMN } as IEventTelemetry, {
      sortedColumnName: column.key,
      username: getCachedUser()?.name
    });
  };

  const COLUMNS: IColumn[] = [
    {
      key: 'displayName',
      name: '  Name',
      fieldName: 'displayName',
      maxWidth: 350,
      minWidth: 350,
      iconName: GRID.SORT_DIR.DEFAULT,
      onColumnClick: columnSortClicked,
      isMultiline:true,
      ariaLabel: 'Share Point Site Name Column header sorted ascending/descending'
    },
    {
      key: 'created',
      name: 'Created Date',
      fieldName: 'created',
      maxWidth: 150,
      minWidth: 150
    },
    {
      key: 'violationCount',
      name: '  Status',
      fieldName: 'Status',
      maxWidth: 150,
      minWidth: 150
      // iconName: GRID.SORT_DIR.DEFAULT,
      // onColumnClick: columnSortClicked
      // Commenting sorting on Status temporarily.
    },
    {
      key: 'actionRequired',
      name: '  Action Required',
      fieldName: 'Action Required',
      maxWidth: 150,
      minWidth: 150
    },
    {
      key: 'deleteDate',
      name: 'Due Date',
      fieldName: 'deleteDate',
      maxWidth: 150,
      minWidth: 150
    }
  ];

  useEffect(() => {
    let urlParams = `$top=${totalItemsPerPage}&$skip=${(currentPage - 1) * totalItemsPerPage}&$count=true`;
    if (sort.sortIcon !== GRID.SORT_DIR.DEFAULT && sort.sortColumn) {
      urlParams += `&$orderby=${sort.sortColumn} ${sort.sortType}`;
    } else {
      urlParams += `&$orderby=displayName ${SORT_TYPE.DESC}`;
    }
    dispatch(getSharePointSites(urlParams));
  }, [dispatch, sort, totalItemsPerPage, currentPage]);
  const [columns, setColumns] = useState(COLUMNS);

  useEffect(() => {
    if (getSharePointSitesState && getSharePointSitesState.sharePointSitesData) {
      setTotalSharePointSitesCount(getSharePointSitesState.sharePointSitesData['@odata.count']);
      const list: ISharePointSitesList[] = formatSharePointSitesUtil(getSharePointSitesState.sharePointSitesData?.value);
      setSharePointSitesList(list);
    }
  }, [getSharePointSitesState, formatSharePointSites]);

  const onStatusClick = (id: string | undefined) => {
    TelemetryService.trackEvent({ name: EVENTS.LINK_CLICK } as IEventTelemetry, {
      linkName: 'Voilation Policies',
      id: id,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      username: getCachedUser()?.name
    });
  };

  const onRenderItemColumn = (item: ISharePointSitesList, index: number, column: IColumn) => {
    const fieldContent = item[column.fieldName as keyof ISharePointSitesList] as string;
    switch (column.key) {
      case 'violationCount':
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        return (
          <div title="NonCompliant" className={css(iconStyles.statusWrapper)}>
            <div className={css(iconStyles.circle, iconStyles[SPS_CONST.StatusStyle[item.complianceState]])} />
            <span>{SPS_CONST.ApiStatus[item.complianceState]}</span>
          </div>
        );
      case 'actionRequired':
        return !isCompliant(item.executedPolicies) ? (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <DefaultButton
            aria-label={`Press ${SPS_CONST.ActionLabel[item.complianceState]}`}
            className={css(SharePointSites.actionButton)}
            text={SPS_CONST.ActionLabel[item.complianceState]}
            onClick={() => {
              onStatusClick(item.id);
              const url = getActionUrl(item);
              history.push(url);
            }}
          />
        ) : (
          // TODO: Change the label on the button once a decision is made
          // <DefaultButton
          //   aria-label="Press to View Sharepoint"
          //   className={css(SharePointSites.actionButton)}
          //   text={SPS_CONST.ActionLabel.View}
          //   onClick={() => {
          //     onStatusClick(item.id);
          //     const url = getActionUrl(item);
          //     history.push(url);
          //   }}
          // />
          <div/>
        );
        case 'deleteDate':
          // TODO: Change this field to DeleteDate or something equivalent once discussion is done with Governance team
          return !isCompliant(item.executedPolicies) ? (
            <span>{fieldContent}</span>
          ) : (
            <div />
          );
      default:
        return <span>{fieldContent}</span>;
    }
  };

  if (getSharePointSitesState?.loadingState === LoadingState.error)
    return (
      <>
        <ScrollablePane
          className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}
        >
          <br />
          <ErrorBanner />
        </ScrollablePane>
      </>
    );

  return (
    <ScrollablePane
      className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}
      aria-label="Sharepoint Sites List"
      role="region"
    >
      <main data-automation-id="SharePoints" className={css(SharePointSites.pageBackground)} tabIndex={-1}>
        <div id='no-horizontal-scroll' className={css(SharePointSites.noHorizontalScroll)}>
        <PageHeader pageTitle={'SharePoint'} />
        <MessageBar messageBarType={MessageBarType.info} styles={messageBarStyles}>
          Note: The status update may take 24-48hrs after the submission.
        </MessageBar>
        <Stack>
          <div className={css(SharePointSites.listWrapper)}>
            <ShimmeredDetailsList
              columns={columns}
              enableShimmer={getSharePointSitesState.isLoading}
              ariaLabelForShimmer="Content is being fetched"
              ariaLabelForGrid="Share Point Sites details"
              items={sharePointSitesList || []}
              onRenderItemColumn={onRenderItemColumn}
              selectionMode={SelectionMode.none}
            />
          </div>
        </Stack>
        <Stack horizontalAlign="center" {...(window.innerWidth > SCREEN_SIZE.MEDIUM ? { horizontal: true } : {})}>
          <CoherencePagination {...paginationProps} />
          <CoherencePageSize {...paginationPageSizeProps} />
        </Stack>
        </div>
      </main>
    </ScrollablePane>
  );

}
