import { createReducer } from 'typesafe-actions';
import { IEventTelemetry, IExceptionTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { deleteRoleAssignment, deleteRoleAssignmentSuccess, deleteRoleAssignmentFail } from '../../actions';
import { toastSuccess, toastError } from '../../../utils/tools/toast';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { telemetryConfig } from '../../../../components/TelemetryService/TelemetryConfig';
import { API_NAMES, API_STATUS, EVENTS } from '../../../constants';

TelemetryService.initialize(telemetryConfig);

export const oneAccessRevokeReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(deleteRoleAssignment, (state) => {
    return {
      ...state,
      revokedRoleAssignment: {
        ...state.revokedRoleAssignment,
        isLoading: true,
        loadingState: LoadingState.loading
      }
    };
  })
  .handleAction(deleteRoleAssignmentSuccess, (state, action) => {
    toastSuccess('Successful deleted role assignment \n' + action.payload.id);
    TelemetryService.trackEvent({ name: EVENTS.API_CALL } as IEventTelemetry, {
      API: API_NAMES.DELETE_ROLE_ASSIGNMENT,
      Result: API_STATUS.SUCCESS
    });
    return {
      ...state,
      revokedRoleAssignment: {
        ...state.revokedRoleAssignment,
        revokedRoleAssignment: { ...action.payload },
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(deleteRoleAssignmentFail, (state, action) => {
    toastError('Fail to revoke role assignment', action.payload);
    TelemetryService.trackException({
      exception: { message: API_STATUS.FAIL, name: API_NAMES.DELETE_ROLE_ASSIGNMENT } as Error,
      properties: { API: API_NAMES.DELETE_ROLE_ASSIGNMENT, Payload: action.payload },
      severityLevel: SeverityLevel.Error
    } as IExceptionTelemetry);
    return {
      ...state,
      revokedRoleAssignment: {
        ...state.revokedRoleAssignment,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    };
  });
