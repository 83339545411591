import { ITelemetryConfig } from './TelemetryService.types';

const instrumentationKey = process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY
  ? process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY
  : 'fbe6715f-957a-4d5c-bf48-29bde7af4791';
const envName = process.env.REACT_APP_ENVIRONMENT_NAME ? process.env.REACT_APP_ENVIRONMENT_NAME : 'dev';

export const telemetryConfig = {
  EnvironmentName: envName,
  InstrumentationKey: instrumentationKey
} as ITelemetryConfig;
