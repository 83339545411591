/* eslint-disable @typescript-eslint/no-unsafe-call */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IRootState } from '../../shared/store/IRootState';
import { deleteRoleAssignment } from '../../shared/store/actions';
import { IDisplayItemRoleAssignment } from '../../shared/models/aadPAP/roleAssignment';
import { OneAccessRevoke } from './OneAccessRevoke';
import {
  IConnectedOneAccessRevokeProps,
  IOneAccessRevokeActions,
  IOneAccessRevokeMappedProps
} from './OneAccessRevoke.types';

const mapStateToProps = (state: IRootState, _ownProps: IConnectedOneAccessRevokeProps): IOneAccessRevokeMappedProps => {
  const { revokedRoleAssignment } = state;
  return {
    revokedRoleAssignment: revokedRoleAssignment
  };
};

const mapDispatchToProps = (): IOneAccessRevokeActions => {
  return {
    deleteRoleAssignment: (RoleAssignment: IDisplayItemRoleAssignment) => deleteRoleAssignment(RoleAssignment)
  };
};

export const ConnectedOneAccessRevoke = withRouter(connect(mapStateToProps, mapDispatchToProps())(OneAccessRevoke));
