import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { ensureSystemAdmin, ensureSystemAdminFail, ensureSystemAdminSuccess } from '../../actions';
import { IRootState } from '../../IRootState';
import { RootActions } from '../../RootActions';
import { ensureSystemAdminUrl, httpPost } from '../../../services';
import { ISystemUser } from '../../../models/MailboxSSS/ISystemUser';

export const EnsureSystemAdminEpic: Epic<RootActions, RootActions, IRootState> = (action$, _store) => {
  return action$.pipe(
    filter(isActionOf(ensureSystemAdmin)),
    mergeMap((action$) => {
      const body = { request: action$.payload.request };
      return from(httpPost(ensureSystemAdminUrl(), body))
        .pipe(
          map((res) => {
            return res.response as ISystemUser;
          })
        )
        .pipe(
          mergeMap((result) => {
            return of(ensureSystemAdminSuccess(result));
          }),
          catchError((error) => {
            return of(ensureSystemAdminFail(error));
          })
        );
    })
  );
};

export default [EnsureSystemAdminEpic];
