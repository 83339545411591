import { IDocumentCardStyles, IIconStyles, IStackStyles, ITextStyles } from '@fluentui/react';

export const HelpCardStyle: IDocumentCardStyles = {
  root: {
    display: 'inline-block',
    marginTop: 20,
    marginRight: 10,
    marginBottom: 20,
    width: '100%',
    boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px',
    transition: 'all .3s ease-in-out',
    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.22) 0px 19px 43px',
      transform: 'translate3d(0px, -1px, 0px)'
    }
  }
};

export const IconStyle: IIconStyles = {
  root: {
    color: 'rgb(0, 120, 212)',
    marginRight: 10,
    fontSize: 18
  }
};

export const StackStyle: IStackStyles = {
  root: {
    marginBottom: 20
  }
}

export const TextStyle: ITextStyles = {
  root: {
    marginBottom: 20
  }
}
