import { IStyle, mergeStyles, mergeStyleSets } from '@fluentui/react';

export const modalStyle = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    overflow: 'hidden' // bugfix: modal gets taller while tooltip content is rendering, goes back to normal height once render is done
  },
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    width: '600px'
  }
});

export const AppsListStyle = mergeStyleSets({
  pageBackground: {
    selectors: {
      ':global(.ms-DetailsList)': {
        overflow: 'visible'
      }
    }
  } as IStyle
});

export const iconClass = mergeStyles({
  color: 'orange',
  fontSize: 25,
  height: 25,
  width: 25,
  margin: '10px 10px'
});

export const modalButtonClass = mergeStyles({
  margin: '5px 5px 5px 5px'
});

export const AppsListCommandBarStyle = {
  // noop
};

export const AppsListSearchBarStyle = {
  root: {
    marginTop: '10px',
    width: '220px',
    alignitems: 'center'
  }
};

export const AppsListPageContentStyles = mergeStyleSets({
  filter: {
    marginLeft: '3%',
    width: '94%',
    marginBottom: '5px'
  },
  table: {
    height: '77vh',
    marginLeft: '3%',
    width: '94%'
  }
});
