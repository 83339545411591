/* eslint-disable @typescript-eslint/no-explicit-any */
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { Observable } from 'rxjs/internal/Observable';

export const ajaxHttpGet = (url: string, token: string | null, responseType?: string): Observable<AjaxResponse> => {
  return ajax({
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token || ''}`
    },
    url,
    responseType: responseType || 'json'
  });
};

export const ajaxHttpGetV1 = (url: string, tokens: (string | null)[], responseType?: string): Observable<AjaxResponse> => {
  return ajax({
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokens[0] || ''}`,
      Gov_Authorization: tokens[1]
    },
    url,
    responseType: responseType || 'json'
  });
};

export const ajaxHttpPut = (url: string, body: any, token: string | null): Observable<AjaxResponse> => {
  return ajax({
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    url,
    body
  });
};

export const ajaxHttpPost = (
  url: string,
  body: any,
  token: string | null,
  responseType?: string
): Observable<AjaxResponse> => {
  return ajax({
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    url,
    body,
    responseType: responseType || 'json'
  });
};

export const ajaxHttpDelete = (url: string, token: string | null): Observable<AjaxResponse> => {
  return ajax({
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    url
  });
};
