import {
  FontSizes,
  IStyle,
  mergeStyleSets,
  IIconProps,
  IButtonStyles,
  getTheme,
  FontWeights,
  IMessageBarStyles
} from '@fluentui/react';

const theme = getTheme();
export const EnvironmentStyle = mergeStyleSets({
  pageBackground: {
    position: 'relative',
    height: '100%',
    selectors: {
      ':global(.ms-DetailsList)': {
        overflow: 'visible'
      }
    }
  } as IStyle,
  listWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '2%'
  } as IStyle,
  btnRemediate: {
    borderRadius: '5px',
    border: 'solid 1px #D2D0CE',
    color: '#353434'
  } as IStyle,
  actionButton: {
    width: '75%',
    fontSize: 'small'
  } as IStyle,
  noHorizontalScroll: {
    overflowX:'hidden'
  } as IStyle
});

export const modalStyle = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    overflow: 'hidden !important', // bugfix: modal gets taller while tooltip content is rendering, goes back to normal height once render is done
    borderRadius: '20px',
    height: '700px',
    width: '670px'
  },
  header: [
    theme.fonts.xxLarge,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 4px 24px',
      zIndex: 999,
      position: 'absolute',
      width: '98%',
      background: '#ffffff'
    }
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    width: '600px',
    marginTop: '60px'
  },
  heading: {
    alignContent: 'start',
    fontSize: FontSizes.size24,
    fontWeight: 600
  } as IStyle
});

export const cancelIcon: IIconProps = { iconName: 'Cancel' };

export const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '14px',
    marginRight: '2px',
    position: 'absolute',
    right: '24px',
    top: '0px'
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
};

export const iconStyles = mergeStyleSets({
  statusWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    lineHeight: '14px'
  },
  nonCompliantLabel: {
    color: '#0B0080',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  compliantLabel: {
    color: 'black',
    fontWeight: 600
  },
  circle: {
    width: '8px',
    height: '8px',
    borderRadius: '10px',
    marginRight: '4px',
    marginTop: '3px'
  },
  markCompliant: {
    background: '#008000'
  },
  markNonCompliant: {
    background: '#FFA500'
  },
  markQuarantined: {
    background: '#D13438'
  },
  markDeleted: {
    background: '#a7a7a7'
  }
});

export const buttonStyle = mergeStyleSets({
  box: {
    boxSizing: 'borderBox',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 12px',
    width: '111px',
    height: '32px',
    background: '#FFFFFF',
    border: '1px solid #0078D4',
    borderRadius: '5px',
    flex: 'none',
    order: '0',
    flexGrow: '0'
  },

  text: {
    width: '68px',
    height: '20px',
    fontFamily: 'Segoe UI',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '13px',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#0078D4',
    flex: 'none',
    order: '4',
    flexGrow: '0',
    paddingBottom: '2px'
  }
});

export const messageBarStyles: IMessageBarStyles = {
  root: {
    marginLeft: '25px',
    width: '90%'
  }
};
