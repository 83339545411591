import { MessageBarType } from '@fluentui/react';
import { IExecutedPolicies } from '../../../../shared/models/Environment/IExecutedPolices';
import { IHashPolicy } from '../../../../shared/models/policies/IPolicies';
import { VOILATION_FLAG_MSG, VOILATION_STATUS } from './EnvironmentVoilation.constant';
import { dateUtils } from '../../../../shared/utils';
import { IEnvironmentViolationPolicy } from './EnvironmentViolation.model';
import { ENV_API_STATE } from '../Environment.constants';

/**
 * To fetch the failed polices
 * later on we reform this with IPolicy.
 * @param ep
 * @returns
 */
export const fetchFailedExecutedPolicies = (
  ep: IExecutedPolicies[] = [],
  policies: IHashPolicy
): IEnvironmentViolationPolicy[] => {
  const failedEP: IEnvironmentViolationPolicy[] = [];
  for (let i = 0; i < ep.length; i += 1) {
    const policy: IExecutedPolicies = ep[i];
    if (policy.status === VOILATION_STATUS.FAIL && policy.policyId) {
      const policyData = policies[policy.policyId];
      const failedPolicy: IEnvironmentViolationPolicy = {
        desc: policyData.description,
        id: policy.policyId,
        info: policyData.referenceUri,
        policy: policyData.displayName
      };
      failedEP.push(failedPolicy);
    }
  }
  return failedEP;
};

/**
 * To get the due date
 * @param dueDate
 * @returns
 */
export const getDueDateString = (dueDate: string) => {
  let formattedDate = '  ' + dateUtils.formatDate(dueDate);
  const days = dateUtils.noOfDaysDates(dueDate, new Date().toString());
  formattedDate += days > 0 ? ` (${days} ${days > 1 ? 'days left' : 'day left'})` : '';
  return formattedDate;
};

export const selectClass = (status: string) => {
  switch (status) {
    case ENV_API_STATE.COMPLIANT:
      return MessageBarType.success;
    case ENV_API_STATE.DELETED:
      return MessageBarType.info;
    case ENV_API_STATE.IN_VIOLATION:
      return MessageBarType.warning;
    case ENV_API_STATE.QUARANTINED:
      return MessageBarType.error;
    default:
      return MessageBarType.blocked;
  }
};

export const getStatusFlag = (status: string) => {
  switch (status) {
    case ENV_API_STATE.QUARANTINED:
      return VOILATION_FLAG_MSG.Quarantined;
    // case ENV_API_STATE.IN_VIOLATION:
    //   return  'The violations should be restore by';
    //   //return VOILATION_FLAG_MSG.Deleted;
    default:
      return null;
  }
};
