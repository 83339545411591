import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, catchError, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { IRootState } from '../../IRootState';
import { RootActions } from '../../RootActions';
import { httpPostV1, rescanAssetUrl } from '../../../services';
import { AUTH_SCOPE } from '../../../constants';

import {
    rescanEnvironment,
  rescanEnvironmentFailed,
  rescanEnvironmentSucceeded

} from '../../actions/environment';
import { toastSuccess } from '../../../utils/tools/toast';

export const SubmitEnvironmentRescanEpic: Epic<RootActions, RootActions, IRootState> = (_action$, _store) =>
   _action$.pipe(
    filter(isActionOf(rescanEnvironment)),
    mergeMap((_action$) => {
        const request = {
            url: rescanAssetUrl(),
            scope: AUTH_SCOPE.GOVERNANCE
          };
      return from(
        httpPostV1(request, _action$.payload).pipe(
          mergeMap(() => {
            toastSuccess(
                'Rescan request submitted, status will be updated shortly'
              );
            return of(rescanEnvironmentSucceeded());
          }),
          catchError((error) => {
            return of(rescanEnvironmentFailed(error));
          })
        )
      );
    })
  );

export default [SubmitEnvironmentRescanEpic];
