import { createAction } from 'typesafe-actions';
import { IHttpError } from '../../../models/IHttpError';
import { IRequestStatusEnvironment } from '../../../models/myRequestStatus/IRequestStatusEnvironment';
import {
  GET_REQUEST_STATUS_Environment,
  GET_REQUEST_STATUS_Environment_FAIL,
  GET_REQUEST_STATUS_Environment_SUCCESS
} from '../../constants';

export const getRequestStatusEnvironment = createAction(
  GET_REQUEST_STATUS_Environment,
  (requestId: string) => requestId
)<string>();

export const getRequestStatusEnvironmentSuccess = createAction(
  GET_REQUEST_STATUS_Environment_SUCCESS,
  (requestStatusEnvironment: IRequestStatusEnvironment) => requestStatusEnvironment
)<IRequestStatusEnvironment>();

export const getRequestStatusEnvironmentFail = createAction(
  GET_REQUEST_STATUS_Environment_FAIL,
  (err: IHttpError) => err
)<IHttpError>();
