import { GraphObjectTypes } from './GraphObjectTypes';

export interface IGraphObjectInfo {
  id?: string;
  appId?: string | null;
  homepage?: string | null;
  objectType?: GraphObjectTypes | string;
  displayName?: string;
  mail?: string | null;
  description?: string | null;
  givenName?: string;
  surname?: string | null;
  userPrincipalName?: string;
  immutableId?: string | null;
  companyName?: string | null;
  department?: string | null;
  jobTitle?: string | null;
  city?: string | null;
  country?: string | null;
  telephoneNumber?: string | null;
  photo?: object | null;
}

export const EMPTY_GRAPH_OBJECT_INFO: IGraphObjectInfo = {
  id: '',
  appId: '',
  homepage: '',
  objectType: GraphObjectTypes.NonType,
  displayName: '',
  mail: '',
  description: '',
  givenName: '',
  surname: '',
  userPrincipalName: '',
  immutableId: '',
  companyName: '',
  department: '',
  jobTitle: '',
  city: '',
  country: '',
  telephoneNumber: '',
  photo: {}
};
