/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-param-reassign */
const uniqStrArr = (strArr: string[]): string[] => {
  return Array.from(new Set(strArr));
};

export const splitStrToArr = (str: string): string[] => {
  return uniqStrArr(
    str
      .replace(/( )/g, '')
      .replace(/(;+)/g, ';')
      .replace(/(^\s*;)|(;\s*$)/g, '')
      .split(';')
  );
};

export const dateTimeToLocalString = (dateTime: Date) => {
  return new Date(dateTime.toString() + 'Z').toLocaleString();
};

export const dateTimeToLocalDateString = (dateTime: Date) => {
  return new Date(dateTime.toString() + 'Z').toLocaleDateString();
};

export const joinArrToStr = (strArr: string[]) => {
  return strArr.join(';');
};

export const indexOfSplitChar = (str: string): number => {
  return str.indexOf(';');
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const insertSpaceBeforeCapitalLetters = (str: string): string => {
  return str.split(/(?=[A-Z])/).join(' ');
};

export const fieldNameToLabel = (str: string): string => {
  return insertSpaceBeforeCapitalLetters(capitalizeFirstLetter(str));
};

export const isJsonString = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const stringToObjectOrString = (str: string): string | object => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
};

export const parseQueryString = (queryString?: string): any => {
  // if the query string is NULL or undefined
  if (!queryString) {
    queryString = window.location.search.substring(1);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = {};

  const queries = queryString.split('&');

  queries.forEach((indexQuery: string) => {
    const indexPair = indexQuery.split('=');

    const queryKey = decodeURIComponent(indexPair[0]);
    const queryValue = decodeURIComponent(indexPair.length > 1 ? indexPair[1] : '');
    params[queryKey] = queryValue;
  });

  return params;
};

export const parseStringToJson = <T extends {}>(content = ''): { obj: T } | { errorMassage: string } => {
  try {
    const obj = JSON.parse(content) as T;
    return { obj };
  } catch (error) {
    const exp: Error = error;
    let errorMassage = exp.message;
    errorMassage = errorMassage.substring(0, errorMassage.lastIndexOf('at position')).trimRight();
    if (!errorMassage) {
      return { errorMassage: `${exp.message}` };
    }
    const position = parseInt(exp.message.substr(errorMassage.lastIndexOf(' ')), 10);
    const sub = content.substring(0, position);
    const line = (sub.match(/\n/g) || []).length;
    const col = position - content.substr(0, sub.lastIndexOf('\n')).length;
    return { errorMassage: `${errorMassage}, line: ${line + 1}, column: ${col}` };
  }
};
