/* eslint-disable jsx-a11y/no-noninteractive-tabindex */  
import * as React from 'react';
import { Label, Stack, Icon, css } from '@fluentui/react';
import { violaltionStyle, endSpacingTokens } from './Environment.style';
import { IEnvironmentBasicDetail, IEnvironmentBasicDetailProps } from './EnvironmentViolation.model';
import { ADMIN_ENV_URL } from '../Environment.constants';

export const EnvironmentViolationDetail: React.FunctionComponent<IEnvironmentBasicDetailProps> = (props) => {
  const detail: IEnvironmentBasicDetail | null = props.basicDetails;

  const openEnvironment =() =>{
    let url = `${ADMIN_ENV_URL}/${detail?.id}/hub`
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  return (
    <Stack tokens={endSpacingTokens} className={violaltionStyle.detailWrapper}>
      <Stack horizontal>
          <Icon
            className={violaltionStyle.openAppIconStyle}
            onClick={openEnvironment}
            iconName="OpenInNewWindow"
            aria-label="Open Environment"
            role="link"
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                  event.preventDefault();
                  openEnvironment();
              }
            }}
          />
        <span style={{ padding: '0px 4px', fontSize: '14px', color: '#201F1E' }}>Open Environment</span>
      </Stack>
      <Stack>
        <Label className={violaltionStyle.labelSize}>Environment:</Label>
        <div className={violaltionStyle.labelSize}>{detail?.displayName}</div>
      </Stack>
      {detail?.desc ? (
        <Stack>
          <Label className={css(violaltionStyle.labelSize)}>Description:</Label>
          <div className={css(violaltionStyle.labelSize)}>{detail?.desc}</div>
        </Stack>
      ) : (
        <Stack>
          <Label className={css(violaltionStyle.labelSize)}>Description:</Label>
          <div className={css(violaltionStyle.labelSize, violaltionStyle.disabledLabel)}> {'Not Available'}</div>
        </Stack>
      )}
    </Stack>
  );
};
