import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { getMyApps, getMyAppsFailed, getMyAppsSucceeded } from '../../actions';
import { IRootState } from '../../IRootState';
import { RootActions } from '../../RootActions';
import { getListOwnedAppsUrl, httpGet } from '../../../services';
import { IApp } from '../../../models/AppsList/IAppsRequest';

export const GetListMyAppsEpic: Epic<RootActions, RootActions, IRootState> = (action$, _store) =>
  action$.pipe(
    filter(isActionOf(getMyApps)),
    mergeMap((_action$) => {
      return from(
        httpGet(getListOwnedAppsUrl())
          .pipe(
            map((res) => {
              return res.response as IApp[];
            })
          )
          .pipe(
            mergeMap((res) => {
              return of(getMyAppsSucceeded(res));
            }),
            catchError((error) => {
              return of(getMyAppsFailed(error));
            })
          )
      );
    })
  );

export default [GetListMyAppsEpic];
