import { ILoading } from '../ILoading';
import { IExecutedPolicies } from './IExecutedPolices';
import { IPolicy } from '../policies/IPolicies';

export interface IEnvironmentDetail {
  id: string;
  createdTime: string | null;
  attestationDate: string;
  quarantineDate: string;
  environmentName: string;
  purpose?: string | number;
  description: string;
  justification: string;
  audience: string;
  owners: IUser[];
  owningTeam: string;
  businessSponsor: IUser[];
  dataClassification: string | number;
  isHIPAA: boolean;
  isGDPR: boolean;
  isTented: boolean;
  isSOX: boolean;
  serviceTreeId: string;
  modifiedTime: Date | null;
  modifiedBy: IUser;
  isAttestationConfirmed: boolean;
  lastAttestedBy: IUser;
  lastAttestionDate: Date | null;
  actionType: string;
  assetUri: string;
  executedPolicies: IExecutedPolicies[] | IPolicy[];
}

export interface IEnvironmentDetailRequest extends ILoading {
  data: IEnvironmentDetail;
}

export interface IUser {
  id: string | null;
  displayName: string | null;
  emailAddress: string;
  userPrincipalName: string | null;
  accountType: string | null;
}

export const initialEnvironmentDetail: IEnvironmentDetail = {
  id: '',
  createdTime: null,
  attestationDate: '',
  quarantineDate: '',
  environmentName: '',
  purpose: 0,
  description: '',
  justification: '',
  audience: '',
  owners: [],
  owningTeam: '',
  businessSponsor: [],
  dataClassification: 0,
  isHIPAA: false,
  isGDPR: false,
  isTented: false,
  isSOX: false,
  serviceTreeId: '',
  modifiedTime: null,
  modifiedBy: {} as IUser,
  isAttestationConfirmed: false,
  lastAttestedBy: {} as IUser,
  lastAttestionDate: null,
  actionType: '',
  assetUri: '',
  executedPolicies: []
};
