import { IColumn } from '@fluentui/react';
import * as React from 'react';
import { IRequestStatusAuditLog } from '../../../shared/models/myRequestStatus/IRequestStatusAuditLog';
import { dateTimeToLocalString } from '../../../shared/utils/tools/string';

export interface ITableRequestStatusEnvironmentColumn extends IColumn {
  fieldName: keyof IRequestStatusAuditLog;
  onRender?(item: IRequestStatusAuditLog): React.ReactNode;
}

export const getTableRequestStatusEnvironmentColumns = () => {
  const columns: ITableRequestStatusEnvironmentColumn[] = [
    {
      key: 'timeStamp',
      name: 'Time Stamp (Local Time)',
      fieldName: 'timeStamp',
      headerClassName: 'tbl-header',
      minWidth: 80,
      maxWidth: 170,
      isResizable: false,
      onRender: (item: IRequestStatusAuditLog) => {
        return <div>{dateTimeToLocalString(item.timeStamp)}</div>;
      }
    }
  ];
  columns.push({
    key: 'action',
    name: 'Action',
    fieldName: 'action',
    headerClassName: 'tbl-header',
    minWidth: 80,
    maxWidth: 320,
    isResizable: true
  });

  columns.push({
    key: 'actionOwner',
    name: 'Action Owner',
    fieldName: 'actionOwner',
    headerClassName: 'tbl-header',
    minWidth: 80,
    maxWidth: 160,
    isResizable: true
  });

  columns.push({
    key: 'actionDetail',
    name: 'Action Detail',
    fieldName: 'actionDetail',
    headerClassName: 'tbl-header',
    minWidth: 80,
    maxWidth: 120,
    isResizable: true
  });
  return columns;
};
