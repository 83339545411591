import * as React from 'react';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { EnvironmentAttestationForm } from '../../../../components/Forms/EnvironmentAttestationForm/EnvironmentAttestation';
import { IEnvironmentEditMetadataPanelProps } from './EnvironmentEditMetadataPanel.model';
import { IRootState } from '../../../../shared/store/IRootState';
import { IEnvironmentAttestation } from '../../../../shared/models/Environment/IEnvironmentAttestationRequest';
import { getEnvironmentDetail } from '../../../../shared/store/actions';

export const EnvironmentEditMetadataPanel: React.FunctionComponent<IEnvironmentEditMetadataPanelProps> = (props) => {
  const { selectedEnvironmentId, isOpen, dismissPanel } = props;
  const dispatch = useDispatch();
  const [envDetails, setEnvDetails] = React.useState<IEnvironmentAttestation | null>(null);

  const getEnvironmentDetailRequestState = useSelector((state: IRootState) => state.getEnvironmentDetailRequestState);

  const dismissEnvironmentModal = () => {
    dismissPanel();
  };

  useEffect(() => {
    if (getEnvironmentDetailRequestState.data && getEnvironmentDetailRequestState.data.id) {
      const data = getEnvironmentDetailRequestState.data;
      setEnvDetails(data as unknown as IEnvironmentAttestation);
    }
  }, [getEnvironmentDetailRequestState]);

  const updateViolationState = React.useCallback(() => {
    if (selectedEnvironmentId) {
      setEnvDetails(null);
      dispatch(getEnvironmentDetail(selectedEnvironmentId));
    }
  }, [dispatch, selectedEnvironmentId]);

  useEffect(() => {
    updateViolationState();
  }, [updateViolationState]);

  return (
    <div style={{ lineHeight: '55px' }}>
      <Panel
        isOpen={isOpen}
        onDismiss={dismissPanel}
        type={PanelType.medium}
        closeButtonAriaLabel="Close"
        headerText="Environment Metadata Update Form"
      >
        {getEnvironmentDetailRequestState.isLoading ? (
          <div style={{marginTop:'50%'}}>
            <Spinner size={SpinnerSize.large} label={'Loading Environment Data'} labelPosition="bottom" />
          </div>
        ) : (
          envDetails && (
            <EnvironmentAttestationForm
              environmentId={selectedEnvironmentId || ''}
              attestationDetails={envDetails}
              onSubmitCallback={dismissEnvironmentModal}
              updateViolationState={updateViolationState}
              operationType='Metadata Update'
            />
          )
        )}
      </Panel>
    </div>
  );
};
