import {
  ScrollablePane,
  css,
  Stack,
  DocumentCard,
  DocumentCardDetails,
  DocumentCardImage,
  DocumentCardTitle,
  ImageFit,
  IIconProps
} from '@fluentui/react';
import * as React from 'react';
import { _Styles } from '../../shared/styles/Page.styles';
import { PageProps } from './Page.types';
import {
  HomePageCustomSpacingStackTokens,
  HomePageCardStyles,
  HomePageStyle,
  HomePageStackItemStyles
} from './HomePage.styles';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { APPS_LIST_FF, ENV_REQUEST_FF } from '../../shared/store/constants';

const environmentIconProps: IIconProps = {
  iconName: 'AddOnlineMeeting',
  styles: { root: { color: '#023e8a', fontSize: '120px', width: '120px', height: '120px' } }
};

const mailboxIconProps: IIconProps = {
  iconName: 'Mail',
  styles: { root: { color: '#023e8a', fontSize: '120px', width: '120px', height: '120px' } }
};

const powerappsIconProps: IIconProps = {
  iconName: 'AppIconDefault',
  styles: { root: { color: '#023e8a', fontSize: '120px', width: '120px', height: '120px' } }
};

export const HomePage: React.FC<PageProps> = (props) => {
  return (
    <ScrollablePane className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}>
      <main data-automation-id="HomePage" className={css(HomePageStyle.pageBackground)} tabIndex={-1}>
        <PageHeader pageTitle={'Builders Hub'} />
        <div className={'ms-DetailsContent'}>
          <div
            className={'Card-Row'}
            style={{
              marginTop: '10%',
              textAlign: 'center'
            }}
          >
            {/* <FocusZone> */}
            <Stack
              horizontal
              horizontalAlign="center"
              wrap
              styles={HomePageStackItemStyles}
              tokens={HomePageCustomSpacingStackTokens}
            >
              {props.featureFlags?.[ENV_REQUEST_FF] && (
                <DocumentCard
                  aria-label={'Environment Request, Request a power app environment in CSEO'}
                  styles={HomePageCardStyles}
                  onClickHref="/environment-request"
                >
                  <DocumentCardImage height={150} imageFit={ImageFit.cover} iconProps={environmentIconProps} />
                  <DocumentCardDetails>
                    <DocumentCardTitle title="Environment Request" shouldTruncate />
                    <div style={{ padding: '0px 0px 10px 15px' }}>Request a power app environment in CSEO</div>
                  </DocumentCardDetails>
                </DocumentCard>
              )}
              <DocumentCard
                aria-label={'Mailbox SSS Request'}
                styles={HomePageCardStyles}
                onClickHref="/mailbox-request/create"
              >
                <DocumentCardImage height={150} imageFit={ImageFit.cover} iconProps={mailboxIconProps} />
                <DocumentCardDetails>
                  <DocumentCardTitle title="Mailbox SSS Request" shouldTruncate />
                  <div style={{ padding: '0px 0px 10px 15px' }}>Request a mailbox server side sync in Dynamics</div>
                </DocumentCardDetails>
              </DocumentCard>

              {props.featureFlags?.[APPS_LIST_FF] && (
                <DocumentCard aria-label={'PowerApps'} styles={HomePageCardStyles} onClickHref="/appslist">
                  <DocumentCardImage height={150} imageFit={ImageFit.cover} iconProps={powerappsIconProps} />
                  <DocumentCardDetails>
                    <DocumentCardTitle title="PowerApps" shouldTruncate />
                    <div>Explore all the apps that you own</div>
                  </DocumentCardDetails>
                </DocumentCard>
              )}
            </Stack>
            {/* </FocusZone> */}
          </div>
        </div>
      </main>
    </ScrollablePane>
  );
};
