import { createAction } from 'typesafe-actions';
import { ISearchGraphObjectsInfo } from '../../../models/graph/ISearchGraphObjectsInfo';
import { GraphObjectTypes } from '../../../models/graph/GraphObjectTypes';
import { IHttpError } from '../../../models/IHttpError';
import {
  GET_PRIMARY_FTE_CONTACTS,
  GET_PRIMARY_FTE_CONTACTS_SUCCESS,
  GET_PRIMARY_FTE_CONTACTS_FAIL
} from '../../constants';

export const getPrimaryFTEContactsList = createAction(
  GET_PRIMARY_FTE_CONTACTS,
  (objectType: GraphObjectTypes, keyWords: string) => {
    return { objectType, keyWords };
  }
)<{ objectType: GraphObjectTypes; keyWords: string }>();

export const getPrimaryFTEContactsListSuccess = createAction(
  GET_PRIMARY_FTE_CONTACTS_SUCCESS,
  (searchGraphObjectResult: ISearchGraphObjectsInfo) => ({ searchGraphObjectResult })
)<{ searchGraphObjectResult: ISearchGraphObjectsInfo }>();

export const getPrimaryFTEContactsListFail = createAction(
  GET_PRIMARY_FTE_CONTACTS_FAIL,
  (err: IHttpError) => err
)<IHttpError>();
