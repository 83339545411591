import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IRootState } from '../../shared/store/IRootState';
import { NavigationBase } from './Navigation';
import { ConnectedNavigationProps, NavigationActions, NavigationMappedProps } from './Navigation.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the Navigation control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (INavigationProps) for the Navigation control
 */
const mapStateToProps = (_state: IRootState, _ownProps: ConnectedNavigationProps): NavigationMappedProps => {
  return {};
};

/**
 * Maps the dispatch actions to the props for the Navigation control.
 * @param dispatch Dispatches actions to the store
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (INavigationProps) for the Navigation control
 */
const mapDispatchToProps = (): NavigationActions => {
  return {};
};

/**
 * A redux connected Navigation control.
 * @description Supports translation
 */
// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export const ConnectedNavigation = withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationBase));
