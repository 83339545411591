/* eslint-disable no-plusplus */
/* eslint-disable react/no-did-update-set-state */
// import { css } from '@fluentui/react';
// import React from 'react';
// import { HorizontalStepperStyles, StepperStateStyles, VerticalStepperStyles } from './Stepper.styles';
// import { StepperNodeState, StepperProps, StepperState } from './Stepper.types';

import React from 'react';
import ProgressStepper from '../ProgressStepper/ProgressStepper';
import { ProgressStepperNodeState, ProgressStepperNodeTypes } from '../ProgressStepper/ProgressStepper.types';
import { StepperProps, StepperState } from './Stepper.types';

export default class Stepper extends React.Component<StepperProps, StepperState> {
  constructor(props: StepperProps) {
    super(props);
    this.state = {
      steps: []
    };
  }

  componentDidMount() {
    this.Update();
  }

  componentDidUpdate(prevProps: StepperProps) {
    if (prevProps.currentStepNumber !== this.props.currentStepNumber) {
      this.Update();
    }
  }

  private Update = () => {
    const lst: ProgressStepperNodeState[] = [];
    for (let i = 0; i < this.props.steps.length; i += 1) {
      lst.push({
        description: this.props.steps[i],
        nodeType: i < this.props.currentStepNumber ? ProgressStepperNodeTypes.Complete : ProgressStepperNodeTypes.Empty
      } as ProgressStepperNodeState);
    }
    this.setState({
      steps: lst
    });
  };

  render() {
    return (
      <ProgressStepper
        lstSteps={this.state.steps}
        enableSubDescription={false}
        isVertical={this.props.isVertical}
        currentStepNumber={this.props.currentStepNumber}
      />
    );
  }

  // constructor(props: StepperProps) {
  //   super(props);
  //   this.state = {
  //     // Completed - to add a check mark
  //     // Selected - to fill step with color
  //     // Highlighted - to make text of selected step bold
  //     steps: [] as StepperNodeState[]
  //   };
  // }

  // componentDidMount() {
  //   const { steps, currentStepNumber } = this.props;

  //   const stepsState = steps.map((step, index) => {
  //     const stepObj = {
  //       description: step,
  //       highlighted: index === 0,
  //       selected: index === 0,
  //       completed: false
  //     } as StepperNodeState;

  //     return stepObj;
  //   });

  //   const currentSteps = this.updateStep(currentStepNumber, stepsState);

  //   this.setState({
  //     steps: currentSteps
  //   });
  // }

  // componentDidUpdate(prevProps: StepperProps) {
  //   const { steps } = this.state;
  //   const currentSteps = this.updateStep(this.props.currentStepNumber, steps);

  //   if (prevProps.currentStepNumber !== this.props.currentStepNumber)
  //     this.setState({
  //       steps: currentSteps
  //     });
  // }

  // updateStep = (stepNumber: number, steps: StepperNodeState[]): StepperNodeState[] => {
  //   const newSteps = [...steps];
  //   let stepCounter = 0;

  //   // Completed - to add a check mark
  //   // Selected - to fill step with color
  //   // Highlighted - to make text of selected step bold

  //   while (stepCounter < newSteps.length) {
  //     // Current step
  //     if (stepCounter === stepNumber) {
  //       newSteps[stepCounter] = {
  //         ...newSteps[stepCounter],
  //         highlighted: true,
  //         selected: true,
  //         completed: false
  //       };
  //       stepCounter++;
  //     }
  //     // Past step
  //     else if (stepCounter < stepNumber) {
  //       newSteps[stepCounter] = {
  //         ...newSteps[stepCounter],
  //         highlighted: false,
  //         selected: true,
  //         completed: true
  //       };
  //       stepCounter++;
  //     }
  //     // Future step
  //     else {
  //       newSteps[stepCounter] = {
  //         ...newSteps[stepCounter],
  //         highlighted: false,
  //         selected: false,
  //         completed: false
  //       };
  //       stepCounter++;
  //     }
  //   }

  //   return newSteps;
  // };

  // render() {
  //   const { steps } = this.state;
  //   const stepsDisplay = steps.map((step, index) => {
  //     const verticalDividerLineStyle = index !== steps.length - 1 ? css(VerticalStepperStyles.dividerLine) : undefined;
  //     const horizontalDividerLineStyle =
  //       index !== steps.length - 1 ? css(HorizontalStepperStyles.dividerLine) : undefined;
  //     return (
  //       <div
  //         key={`${step.description}`}
  //         className={
  //           this.props.isVertical
  //             ? css(VerticalStepperStyles.stepperWrapper)
  //             : css(HorizontalStepperStyles.stepperWrapper)
  //         }
  //       >
  //         <div
  //           className={`${
  //             this.props.isVertical
  //               ? css(VerticalStepperStyles.stepperNumber)
  //               : css(HorizontalStepperStyles.stepperNumber)
  //           }
  //             ${step.selected ? StepperStateStyles.stepNumberSelected : StepperStateStyles.stepNumberDisabled}`}
  //         >
  //           {step.completed ? '✓' : index + 1}
  //         </div>
  //         <div className={'step-description'}>{step.description}</div>
  //         <div className={this.props.isVertical ? verticalDividerLineStyle : horizontalDividerLineStyle} />
  //       </div>
  //     );
  //   });
  //   return (
  //     <div
  //       className={
  //         this.props.isVertical
  //           ? css(VerticalStepperStyles.stepperWrapperVertical)
  //           : css(HorizontalStepperStyles.stepperWrapperHorizontal)
  //       }
  //     >
  //       {stepsDisplay}
  //     </div>
  //   );
  // }
}
