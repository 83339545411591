/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { AccountInfo } from '@azure/msal-browser';
import { msalInstance } from '.';

const authorityUrl = `${process.env.REACT_APP_MSAL_INSTANCE}${process.env.REACT_APP_MSAL_UI_TENANT}`;

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_UI_CLIENT_ID,
    authority: authorityUrl,
    redirectUri: '/',
    prompt: 'consent'
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};

export const getCachedUser = (): AccountInfo | null => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    const username = accounts[0].username;
    return msalInstance.getAccountByUsername(username);
  }
  return null;
};

export const logout = () => {
  msalInstance.logoutRedirect();
}

export function authenticateToken(scope: string): Promise<string | null> {
  return new Promise<string | null>((resolve, reject) => {
    const userAccounts = msalInstance.getAllAccounts();

    if (userAccounts.length > 0) {
      msalInstance
        .acquireTokenSilent({
          scopes: [scope],
          account: userAccounts[0],
          redirectUri: window.location.origin
        })
        .then((response: any) => {
          const accessToken = response.accessToken;
          resolve(accessToken);
        })
        .catch((error: any) => {
          reject(error);
        });
    } else {
      reject(new Error('No logged-in user found'));
    }
  });
}
