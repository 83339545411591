import { INavLink, INavLinkGroup } from '@coherence-design-system/controls';
import { RouteProps } from 'react-router-dom';
import { IPageViewTelemetry } from '@microsoft/applicationinsights-web';
import { NavigationProps } from './Navigation.types';
import { APPS_LIST_FF, ENV_LIST_FF, ENV_REQUEST_FF, SPS_LIST_FF } from '../../shared/store/constants';
import { TelemetryService } from '../TelemetryService/TelemetryService';
import { telemetryConfig } from '../TelemetryService/TelemetryConfig';

const links: INavLink[] = [];
export const authzRoutes: RouteProps[] = [];

export const addRoute = (route: RouteProps) => {
  authzRoutes.push(route);
};

export const addLink = (linkToAdd: INavLink) => {
  links.push(linkToAdd);
};

TelemetryService.initialize(telemetryConfig);

export const getNavigation = (props: NavigationProps): INavLinkGroup[] => {
  const navLinkGroups: INavLinkGroup[] = [
    {
      key: 'Left Navigation Menu',
      links: [
        {
          name: 'Home',
          key: 'HomeLink1',
          ariaLabel: 'homestuff',
          icon: 'Home',
          'data-id': 'automation_id_22230',
          target: '_blank',
          onClick: () => {
            TelemetryService.trackPageView({
              name: 'Home',
              uri: `${window.location.protocol + '//' + window.location.host + '/'}`,
              refUri: ''
            } as IPageViewTelemetry);
            props.history.push('/');
          },
          isSelected: window.location.href === window.location.protocol + '//' + window.location.host + '/'
        },
        {
          name: 'Request Status',
          key: 'MyRequestStatusesLink',
          ariaLabel: 'My Request Status',
          icon: 'Headset',
          target: '_blank',
          onClick: () => {
            TelemetryService.trackPageView({
              name: 'Request Status',
              uri: `${window.location.protocol + '//' + window.location.host + '/list-request-status'}`,
              refUri: ''
            } as IPageViewTelemetry);
            props.history.push('/list-request-status');
          },
          isSelected:
            window.location.href === window.location.protocol + '//' + window.location.host + '/list-request-status'
        }
      ]
    },
    {
      key: 'Left Navigation Power Platform Menu',
      name: 'Power Platform',
      links: [

       
              {
                name: 'Environment',
                key: 'Environment',
                icon: 'AddOnlineMeeting',
                onClick: (_ev?: React.MouseEvent<HTMLElement>, _item?: INavLink) => {
                  // console.log(item);
                },
                links: [
                  ...(props.featureFlags?.[ENV_REQUEST_FF]
                    ? [
                  {
                    name: 'New',
                    key: 'NewEnvironmentRequestLink',
                    ariaLabel: 'New Environment Request',
                    target: '_blank',
                    onClick: () => {
                      TelemetryService.trackPageView({
                        name: 'New Environment Request',
                        uri: `${window.location.protocol + '//' + window.location.host + '/environment-request'}`,
                        refUri: ''
                      } as IPageViewTelemetry);
                      props.history.push('/environment-request');
                    },
                    isSelected:
                      window.location.href ===
                      window.location.protocol + '//' + window.location.host + '/environment-request'
                  } ]
                  : []),
                  ...(props.featureFlags?.[ENV_LIST_FF]
                    ? [
                  {
                    name: 'View',
                    key: 'ViewEnvironmentsLink',
                    ariaLabel: 'View Environments Link',
                    // target: '_blank',
                    onClick: () => {
                      TelemetryService.trackPageView({
                        name: 'Environments',
                        uri: `${window.location.protocol + '//' + window.location.host + '/power-platform/environments'}`,
                        refUri: ''
                      } as IPageViewTelemetry);
                      props.history.push('/power-platform/environments');
                    },
                    isSelected:
                      window.location.href ===
                      window.location.protocol + '//' + window.location.host + '/power-platform/environments'
                  }]
                  :[]),
                ]
              },
              ...(props.featureFlags?.[APPS_LIST_FF]
                ? [
                    {
                      name: 'Apps',
                      key: 'PowerApps',
                      ariaLabel: 'Apps',
                      icon: 'AppIconDefault',
                      target: '_blank',
                      onClick: () => {
                        TelemetryService.trackPageView({
                          name: 'Apps',
                          uri: `${window.location.protocol + '//' + window.location.host + '/appslist'}`,
                          refUri: ''
                        } as IPageViewTelemetry);
                        props.history.push('/appslist');
                      },
                      isSelected:
                        window.location.href === window.location.protocol + '//' + window.location.host + '/appslist'
                    }
                  ]
                : []) // maybe there is a better way to do this than spreading?
           
       
      ]
    },
    ...(props.featureFlags?.[SPS_LIST_FF] ?[
    {
      key: 'Left Navigation Shared Workspaces Menu',
      name: 'Shared Workspaces',
      links: [
              {
                name: 'Sharepoint Sites',
                key: 'Sharepointsites',
                icon: 'SharepointAppIcon16',
                onClick: (_ev?: React.MouseEvent<HTMLElement>, _item?: INavLink) => {
                  // console.log(item);
                },
                links: [
                  {
                    name: 'View',
                    key: 'ViewSharepointSitesLink',
                    ariaLabel: 'View Sharepoint Sites Link',
                    // target: '_blank',
                    onClick: () => {
                      TelemetryService.trackPageView({
                        name: 'Sharepoint Sites',
                        uri: `${window.location.protocol + '//' + window.location.host + '/shared-workspaces/sharepointsites'}`,
                        refUri: ''
                      } as IPageViewTelemetry);
                      props.history.push('/shared-workspaces/sharepointsites');
                    },
                    isSelected:
                      window.location.href ===
                      window.location.protocol + '//' + window.location.host + '/shared-workspaces/sharepointsites'
                  },
                ]
              }
      ]
    }]:[]),
    {
      key: 'Left Navigation Mailbox SSS Menu',
      name: 'Mailbox SSS',
      links: [
            {
              name: 'Mailbox Request',
              key: 'MailboxRequestLink',
              ariaLabel: 'Mailbox Request',
              icon:'mail',
              target: '_blank',
              onClick: () => {
                TelemetryService.trackPageView({
                  name: 'Mailbox Request',
                  uri: `${window.location.protocol + '//' + window.location.host + '/mailbox-request/create'}`,
                  refUri: ''
                } as IPageViewTelemetry);
                props.history.push('/mailbox-request/create');
              },
              isSelected:
                window.location.href ===
                window.location.protocol + '//' + window.location.host + '/mailbox-request/create'
            },
            {
              name: 'Global Admin Approval',
              key: 'MailboxSSSGAApproval',
              ariaLabel: 'Global Admin Approval',
              icon:'UserFollowed',
              target: '_blank',
              onClick: () => {
                TelemetryService.trackPageView({
                  name: 'Global Admin Approval',
                  uri: `${window.location.protocol + '//' + window.location.host + '/mailbox-sss-ga-approval'}`,
                  refUri: ''
                } as IPageViewTelemetry);
                props.history.push('/mailbox-sss-ga-approval');
              },
              isSelected:
                window.location.href ===
                window.location.protocol + '//' + window.location.host + '/mailbox-sss-ga-approval'
            }
          ]
        }
        

      ]
      return navLinkGroups;
    };
