import { createAction } from 'typesafe-actions';
import { IMailboxSSS } from '../../../models/MailboxSSS/IMailboxSSSRequests';
import { IHttpError } from '../../../models/IHttpError';
import {
  SUBMIT_MAILBOXSSS_REQUEST,
  SUBMIT_MAILBOXSSS_REQUEST_SUCCESS,
  SUBMIT_MAILBOXSSS_REQUEST_FAIL
} from '../../constants';

export const submitMailboxSSSRequest = createAction(
  SUBMIT_MAILBOXSSS_REQUEST,
  (IMailboxSSS: IMailboxSSS) => IMailboxSSS
)<IMailboxSSS>();

export const submitMailboxSSSRequestSuccess = createAction(
  SUBMIT_MAILBOXSSS_REQUEST_SUCCESS,
  (IMailboxSSS: IMailboxSSS) => IMailboxSSS
)<IMailboxSSS>();

export const submitMailboxSSSRequestFail = createAction(
  SUBMIT_MAILBOXSSS_REQUEST_FAIL,
  (err: IHttpError) => err
)<IHttpError>();
