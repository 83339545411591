import { connect } from 'react-redux';
import { IAppMetadata } from '../../../shared/models/AppsList/IAppMetadataRequest';
import { getAppMetadata, setAppMetadata } from '../../../shared/store/actions';
import { IRootState } from '../../../shared/store/IRootState';
import { AppMetadataForm } from './AppMetadataForm';
import { AppMetadataActions, AppMetadataReducerStates } from './AppMetadataFormTypes';

// Reducer States
const mapStateToProps = (state: IRootState): AppMetadataReducerStates => {
  const { getAppMetadataRequestState, setAppMetadataRequestState } = state;
  return {
    getAppMetadataRequestState: getAppMetadataRequestState,
    setAppMetadataRequestState: setAppMetadataRequestState
  };
};

// Actions
const mapDispatchToProps = (): AppMetadataActions => {
  return {
    getAppMetadata: (appId: string) => getAppMetadata(appId),
    setAppMetadata: (appId: string, data: IAppMetadata) => setAppMetadata(appId, data)
  };
};

export const ConnectedAppMetadataForm = connect(mapStateToProps, mapDispatchToProps())(AppMetadataForm);
