const itemByKey = <T>(arr: T[], key: string, value: string, replaceTo: T): Array<T> => {
  const result: Array<T> = [];
  try {
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < arr.length; index++) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let val: any = arr[index];
      if (val[key] === value) {
        val = replaceTo;
      }
      result.push(val);
    }
    return result;
  } catch (ex) {
    return arr;
  }
};

export const replace = {
  itemByKey
};
