export enum EnvironmentType {
  localhost = 'localhost',
  PPE = 'PPE',
  TIP = 'TIP',
  PROD = 'PROD',
  AR = 'US'
}

let environmentCallback: EnvironmentCallback | undefined;

export const determineEnvironment = (location: Location): EnvironmentType => {
  if (environmentCallback !== undefined) {
    const environmentOverride = environmentCallback(location);
    if (environmentOverride !== undefined) {
      return environmentOverride;
    }
  }

  const currentUrl = location.hostname.toLowerCase();

  if (currentUrl.includes(EnvironmentType.localhost.toString().toLowerCase())) {
    return EnvironmentType.localhost;
  }

  if (currentUrl.includes(EnvironmentType.PPE.toString().toLowerCase())) {
    return EnvironmentType.PPE;
  }

  if (currentUrl.includes(EnvironmentType.TIP.toString().toLowerCase())) {
    return EnvironmentType.TIP;
  }

  if (currentUrl.includes(EnvironmentType.AR.toString().toLowerCase())) {
    return EnvironmentType.AR;
  }

  // Assume that if the URL doesn't contain localhost, TIP, or PPE then it's PROD
  return EnvironmentType.PROD;
};

export const getEnvironmentSetting = (
  env: EnvironmentType,
  fallback: string,
  prodSetting?: string,
  ppeSetting?: string,
  tipSetting?: string,
  localhostSetting?: string,
  arSetting?: string,
  overrideSetting?: string
) => {
  if (overrideSetting) {
    return overrideSetting;
  }

  if (env === EnvironmentType.localhost && localhostSetting) {
    return localhostSetting;
  }

  if ((env === EnvironmentType.PPE || env === EnvironmentType.localhost) && ppeSetting) {
    return ppeSetting;
  }

  if (env === EnvironmentType.TIP && tipSetting) {
    return tipSetting;
  }

  if (env === EnvironmentType.AR && arSetting) {
    return arSetting;
  }

  // Currently the prod setting also acts the fallback in the .env file
  if (prodSetting) {
    return prodSetting;
  }

  return fallback;
};

export const setEnvironmentCallback = (callback: EnvironmentCallback | undefined) => {
  environmentCallback = callback;
};

export type EnvironmentCallback = (location: Location) => EnvironmentType | undefined;
