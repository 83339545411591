import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { IRootState } from '../../IRootState';
import { RootActions } from '../../RootActions';
import {  httpPost, submitMailboxSSSRequestUrl } from '../../../services';

import { submitMailboxSSSRequest, submitMailboxSSSRequestFail, submitMailboxSSSRequestSuccess } from '../../actions';
import { IMailboxSSS } from '../../../models/MailboxSSS/IMailboxSSSRequests';
import { toastError} from '../../../utils/tools/toast';

export const SubmitMailboxSSSRequestEpic: Epic<RootActions, RootActions, IRootState> = (action$, _store) =>
  action$.pipe(
    filter(isActionOf(submitMailboxSSSRequest)),
    mergeMap((action$) => {
      return from(httpPost(submitMailboxSSSRequestUrl(), action$.payload))
        .pipe(
          map((res) => {
            return res.response as IMailboxSSS;
          })
        )
        .pipe(
          mergeMap((result) => {
            // toastSuccess(`Mailbox SSS Request Form has been submitted successfully, Check Your Mail for further Information.`);
            return of(submitMailboxSSSRequestSuccess(result));
          }),
          catchError((error) => {
            toastError('MailboxSSS Request Form has not submitted succeffully.',error);
            return of(submitMailboxSSSRequestFail(error));
          })
        );
    })
  );

export default [SubmitMailboxSSSRequestEpic];
