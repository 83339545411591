import { mergeStyleSets } from '@fluentui/react';

export const EnvironmentRequestBasicInfoFormStyle = mergeStyleSets({
  wrapper: {
    display: 'grid',
    gap: '10px',
    padding: '6px 6px 6px 0px'
  },
  lgScreenWrapper: {
    gridTemplateColumns: 'minmax(175px, 1fr) minmax(150px, 1fr)',
    gridAutoRows: 'minmax(10px, auto)'
  },
  smScreenWrapper: {
    overflowX: 'hidden',
    gridTemplateColumns: 'minmax(120px, 1fr)',
    gridAutoRows: 'minmax(10px, auto)'
  },
  formColMedium: {
    gridColumn: 1 / 2,
    position: 'relative'
  },
  formColSmall: {
    gridColumn: 2 / 3,
    position: 'relative'
  }
});

export const EnvironmentRequestPlatformInformationFormStyle = mergeStyleSets({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '10px',
    gridAutoRows: 'minmax(70px, auto)',
    padding: '6px 6px 6px 0px'
  },
  generalInfoWrapper: {
    gridColumn: 1 / 2
  },
  dataVerseWrapper: {
    gridColumn: 2 / 3
  },
  dataVerseContent: {
    border: '1px solid grey',
    padding: '10px'
  },
  formColNonCDS: {
    position: 'relative'
  },
  formColCDS: {
    height: '70px',
    paddingBottom: '6px',
    position: 'relative'
  },
  formValidateBtn: {
    paddingBottom: '6px',
    position: 'relative'
  },
  formMessageBox: {
    paddingBottom: '6px',
    position: 'relative'
  },
});

export const EnvironmentRequestComplianceInformationFormStyle = mergeStyleSets({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '10px',
    gridAutoRows: 'minmax(30px, auto)',
    width: '70%'
  },
  formColMedium: {
    gridColumn: 1 / 2,
    height: '60px',
    position: 'relative'
  },
  formColSmall: {
    gridColumn: 1 / 2,
    position: 'relative'
  },
  formColCheckbox: {
    paddingBottom: '10px'
  },
  formColHeader: {
    padding: '10px 0px',
    fontWeight: '500'
  }
});

export const EnvironmentRequestFormStyle = mergeStyleSets({
  formWrapper: {
    margin: '1%',
    padding: '0% 1%'
  },
  formContent: {
    padding: '8px 8px 8px 0px'
  },
  pLR60: {
    padding: '0px 60px'
  },
  calloutDialog : {
    margin: '10px 10px',
    width: 320,
    padding: '20px 24px'
  }
});

export const EnvironmentRequestStepperStyle = mergeStyleSets({
  wrapper: {
    display: 'flex',
    justifyContent: 'center'
  }
});
