// import { createAsyncAction } from 'typesafe-actions';
// import { IRoleDefinition } from '../../../models/IRoleDefinition';
// import {
//   GET_MAILBOX_SSS_LIST_REQUESTS,
//   GET_MAILBOX_SSS_LIST_REQUESTS_SUCCESS,
//   GET_MAILBOX_SSS_LIST_REQUESTS_FAIL
// } from '../../constants';

// export const getMailboxSSSListRequestsAction = createAsyncAction(
//   [GET_MAILBOX_SSS_LIST_REQUESTS, (appId: string) => appId], // request payload creator
//   [GET_MAILBOX_SSS_LIST_REQUESTS_SUCCESS, (list: IRoleDefinition[]) => list], // success payload creator
//   [GET_MAILBOX_SSS_LIST_REQUESTS_FAIL, (err: Error) => err] // failure payload creator
// )();

import { createAction } from 'typesafe-actions';
import { IHttpError } from '../../../models/IHttpError';
import { IMailboxSSSRequest } from '../../../models/MailboxSSS/IMailboxSSSRequest';
import {
  GET_LIST_MAILBOX_SSS_REQUESTS,
  GET_LIST_MAILBOX_SSS_REQUESTS_SUCCESS,
  GET_LIST_MAILBOX_SSS_REQUESTS_FAIL
} from '../../constants';

export const getListMailboxSSSRequests = createAction(GET_LIST_MAILBOX_SSS_REQUESTS, () => { })();

// export const getListMailboxSSSRequests = createAction(
//   GET_LIST_MAILBOX_SSS_REQUESTS, (appId: string) => appId
// )<string>();

// export const getListMailboxSSSRequests = createAction(
//   GET_LIST_MAILBOX_SSS_REQUESTS,
//   (query: IRoleAssignmentQueryParams) => query,
//   // (appId: string) => appId
// )<IRoleAssignmentQueryParams>();

export const getListMailboxSSSRequestsSuccess = createAction(
  GET_LIST_MAILBOX_SSS_REQUESTS_SUCCESS,
  (lstMailboxSSSRequests: IMailboxSSSRequest[]) => lstMailboxSSSRequests
)<IMailboxSSSRequest[]>();

export const getListMailboxSSSRequestsFail = createAction(GET_LIST_MAILBOX_SSS_REQUESTS_FAIL, (err: IHttpError) => err)<
  IHttpError
>();
