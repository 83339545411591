/* eslint-disable @typescript-eslint/no-unsafe-return */
import { ScrollablePane, Stack, Breadcrumb, css, MessageBar, Label, Icon, MessageBarType, Separator, Spinner, SpinnerSize, ShimmeredDetailsList, SelectionMode, IBreadcrumbItem, IColumn, Link, PrimaryButton } from '@fluentui/react'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { fluentBreadcrumb, fluentBreadcrumbItem, provideFluentDesignSystem } from '@fluentui/web-components';
import { SCREEN_SIZE } from '../../../../shared/constants'
import { _Styles } from '../../../../shared/styles/Page.styles'
import { IRootState } from '../../../../shared/store/IRootState'
import { ISharePointBasicDetail, ISharePointViolationPolicy } from './SharePointViolation.model'
import { getSharePointDetail } from '../../../../shared/store/actions'
import { SPS_CONST } from '../SharePointSites.constants'
import { ISharePointDetail } from '../../../../shared/models/SharepointSites/ISharePointDetailRequests'
import { LoadingState } from '../../../../shared/models/ILoading'
import { fetchFailedExecutedPolicies, getDueDateString, getStatusFlag, selectClass } from './SharePointViolation.helper'
import { SharePointViolationDetail } from './SharePointViolationDetail'
import { sharePointPolicyColumns } from './SharePointViolation.column'
import { endSpacingTokens, messageBarStyles, violaltionStyle } from './SharePointViolation.style';

provideFluentDesignSystem().register(
  fluentBreadcrumb(),
  fluentBreadcrumbItem({
    separator: `<div style="font-size: 20px;
      color: #605e5c; margin-left: -2px;">></div>`
  })
);

// eslint-disable-next-line react/no-unused-prop-types
export function SharePointViolation(props: { isNavCollapsed: any; }) {

  const history = useHistory();

  const { sharePointId }: { [key: string]: string } = useParams();
  const policiesRequestState = useSelector((state: IRootState) => state.sharePointPoliciesRequestState);
  const dispatch = useDispatch();
  const [basicDetails, setBasicDetails] = useState<ISharePointBasicDetail | null>(null);
  const [sharePointName, setSharePointName] = useState<string>('');
  const [failedPolicies, setFailedPolicies] = useState<ISharePointViolationPolicy[]>([]);
  const getSharePointDetailRequestState = useSelector((state: IRootState) => state.getSharePointDetailRequestState);
  const [sharePointDetails, setSharePointDetails] = useState<ISharePointDetail | null>(null);
  
  const goBack = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    history.push('/shared-workspaces/sharepointsites');
  };

  const items: IBreadcrumbItem[] = [
    { text: 'SharePoints', key: 'sharepoints', onClick: goBack },
    { text: sharePointName, key: `${sharePointId}` }
  ];

  const onRenderItemColumn = (item: ISharePointViolationPolicy, index: number, column: IColumn) => {
    const fieldContent = item[column.fieldName as keyof ISharePointViolationPolicy] as string;
    switch (column.key) {
      case 'info':
        return (
          <Link
            rel="noopener noreferrer"
            disabled={sharePointDetails?.complianceState === SPS_CONST.ApiStatus.Deleted}
            style={{ lineHeight: '40px', fontSize: '14px' }}
            target="_blank"
            href={item.info}
            underline 
          >
            How To fix
          </Link>
        );
      case 'action':
        return (
          <PrimaryButton
            href= {item?.action}
            disabled={sharePointDetails?.complianceState === SPS_CONST.ApiStatus.Deleted}
            target="_blank"
            rel="noopener noreferrer"
            style={{width:'130px'}}
          >
            {item.id === 'Eue.Governance.Spe.Core.AttestationPolicy' ? 'Remediate' : 'Open Site'}
          </PrimaryButton>
        );
      default:
        return <p style={{ whiteSpace: 'pre-wrap', fontSize: '14px' }}>{fieldContent}</p>;
    }
  };

  useEffect(()=>{
   dispatch(getSharePointDetail(sharePointId));
  },[sharePointId, dispatch]);

  useEffect(() => {
    if (policiesRequestState.loadingState === LoadingState.loaded && policiesRequestState.data) {
      if (getSharePointDetailRequestState.data && getSharePointDetailRequestState.data.id) {
        const data = getSharePointDetailRequestState.data;
        const basicDetail: ISharePointBasicDetail = {
          id: data.id,
          assetUri: data.assetUri,
          dueDate: data.deleteDate,
          desc: data.displayName, // Since description is not available, setting this to to display name for now
          displayName: data.displayName,
          remediationLink: data.remediationLink,
          workspaceUrl: data.workspaceUrl
        };
        setFailedPolicies(
          fetchFailedExecutedPolicies(data.executedPolicies, policiesRequestState.data)
        );
        setSharePointName(data.displayName);
        setBasicDetails(basicDetail);
        setSharePointDetails(data);
      }
    }
  }, [getSharePointDetailRequestState, policiesRequestState]);
  
  return getSharePointDetailRequestState.isLoading ? (
    <Stack className={violaltionStyle.spinnerCenter}>
      <Spinner size={SpinnerSize.large} />
    </Stack>
  ) :
  (
    <ScrollablePane className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand} aria-label="Sharepoints List" role="region">
       <div id='no-horizontal-scroll' className={css(violaltionStyle.noHorizontalScroll)}>
       {getSharePointDetailRequestState.data.id && (
        <Stack>
          <Stack horizontal horizontalAlign="space-between" style={{ marginTop: '20px' }}>
            <div className={violaltionStyle.displaySpaceBetween}>
              <Stack.Item>
                {window.innerWidth < SCREEN_SIZE.MEDIUM ? (
                  <Breadcrumb items={items} ariaLabel="Sharepoints List" />
                ) : (
                  <fluent-breadcrumb>
                    <fluent-breadcrumb-item  href="/shared-workspaces/sharepointsites" role="button" tabIndex={0}>
                      <div className={css(violaltionStyle.breadcrumb, violaltionStyle.distanceToSidePannel)} >
                        SharePoint
                      </div>
                    </fluent-breadcrumb-item>
                    <fluent-breadcrumb-item>
                      <div className={violaltionStyle.priorBreadcrumb}>{basicDetails?.displayName}</div>
                      <div>
                        <MessageBar messageBarType={selectClass(sharePointDetails?.complianceState)}>
                          {SPS_CONST.ApiStatus[sharePointDetails?.complianceState]}
                        </MessageBar>
                      </div>
                    </fluent-breadcrumb-item>
                  </fluent-breadcrumb>
                )}
              </Stack.Item>
              <div className={violaltionStyle.m5}>
                <Stack horizontal tokens={endSpacingTokens}>
                  <span className={violaltionStyle.platformLabel}>Shared Workspace</span>
                  <Label style={{ lineHeight: '25px', fontSize: '14px' }}>SharePoint Sites</Label>
                </Stack>
              </div>
            </div>
          </Stack>

          <div>
            <SharePointViolationDetail basicDetails={basicDetails} />
            {SPS_CONST.ApiStatus[sharePointDetails?.complianceState] === SPS_CONST.ApiStatus.Quarantined && (
              <div className={violaltionStyle.infoLabelWrapper}>
                <Icon iconName="Info" className={violaltionStyle.infoIcon} />
              </div>
            )}
            {getStatusFlag(sharePointDetails?.complianceState) && (
              <div className={violaltionStyle.infoLabelWrapper}>
                <MessageBar messageBarType={MessageBarType.warning} style={{ fontSize: '14px' }}>
                  {getStatusFlag(sharePointDetails?.complianceState)}
                </MessageBar>
              </div>
            )}
            <Separator style={{ fontWeight: '700', backgroundColor: '#e8e8e8 !important' }} />
            {sharePointDetails?.complianceState !== SPS_CONST.ApiStatus.Compliant &&
            <div is='Sharepoint-violation-details'>
            <Stack horizontal horizontalAlign="space-between">
              <Label className={violaltionStyle.distanceToSidePannel}>Policy Violations</Label>
              {sharePointDetails?.complianceState === SPS_CONST.ApiStatus.Deleted &&
              <Stack horizontal
                className={violaltionStyle.restore}
                onClick={() =>{
                  let url = `${basicDetails?.remediationLink}`
                  window.open(url);}}   
                >
                <PrimaryButton style={{ margin: '2px 4px', cursor: 'pointer' }}>Restore</PrimaryButton>
              </Stack>}
            </Stack>
            {basicDetails?.dueDate && (
              <div>
                <Stack horizontal>
                  <Icon iconName="DateTime" className={violaltionStyle.dueDateIcon} />
                  <div className={violaltionStyle.dueDate}>
                    <Label className={violaltionStyle.p0}>Due Date:</Label>
                    <span className={violaltionStyle.dueDateValue}>{getDueDateString(basicDetails.dueDate)}</span>
                  </div>
                </Stack>
                {sharePointDetails?.complianceState === SPS_CONST.ApiStatus.Deleted && (
                  <MessageBar messageBarType={MessageBarType.warning} styles={messageBarStyles}>
                    SharePoint site is deleted, please restore if required. click on <b>Restore</b> button.
                  </MessageBar>
                )}
              </div>
            )}
            <div>
              {failedPolicies.length !== 0 && (
                <ShimmeredDetailsList
                  setKey="items"
                  items={failedPolicies}
                  columns={sharePointPolicyColumns}
                  selectionMode={SelectionMode.none}
                  onRenderItemColumn={onRenderItemColumn}
                  // enableShimmer={!items}
                  ariaLabelForShimmer="Content is being fetched"
                  ariaLabelForGrid="Item details"
                />
              )}
            </div>
            </div>
            }
          </div>
        </Stack>
      )}
      </div>
    </ScrollablePane>
  );
}