import { ICommandBarItemProps } from '@fluentui/react';

// Getting initial CommandBar configured
export const getRequestStatusCreateMailSSSCommandBarItems = (refresh: () => void): ICommandBarItemProps[] => {
  return [
    {
      key: 'refresh',
      name: 'Refresh',
      iconProps: {
        iconName: 'Refresh'
      },
      ariaLabel: 'Refresh. Use left and right arrow keys to navigate',
      onClick: () => refresh()
    }
  ];
};
