import * as React from 'react';
import { IStyleFunctionOrObject} from '@fluentui/react';
import { CoherenceNav, ICoherenceNavStyleProps, ICoherenceNavStyles } from '@coherence-design-system/controls';
import { getNavigation } from './Routings';
import { NavigationProps, NavigationState } from './Navigation.types';


const overrideNavStyles: IStyleFunctionOrObject<ICoherenceNavStyleProps, ICoherenceNavStyles> = {
  root: {
    borderRight: '1px solid #c1c1c1',
    fontFamily: "Segoe UI"
    
  }
};

export class NavigationBase extends React.Component<NavigationProps, NavigationState> {
  render() {
    return (
      <CoherenceNav
        appName={'Builders Hub'}
        groups={getNavigation(this.props)}
        onNavCollapsed={this.props.onNavCollapsed}
        styles={overrideNavStyles}
      />
    );
  }
}
