import { createAction } from 'typesafe-actions';
import { IHttpError } from '../../../models/IHttpError';
import { IMailboxSSSRequest } from '../../../models/MailboxSSS/IMailboxSSSRequest';
import { USER_EMAIL_APPROVAL, USER_EMAIL_APPROVAL_FAIL, USER_EMAIL_APPROVAL_SUCCESS } from '../../constants';

export const userEmailApproval = createAction(
  USER_EMAIL_APPROVAL,
  (isApproved: boolean, businessJustification: string, curRequest: number, request: IMailboxSSSRequest) => ({
    isApproved,
    businessJustification,
    curRequest,
    request
  })
)<{ isApproved: boolean; businessJustification: string; curRequest: number; request: IMailboxSSSRequest }>();

export const userEmailApprovalSuccess = createAction(USER_EMAIL_APPROVAL_SUCCESS, (count: number) => count)<number>();

export const userEmailApprovalFail = createAction(USER_EMAIL_APPROVAL_FAIL, (err: IHttpError) => err)<IHttpError>();
