import { createReducer } from 'typesafe-actions';
import { IEventTelemetry, IExceptionTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { getRequestStatusMailSSS, getRequestStatusMailSSSFail, getRequestStatusMailSSSSuccess } from '../../actions';
import { toastError } from '../../../utils/tools/toast';
import { IRequestStatus } from '../../../models/myRequestStatus/IRequestStatus';
import { IMailboxSSSRequest } from '../../../models/MailboxSSS/IMailboxSSSRequest';
import { IRequestStatusAuditLog } from '../../../models/myRequestStatus/IRequestStatusAuditLog';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { telemetryConfig } from '../../../../components/TelemetryService/TelemetryConfig';
import { API_NAMES, API_STATUS, EVENTS } from '../../../constants';

TelemetryService.initialize(telemetryConfig);

export const requestStatusMailboxSSSReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(getRequestStatusMailSSS, (state) => {
    return {
      ...state,
      requestStatusMailboxSSSState: {
        ...state.requestStatusMailboxSSSState,
        requestStatus: {} as IRequestStatus,
        mailboxSSSRequest: {} as IMailboxSSSRequest,
        lstAuditLogs: [] as IRequestStatusAuditLog[],
        isLoading: true,
        loadingState: LoadingState.loading
      }
    };
  })
  .handleAction(getRequestStatusMailSSSSuccess, (state, action) => {
    let requestStatus = {} as IRequestStatus;
    let mailboxSSSRequest = {} as IMailboxSSSRequest;
    let lstAuditLogs = [] as IRequestStatusAuditLog[];
    if (action.payload !== null) {
      requestStatus = action.payload.requestStatus;
      mailboxSSSRequest = action.payload.mailboxSSSRequest;
      lstAuditLogs = action.payload.lstAuditLogs;
    }
    TelemetryService.trackEvent({ name: EVENTS.API_CALL } as IEventTelemetry, {
      API: API_NAMES.GET_REQUEST_STATUS_MAIL_SSS,
      Result: API_STATUS.SUCCESS
    });
    return {
      ...state,
      requestStatusMailboxSSSState: {
        ...state.requestStatusMailboxSSSState,
        requestStatus: requestStatus,
        lstAuditLogs: lstAuditLogs,
        mailboxSSSRequest: mailboxSSSRequest,
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(getRequestStatusMailSSSFail, (state, action) => {
    toastError('Fail to get request status of mailbox sss', action.payload);
    TelemetryService.trackException({
      exception: { message: API_STATUS.FAIL, name: API_NAMES.GET_REQUEST_STATUS_MAIL_SSS } as Error,
      properties: { API: API_NAMES.GET_REQUEST_STATUS_MAIL_SSS, Payload: action.payload },
      severityLevel: SeverityLevel.Error
    } as IExceptionTelemetry);
    return {
      ...state,
      requestStatusMailboxSSSState: {
        ...state.requestStatusMailboxSSSState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    };
  });
