import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ScrollablePane,
  css,
  ShimmeredDetailsList,
  CheckboxVisibility,
  IColumn,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
  Stack,
  IComboBoxOption,
  ActionButton,
  SearchBox,
  Dialog,
  DialogType,
  TextField,
  FontIcon,
  PrimaryButton,
  DefaultButton,
  Modal
} from '@fluentui/react';
import { Text } from '@fluentui/react/lib/Text';
import {
  CoherencePageSize,
  CoherencePagination,
  ICoherencePageSizeProps,
  ICoherencePaginationProps
} from '@coherence-design-system/controls';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import {
  AppsListStyle,
  AppsListPageContentStyles,
  AppsListSearchBarStyle,
  iconClass,
  modalButtonClass,
  modalStyle
} from './AppsList.styles';
import { IApp } from '../../shared/models/AppsList/IAppsRequest';
import { AppsListProps } from './AppsListTypes';
import { renderEveryOtherRow } from '../../shared/utils/fabric/renderEveryOtherRow';
import { _Styles } from '../../shared/styles/Page.styles';
import { telemetryHook } from '../../components/TelemetryService/TelemetryUtils';
import { getMyApps } from '../../shared/store/actions';
import { appListColumns } from './AppsList.columns';
import { isNullOrUndefined } from '../../shared/utils/tools/object';
import { getDeleteAppUrl, httpDelete } from '../../shared/services';
import { toastError, toastSuccess } from '../../shared/utils/tools/toast';
import { ConnectedAppMetadataForm } from '../../components/Forms/ApplicationMetadataForm/ConnectedAppMetadataForm';

// With function component, we dont use a class for local state anymore
export const AppsListPage: React.FC<AppsListProps> = (props) => {
  // Setup Local State
  const dispatch = useDispatch();
  const selectedPage = React.useRef<number>(1);
  const pageCount = React.useRef<number>(0);
  const pageSize = React.useRef<number>(20);
  const totalItemsCount = React.useRef<number>(0);
  const sortedField = React.useRef<string | undefined>();
  const sortedDescending = React.useRef<boolean>(true);
  const [itemsState, setItemsState] = useState<IApp[]>([]);
  const [columnsState, setColumnsState] = useState<IColumn[]>();
  const [selectedItem, setSelectedItem] = useState<IApp | undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [confirmTextFieldValue, setConfirmFieldValue] = useState<string>('');
  const [metadataFormIsVisible, { setTrue: showMetadata, setFalse: hideMetadata }] = useBoolean(false);

  const dialogContentProps = {
    type: DialogType.largeHeader,
    title: 'Delete Application'
  };

  const modalProps = {
    isBlocking: false,
    styles: {
      main: {
        maxWidth: 600
      }
    }
  };

  const toggleHideDialog_IfSelected = () => {
    if (selectedItem !== undefined) {
      toggleHideDialog();
    }
  };

  const onConfirmFieldChange = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setConfirmFieldValue(newValue || '');
  };

  // Selection object which monitors which rows are selected in table
  const selection = new Selection({
    onSelectionChanged: () => {
      // can handle any number of selected, but we know only 1 is allowed right now
      setSelectedItem(selection.getSelection()[0] as IApp);
    }
  });

  const addNewApp = () => {
    // noop
  };

  const showAppInfo = () => {
    // noop
  };

  const editApp = () => {
    if (selectedItem?.appId) {
      showMetadata();
    }
  };

  const deleteApp = () => {
    if (selectedItem !== undefined) {
      const appId = selectedItem.appId;
      httpDelete(getDeleteAppUrl(appId)).subscribe(
        (_) => {
          // success
          toastSuccess('App deleted');
          toggleHideDialog();
          setConfirmFieldValue('');
        },
        (err) => {
          // fail
          toastError('Error deleting app', err);
        }
      );
    }
  };

  const refreshTableData = useCallback((rows: IApp[], searchQuery: string) => {
    const filteredData = rows.filter((app) => {
      return (
        app.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        app.environment.toLowerCase().includes(searchQuery.toLowerCase()) ||
        app.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
        app.appId.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });

    const key = sortedField.current as keyof IApp;
    const sortedData = !isNullOrUndefined(sortedField.current)
      ? filteredData.sort((a, b) => {
          return (sortedDescending.current ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1;
        })
      : filteredData;

    const slicedData = sortedData.slice(
      pageSize.current * (selectedPage.current - 1),
      pageSize.current * selectedPage.current
    );

    pageCount.current = Math.ceil(filteredData.length / pageSize.current);
    setItemsState(slicedData);
  }, []);

  const setPage = (newPageNumber: number): void => {
    if (newPageNumber !== selectedPage.current) {
      // change to new page
      setSelectedItem(undefined);
      selectedPage.current = newPageNumber;
      refreshTableData(props.lstMyAppsRequestState.apps, searchQuery);
    }
  };

  const setPageSize = (newPageSize: string | number): void => {
    if ((newPageSize as number) !== pageSize.current) {
      // unselect if page size is shrinking (TODO: only unselect if selected row would no longer be displayed)
      if ((newPageSize as number) < pageSize.current) {
        setSelectedItem(undefined);
      }
      pageSize.current = newPageSize as number;
      pageCount.current = Math.ceil(totalItemsCount.current / pageSize.current);
      if (selectedPage.current !== 1) {
        setPage(1);
      } else {
        refreshTableData(props.lstMyAppsRequestState.apps, searchQuery);
      }
    }
  };

  /* eslint-disable no-param-reassign */
  const sortByColumn = (
    _: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    column: IColumn | undefined
  ): void => {
    const newColumns = columnsState?.slice();
    const currColumn = newColumns?.filter((currCol) => column?.key === currCol.key)[0];
    newColumns?.forEach((newCol) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        sortedField.current = currColumn.fieldName;
        sortedDescending.current = currColumn.isSortedDescending;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    setColumnsState(newColumns);
    if (selectedPage.current !== 1) {
      setPage(1);
    }
  };
  /* eslint-disable no-param-reassign */

  const setSearchQuery_Wrapper = (searchQuery: string) => {
    setSearchQuery(searchQuery);
    setPage(1);
  };

  // Prop sets for coherence pagination controls
  const paginationProps: ICoherencePaginationProps = {
    pageCount: pageCount.current,
    selectedPage: selectedPage.current,
    previousPageAriaLabel: 'previous page',
    nextPageAriaLabel: 'next page',
    inputFieldAriaLabel: 'page number',
    onPageChange: setPage,
  };

  // Prop sets for coherence pagination controls
  const paginationPageSizeProps: ICoherencePageSizeProps = {
    pageSize: pageSize.current,
    pageSizeList: [
      { key: 10, text: '10' },
      { key: 20, text: '20' },
      { key: 30, text: '30' },
      { key: 40, text: '40' },
      { key: 50, text: '50' }
    ] as IComboBoxOption[],
    comboBoxAriaLabel: 'select page size',
    onPageSizeChange: setPageSize,
    telemetryHook: telemetryHook
  };

  // Load data on initial page load, set up column
  useEffect(() => {
    dispatch(getMyApps()); // dispatch action to fetch data from api
    setColumnsState(appListColumns); // configure what columns are shown
  }, [dispatch]); // <- dependency only on dispatch, doesnt re-trigger on component re-render (hopefully?)

  // Whenever data, page size, or selected page change -> refresh the table
  // This is debounced by the way
  useEffect(() => {
    totalItemsCount.current = props.lstMyAppsRequestState.apps.length;
    const timeoutId = setTimeout(() => refreshTableData(props.lstMyAppsRequestState.apps, searchQuery), 200);
    return () => clearTimeout(timeoutId);
  }, [props.lstMyAppsRequestState.apps, searchQuery, columnsState, refreshTableData]);

  return (
    <div>
      <ScrollablePane className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}>
        <main data-automation-id="AppsList" className={css(AppsListStyle.pageBackground)} tabIndex={-1}>
          <ActionButton iconProps={{ iconName: 'Add' }} allowDisabledFocus={false} onClick={addNewApp}>
            New
          </ActionButton>

          <ActionButton
            iconProps={{ iconName: 'Info' }}
            allowDisabledFocus={false}
            onClick={showAppInfo}
            disabled={selectedItem?.appId === undefined}
          >
            Details
          </ActionButton>

          <ActionButton
            iconProps={{ iconName: 'Edit' }}
            allowDisabledFocus={false}
            onClick={editApp}
            disabled={selectedItem?.appId === undefined}
          >
            Edit
          </ActionButton>

          <ActionButton
            iconProps={{ iconName: 'Delete' }}
            allowDisabledFocus={false}
            onClick={toggleHideDialog_IfSelected}
            disabled={selectedItem?.appId === undefined}
          >
            Delete
          </ActionButton>

          <div style={{ display: 'inline-block', float: 'right', marginRight: '37px' }}>
            <SearchBox
              placeholder="Search"
              className="searchBox"
              styles={AppsListSearchBarStyle}
              onChange={(_, searchQuery) => setSearchQuery_Wrapper(searchQuery || '')}
            />
          </div>

          <Dialog
            hidden={hideDialog}
            onDismiss={toggleHideDialog}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
          >
            <div style={{ display: 'flex', backgroundColor: 'lightyellow', padding: '5px 5px 5px 5px' }}>
              <FontIcon aria-label="Warning" iconName="Warning" className={iconClass} />
              <div>
                <Text>
                  Warning! Deleting the selected app will uninstall it for all users. The app will be deleted but any
                  shared connections and flows called by the app will not be deleted. This action cannot be undone.
                </Text>
              </div>
            </div>
            <TextField
              label="Confirmation"
              placeholder='Type "yes" to confirm.'
              value={confirmTextFieldValue}
              onChange={onConfirmFieldChange}
            />
            <PrimaryButton
              text="Delete"
              onClick={deleteApp}
              className={modalButtonClass}
              disabled={confirmTextFieldValue !== 'yes'}
            />
            <DefaultButton text="Cancel" onClick={toggleHideDialog} className={modalButtonClass} />
          </Dialog>

          <PageHeader pageTitle={'My Applications'} />

          <ShimmeredDetailsList
            setKey={selectedItem === undefined ? '' : 'appId'}
            ariaLabelForShimmer="Loading Data"
            checkButtonAriaLabel="Select this App"
            ariaLabelForListHeader="App list header"
            ariaLabelForSelectAllCheckbox="Select all Apps (do not do this)"
            ariaLabel="List of apps owned by you"
            ariaLabelForGrid={'My Owned Apps List'}
            checkboxVisibility={CheckboxVisibility.onHover}
            className={AppsListPageContentStyles.table}
            columns={columnsState}
            enableShimmer={props.lstMyAppsRequestState.isLoading}
            isHeaderVisible={true}
            items={itemsState}
            layoutMode={DetailsListLayoutMode.justified}
            onColumnHeaderClick={sortByColumn}
            onRenderRow={renderEveryOtherRow}
            selection={selection}
            selectionMode={SelectionMode.single}
            selectionPreservedOnEmptyClick={true}
          />

          <Stack horizontal horizontalAlign="center">
            <Stack horizontal horizontalAlign="center">
              <CoherencePagination {...paginationProps} />
              <CoherencePageSize {...paginationPageSizeProps} />
            </Stack>
          </Stack>
        </main>
      </ScrollablePane>
      <Modal
        titleAriaId={useId('title')}
        isOpen={metadataFormIsVisible}
        onDismiss={hideMetadata}
        containerClassName={modalStyle.container}
      >
        <div className={modalStyle.body}>
          <ConnectedAppMetadataForm appId={selectedItem?.appId} />
        </div>
      </Modal>
    </div>
  );
};
