import React from 'react';
import { IColumn } from '@fluentui/react';
import { dateTimeToLocalString } from '../../shared/utils/tools/string';
import { IApp } from '../../shared/models/AppsList/IAppsRequest';

export const appListColumns: IColumn[] = [
  {
    key: 'createdDate',
    name: 'Created Date',
    fieldName: 'createdDate',
    headerClassName: 'tbl-header',
    minWidth: 80,
    maxWidth: 140,
    isResizable: true,
    onRender: (item: IApp) => {
      return <div>{dateTimeToLocalString(item.createdDate)}</div>;
    }
  },
  {
    key: 'appId',
    name: 'AppId',
    fieldName: 'appId',
    headerClassName: 'tbl-header',
    minWidth: 80,
    maxWidth: 240,
    isResizable: true,
    onRender: (item: IApp) => {
      return <div>{item.appId}</div>;
    }
  },
  {
    key: 'name',
    name: 'Name',
    fieldName: 'name',
    headerClassName: 'tbl-header',
    minWidth: 80,
    maxWidth: 240,
    isResizable: true,
    onRender: (item: IApp) => {
      return <div>{item.name}</div>;
    }
  },
  {
    key: 'environment',
    name: 'Environment',
    fieldName: 'environment',
    headerClassName: 'tbl-header',
    minWidth: 80,
    maxWidth: 240,
    isResizable: true,
    onRender: (item: IApp) => {
      return <div>{item.environment}</div>;
    }
  },
  {
    key: 'description',
    name: 'Description',
    fieldName: 'description',
    headerClassName: 'tbl-header',
    minWidth: 80,
    maxWidth: 240,
    isResizable: true,
    onRender: (item: IApp) => {
      return <div>{item.description}</div>;
    }
  }
];
