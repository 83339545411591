import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IRootState } from '../../../shared/store/IRootState';
import {
  RequestStatusCreateMailSSSMappedProps,
  RequestStatusCreateMailSSSActions
} from './RequestStatusCreateMailSSS.types';
import { RequestStatusCreateMailSSS } from './RequestStatusCreateMailSSS';
import { getRequestStatusMailSSS } from '../../../shared/store/actions';

// Reducer States
const mapStateToProps = (
  state: IRootState
  // _ownProps: ConnectedRequestStatusCreateMailSSSProps
): RequestStatusCreateMailSSSMappedProps => {
  const { requestStatusMailboxSSSState } = state;
  return {
    requestStatusMailboxSSSState: requestStatusMailboxSSSState
  };
};

// Actions
const mapDispatchToProps = (): RequestStatusCreateMailSSSActions => {
  return {
    getRequestStatusMailSSS: (requestId: string) => getRequestStatusMailSSS(requestId)
  };
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export const ConnectedRequestStatusCreateMailSSS = withRouter(
  connect(mapStateToProps, mapDispatchToProps())(RequestStatusCreateMailSSS)
);
