import { ICommandBarStyles, IPivotStyles, IStyle, mergeStyleSets } from '@fluentui/react';

export const RequestStatusCreateMailSSSPageStyle = mergeStyleSets({
  pageBackground: {
    selectors: {
      ':global(.ms-DetailsList)': {
        overflow: 'visible'
      }
    }
  } as IStyle
});

export const RequestStatusCreateMailSSSPageCommandBarStyle = {
  // root: { backgroundColor: CoherenceTheme.palette.neutralPrimaryAlt }
} as ICommandBarStyles;

export const RequestStatusCreateMailSSSPivotStyle = {
  root: {
    marginLeft: '2%'
  }
} as IPivotStyles;

export const RequestStatusCreateMailSSSPageContentStyles = mergeStyleSets({
  filter: {
    marginLeft: '3%',
    width: '94%',
    marginBottom: '5px'
  },
  table: {
    height: '69vh',
    marginLeft: '3%',
    width: '94%',
    overflowX: 'visible'
  },
  detailInfo: {
    marginTop: '1.5%',
    marginLeft: '4%'
  },
  detailLine: {},
  detailLabel: {
    display: 'inline-block',
    fontWeight: 'bolder',
    width: '30%'
  },
  detailData: {
    display: 'inline-block'
  }
});
