import { createAction } from 'typesafe-actions';
import { IHttpError } from '../../../models/IHttpError';
import { IRequestStatusMailboxSSS } from '../../../models/myRequestStatus/IRequestStatusMailboxSSS';
import {
  GET_REQUEST_STATUS_Mailbox_SSS,
  GET_REQUEST_STATUS_Mailbox_SSS_FAIL,
  GET_REQUEST_STATUS_Mailbox_SSS_SUCCESS
} from '../../constants';

export const getRequestStatusMailSSS = createAction(
  GET_REQUEST_STATUS_Mailbox_SSS,
  (requestId: string) => requestId
)<string>();

export const getRequestStatusMailSSSSuccess = createAction(
  GET_REQUEST_STATUS_Mailbox_SSS_SUCCESS,
  (requestStatusMailboxSSS: IRequestStatusMailboxSSS) => requestStatusMailboxSSS
)<IRequestStatusMailboxSSS>();

export const getRequestStatusMailSSSFail = createAction(
  GET_REQUEST_STATUS_Mailbox_SSS_FAIL,
  (err: IHttpError) => err
)<IHttpError>();
