export const ATTESTATION = {
  ERROR: {
    ATTESTAION_CONFIRMATION: 'Please check the confirmation checkbox before submitting attestation.'
  },
  SECTION: {
    COMPLIANCE: 'Compliance',
    OWNERSHIP: 'Ownership',
    INFORMATION: 'Environment Information'
  }
};
