import { createReducer } from 'typesafe-actions';
import { IEventTelemetry, IExceptionTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import {
  completeListApprvalMailboxSSSRequestsState,
  ensureSystemAdmin,
  ensureSystemAdminFail,
  ensureSystemAdminSuccess,
  initListApprvalMailboxSSSRequestsState,
  userEmailApproval,
  userEmailApprovalFail,
  userEmailApprovalSuccess
} from '../../actions';
import { toastError, toastSuccess } from '../../../utils/tools/toast';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { telemetryConfig } from '../../../../components/TelemetryService/TelemetryConfig';
import { API_NAMES, API_STATUS, EVENTS } from '../../../constants';

TelemetryService.initialize(telemetryConfig);

export const listApprvalMailboxSSSRequestsReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(initListApprvalMailboxSSSRequestsState, (state, action) => {
    const lstState = action.payload;
    return {
      ...state,
      lstApprvalMailboxSSSRequestsState: {
        ...state.lstApprvalMailboxSSSRequestsState,
        isLoading: true,
        loadingState: LoadingState.loading,
        lstApprovalMailboxSSSRequests: lstState.lstApprovalMailboxSSSRequests,
        isApproved: lstState.isApproved,
        curRequest: lstState.curRequest,
        countSuccess: lstState.countSuccess,
        countFail: lstState.countFail,
        curRequestEnsureSystemAdmin: lstState.curRequestEnsureSystemAdmin,
        curRequestUserEmailApproval: lstState.curRequestUserEmailApproval
      }
    };
  })
  .handleAction(ensureSystemAdmin, (state, action) => {
    return {
      ...state,
      lstApprvalMailboxSSSRequestsState: {
        ...state.lstApprvalMailboxSSSRequestsState,
        curRequest: action.payload.curRequest,
        curRequestEnsureSystemAdmin: {
          ...state.lstApprvalMailboxSSSRequestsState.curRequestEnsureSystemAdmin,
          isLoading: true,
          loadingState: LoadingState.loading
        },
        curRequestUserEmailApproval: {
          ...state.lstApprvalMailboxSSSRequestsState.curRequestUserEmailApproval,
          isLoading: false,
          loadingState: LoadingState.notLoaded
        }
      }
    };
  })
  .handleAction(ensureSystemAdminSuccess, (state) => {
    // let lstApprovalMailboxSSSRequests = [...state.lstApprvalMailboxSSSRequestsState.lstApprovalMailboxSSSRequests];
    // let approvalRequest = lstApprovalMailboxSSSRequests[state.lstApprvalMailboxSSSRequestsState.curRequest];
    // approvalRequest.isLoading = false;
    // approvalRequest.loadingState = LoadingState.loaded;
    // lstApprovalMailboxSSSRequests[state.lstApprvalMailboxSSSRequestsState.curRequest] = approvalRequest;
    TelemetryService.trackEvent({ name: EVENTS.API_CALL } as IEventTelemetry, {
      API: API_NAMES.ENSURE_SYSTEM_ADMIN,
      Result: API_STATUS.SUCCESS
    });
    return {
      ...state,
      lstApprvalMailboxSSSRequestsState: {
        ...state.lstApprvalMailboxSSSRequestsState,
        // lstApprovalMailboxSSSRequests: lstApprovalMailboxSSSRequests,
        curRequestEnsureSystemAdmin: {
          ...state.lstApprvalMailboxSSSRequestsState.curRequestEnsureSystemAdmin,
          isLoading: false,
          loadingState: LoadingState.loaded
        }
      }
    };
  })
  .handleAction(ensureSystemAdminFail, (state, action) => {
    if (action.payload.status === 401 || action.payload.status === 403) {
      toastError('You are not authorize to approval Mailbox SSS Request.', action.payload);
    } else {
      toastError('Fail to ensure user as system admin', action.payload);
    }
    const lstApprovalMailboxSSSRequests = [...state.lstApprvalMailboxSSSRequestsState.lstApprovalMailboxSSSRequests];
    const approvalRequest = lstApprovalMailboxSSSRequests[state.lstApprvalMailboxSSSRequestsState.curRequest];
    approvalRequest.isLoading = false;
    approvalRequest.loadingState = LoadingState.error;
    approvalRequest.loadingError = action.payload;
    lstApprovalMailboxSSSRequests[state.lstApprvalMailboxSSSRequestsState.curRequest] = approvalRequest;
    TelemetryService.trackException({
      exception: { message: API_STATUS.FAIL, name: API_NAMES.ENSURE_SYSTEM_ADMIN } as Error,
      properties: { API: API_NAMES.ENSURE_SYSTEM_ADMIN, Payload: action.payload },
      severityLevel: SeverityLevel.Error
    } as IExceptionTelemetry);
    return {
      ...state,
      lstApprvalMailboxSSSRequestsState: {
        ...state.lstApprvalMailboxSSSRequestsState,
        lstApprovalMailboxSSSRequests: lstApprovalMailboxSSSRequests,
        countFail: state.lstApprvalMailboxSSSRequestsState.countFail + 1,
        curRequestEnsureSystemAdmin: {
          ...state.lstApprvalMailboxSSSRequestsState.curRequestEnsureSystemAdmin,
          isLoading: false,
          loadingState: LoadingState.error,
          loadingError: action.payload
        }
      }
    };
  })
  .handleAction(userEmailApproval, (state, action) => {
    return {
      ...state,
      lstApprvalMailboxSSSRequestsState: {
        ...state.lstApprvalMailboxSSSRequestsState,
        curRequest: action.payload.curRequest,
        curRequestEnsureSystemAdmin: {
          ...state.lstApprvalMailboxSSSRequestsState.curRequestEnsureSystemAdmin,
          isLoading: false,
          loadingState: LoadingState.notLoaded
        },
        curRequestUserEmailApproval: {
          ...state.lstApprvalMailboxSSSRequestsState.curRequestUserEmailApproval,
          isLoading: true,
          loadingState: LoadingState.loading
        }
      }
    };
  })
  .handleAction(userEmailApprovalSuccess, (state) => {
    const isApproval = state.lstApprvalMailboxSSSRequestsState.isApproved;
    const request =
      state.lstApprvalMailboxSSSRequestsState.lstApprovalMailboxSSSRequests[
        state.lstApprvalMailboxSSSRequestsState.curRequest
      ];
    toastSuccess(`Success to ${isApproval ? 'approve' : 'reject'} mailbox: ${request.mailboxEmail}`);
    TelemetryService.trackEvent({ name: EVENTS.API_CALL } as IEventTelemetry, {
      API: API_NAMES.USER_EMAIL_APPROVAL,
      Result: API_STATUS.SUCCESS
    });
    return {
      ...state,
      lstApprvalMailboxSSSRequestsState: {
        ...state.lstApprvalMailboxSSSRequestsState,
        countSuccess: state.lstApprvalMailboxSSSRequestsState.countSuccess + 1,
        curRequestUserEmailApproval: {
          ...state.lstApprvalMailboxSSSRequestsState.curRequestUserEmailApproval,
          isLoading: false,
          loadingState: LoadingState.loaded
        }
      }
    };
  })
  .handleAction(userEmailApprovalFail, (state, action) => {
    toastError('Fail to apprval user email router', action.payload);
    const lstApprovalMailboxSSSRequests = [...state.lstApprvalMailboxSSSRequestsState.lstApprovalMailboxSSSRequests];
    const approvalRequest = lstApprovalMailboxSSSRequests[state.lstApprvalMailboxSSSRequestsState.curRequest];
    approvalRequest.isLoading = false;
    approvalRequest.loadingState = LoadingState.error;
    approvalRequest.loadingError = action.payload;
    lstApprovalMailboxSSSRequests[state.lstApprvalMailboxSSSRequestsState.curRequest] = approvalRequest;
    TelemetryService.trackException({
      exception: { message: API_STATUS.FAIL, name: API_NAMES.USER_EMAIL_APPROVAL } as Error,
      properties: { API: API_NAMES.USER_EMAIL_APPROVAL, Payload: action.payload },
      severityLevel: SeverityLevel.Error
    } as IExceptionTelemetry);
    return {
      ...state,
      lstApprvalMailboxSSSRequestsState: {
        ...state.lstApprvalMailboxSSSRequestsState,
        lstApprovalMailboxSSSRequests: lstApprovalMailboxSSSRequests,
        countFail: state.lstApprvalMailboxSSSRequestsState.countFail + 1,
        curRequestUserEmailApproval: {
          ...state.lstApprvalMailboxSSSRequestsState.curRequestUserEmailApproval,
          isLoading: false,
          loadingState: LoadingState.error,
          loadingError: action.payload
        }
      }
    };
  })
  .handleAction(completeListApprvalMailboxSSSRequestsState, (state) => {
    return {
      ...state,
      lstApprvalMailboxSSSRequestsState: {
        ...state.lstApprvalMailboxSSSRequestsState,
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  });
