import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { CoherenceTheme, MessagingColors } from '@coherence-design-system/styles';
import { ThemeProvider, mergeStyles } from '@fluentui/react';
import { ToastContainer } from 'react-toastify';
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import { IEventTelemetry } from '@microsoft/applicationinsights-common/types/Interfaces/IEventTelemetry';
import { useSelector } from 'react-redux';
import { EnvironmentRequestPage, HomePage } from '../pages';
import { Header } from '../components/Header';
import { ConnectedMailboxSSSGAApproval } from '../pages/MailboxSSSGAApproval/ConnectedMailboxSSSGAApproval';
import { TelemetryService } from '../components/TelemetryService/TelemetryService';
import { telemetryConfig } from '../components/TelemetryService/TelemetryConfig';
import 'react-toastify/dist/ReactToastify.css';
import { ConnectedNavigation } from '../components/Navigation/ConnectedNavigation';
import { ConnectedMailboxSSSRequest } from '../pages/MailboxSSSRequest/ConnectedMailboxSSSRequest';
import { ConnectedListMyRequestStatus } from '../pages/MyRequestStatus/ListMyRequestStatus/ConnectedListMyRequestStatus';
import { ConnectedRequestStatusCreateMailSSS } from '../pages/MyRequestStatus/RequestStatusCreateMailSSS/ConnectedRequestStatusCreateMailSSS';
import { ConnectedRequestStatusEnvironment } from '../pages/MyRequestStatus/RequestStatusEnvironment/ConnectedRequestStatusEnvironment';
import { ConnectedAppsList } from '../pages/AppsList/ConnectedAppsList';
import { AppProps } from './App.types';
import { APPS_LIST_FF, ENV_LIST_FF, ENV_REQUEST_FF, MAILBOX_FORM_VIEW, SPS_LIST_FF } from '../shared/store/constants';
import { ConnectedEnvironmentList } from '../pages/PowerPlatform/Environments/ConnectedEnvironmentList';
import { ConnectedEnvironmentViolation } from '../pages/PowerPlatform/Environments/Violation/ConnectedEnvironmentViolation';
import { msalInstance } from '..';
import { EVENTS } from '../shared/constants';
import { getCachedUser } from '../msalConfig';
import { Environments } from '../pages/PowerPlatform/Environments/Environments';
import { SharepointSites } from '../pages/SharedWorkspaces/SharepointSites/SharePointSites';
import { SharePointViolation } from '../pages/SharedWorkspaces/SharepointSites/Violation/SharePointViolation';
import { IRootState } from '../shared/store/IRootState';

export const App: React.FC<AppProps> = (props) => {

  TelemetryService.initialize(telemetryConfig);
  const [isNavCollapsed, setIsNavCollapsed] = useState<boolean>(props.isNavCollapsed);
  const [user, setUser] = useState<AccountInfo | null>(null);

  const request = {
    scopes: ['User.Read']
  };

useEffect(()=>{
    // Redirect people landing on old url page to new url
    if (
      window.location.href.includes('onegovernanceui.microsoft.com') ||
      window.location.href.includes('onegovernanceui.azurewebsites.net')
    ) {
      window.location.replace(
        window.location.href
          .replace('onegovernanceui.azurewebsites.net', 'buildershub.microsoft.com')
          .replace('onegovernanceui.microsoft.com', 'buildershub.microsoft.com')
      );
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

useEffect(() => {
  if (user) {
    props.getFeatureFlags();
    props.getPolicies();
    props.getSharepointPolicies();
    TelemetryService.trackEvent({ name: EVENTS.USER_LOGIN } as IEventTelemetry, {
      username: getCachedUser()?.name
    });
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [user]);

useEffect(() => {
  // eslint-disable-next-line @typescript-eslint/require-await
  const checkLoginStatus = async (result: AuthenticationResult | null) => {
    if (result && !result.account) {
      console.log('Login failed');
      return;
    }

    if (result?.account && result?.accessToken) {
      const accountInfo = result?.account;
      const storedData = JSON.stringify({
        accessToken: result.accessToken,
        account: accountInfo
      });
      sessionStorage.setItem('msalStoredData', storedData);
    }

    setUser(result?.account ?? null);
  };

  const storedData = sessionStorage.getItem('msalStoredData');

  if (storedData) {
    const parsedData = JSON.parse(storedData);
    setUser(parsedData.account);
  } else {
    msalInstance.handleRedirectPromise().then(checkLoginStatus).catch(checkLoginStatus);

    const login = async () => {
      try {
        await msalInstance.loginRedirect(request);
      } catch (error) {
        console.log('Login failed', error);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    login();
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

const _onNavCollapsed = (isCollapsed: boolean) => {
  setIsNavCollapsed(isCollapsed);
}

    mergeStyles({
      selectors: {
        ':global(.Toastify__toast--warning)': {
           background: `${MessagingColors.warning} !important`
        },
        ':global(.Toastify__toast--error)': {
           background: `${MessagingColors.errorBlockIcon} !important`
        },
        ':global(.Toastify__toast--success)': {
           background: `${MessagingColors.successIcon} !important`
        },
        ':global(.Toastify__close-button:focus)': {
          outline: `-webkit-focus-ring-color auto 1px !important`
        }
      }
    });

    if (!user) {
      return null;
    }

    return (
      <ThemeProvider theme={CoherenceTheme}>
        <div className="App">
          <Header />
          <BrowserRouter>
            <ConnectedNavigation
              onNavCollapsed={_onNavCollapsed}
              featureFlags={props.featureFlagsState.features}
            />
            <main id="main" tabIndex={-1}>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => (
                    <HomePage
                      isNavCollapsed={isNavCollapsed}
                      featureFlags={props.featureFlagsState.features}
                    />
                  )}
                />
                {props.featureFlagsState.features?.[ENV_REQUEST_FF] && (
                  <Route
                    path="/environment-request"
                    render={() => <EnvironmentRequestPage isNavCollapsed={isNavCollapsed} />}
                  />
                )}
                {props.featureFlagsState.features?.[APPS_LIST_FF] && (
                  <Route
                    path="/appslist"
                    render={() => <ConnectedAppsList isNavCollapsed={isNavCollapsed} />}
                  />
                )}
                <Route
                  path="/list-request-status/:pageNum?"
                  render={() => <ConnectedListMyRequestStatus isNavCollapsed={isNavCollapsed} />}
                />
                <Route
                  path="/power-platform/environments/:environmentId/violations"
                  render={() => <ConnectedEnvironmentViolation isNavCollapsed={isNavCollapsed}/>}
                />
                <Route
                    path="/shared-workspaces/sharepointsites/:sharePointId/violations"
                    render={() => <SharePointViolation isNavCollapsed={isNavCollapsed} />}
                  />
                {props.featureFlagsState.features?.[ENV_LIST_FF] && (
                  <Route
                    path="/power-platform/environments"
                    render={() => <ConnectedEnvironmentList isNavCollapsed={isNavCollapsed} />}
                  />
                )}
                 {props.featureFlagsState.features?.[SPS_LIST_FF] && (
                  <Route
                    path="/shared-workspaces/sharepointsites"
                    render={() => <SharepointSites isNavCollapsed={isNavCollapsed}/>}
                  />
                )}
                <Route
                  path="/request-status-mailbox-sss/:requestId?"
                  render={() => <ConnectedRequestStatusCreateMailSSS isNavCollapsed={isNavCollapsed} />}
                />
                <Route
                  path="/request-status-environment/:requestId?"
                  render={() => <ConnectedRequestStatusEnvironment isNavCollapsed={isNavCollapsed} />}
                />
                {props.featureFlagsState.features?.[MAILBOX_FORM_VIEW] && (
                <Route
                  path="/mailbox-request/:actionType"
                  component={ConnectedMailboxSSSRequest}
                  render={() => <ConnectedMailboxSSSRequest isNavCollapsed={isNavCollapsed} />}
                />)}
                <Route
                  path="/mailbox-sss-ga-approval"
                  render={() => <ConnectedMailboxSSSGAApproval isNavCollapsed={isNavCollapsed} />}
                />
                {/*
                <Route path="/apps" render={() => <Apps isNavCollapsed={isNavCollapsed} />} />
                 <Route
                  path="/one-access-report/app-admin"
                  render={() => <ConnectedOneAccessReport isNavCollapsed={isNavCollapsed} />}
                />
                <Route
                  path="/one-access-report/security-owner"
                  render={() => <ConnectedOneAccessReport isNavCollapsed={isNavCollapsed} />}
                />
                <Route
                  path="/one-access-report/compliance-manager"
                  render={() => <ConnectedOneAccessReport isNavCollapsed={isNavCollapsed} />}
                />
                <Route
                  path="/one-access-report/one-finance"
                  render={() => <ConnectedOneAccessReport isNavCollapsed={isNavCollapsed} />}
                />
                <Route
                  path="/one-access-report/realtime-report"
                  render={() => <ConnectedOneAccessReport isNavCollapsed={isNavCollapsed} />}
                />
                <Route
                  path="/one-access-report/ccm-delta"
                  render={() => <ConnectedOneAccessReport isNavCollapsed={isNavCollapsed} />}
                />
                <Route
                  path="/one-access-revoke/:revokeType/:appId/:id"
                  component={ConnectedOneAccessRevoke}
                  render={() => <ConnectedOneAccessRevoke isNavCollapsed={isNavCollapsed} />}
                /> */}
              </Switch>
            </main>
          </BrowserRouter>
          <ToastContainer role="alert" style={{ marginTop: 58, marginRight: 8 }} />
        </div>
      </ThemeProvider >
    );
  }

export default App;
