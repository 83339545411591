import * as React from 'react';
import { FormOptions } from '../FormFieldUtil';
import { FormModel } from '../FormModel';
import { FormCheckbox } from '../Items/FormCheckbox';
import { FormDropdown } from '../Items/FormDropdown';
import { FormPeoplePicker } from '../Items/FormPeoplePicker';
import { FormTextField } from '../Items/FormTextField';
import { FormServiceTreePicker } from '../Items/FormServiceTreePicker';

export const EnvironmentRequestFormModel: FormModel = {
  formId: 'environmentRequestForm',
  formField: {
    requestorEmail: {
      component: FormTextField,
      label: 'Requester',
      name: 'requestorEmail',
      required: true
    } as FormOptions,
    displayName: {
      component: FormTextField,
      label: 'Display Name',
      name: 'displayName',
      required: true,
      tooltip: `This will be the name of the environment.`
    } as FormOptions,
    owningTeamGuid: {
      component: FormServiceTreePicker,
      label: 'Owning Team',
      name: 'owningTeamGuid',
      required: true,
      tooltip: `Select the organization the engineering team owning the environment belongs to. You may search by Division or Organization name. Note, this field will not provide anyone with access to the environment.`
    } as FormOptions,
    primaryFTEContacts: {
      component: FormPeoplePicker,
      label: 'Additional Environment Admins',
      name: 'primaryFTEContacts',
      required: true,
      tooltip: `A minimum of one FTEs is required including requestor. Mentioned user will be added as a environment admin for the new environment.`
    } as FormOptions,
    bussinessTeamGuid: {
      component: FormServiceTreePicker,
      label: 'Business Sponsor',
      name: 'bussinessTeamGuid',
      required: false,
      tooltip: `If the application or service this environment will be used for has a business sponsor, enter it here.`
    } as FormOptions,
    serviceTree: {
      component: FormServiceTreePicker,
      label: 'ServiceTree ID',
      name: 'serviceTreeId',
      required: false,
      tooltip:
        'Enter the ServiceTree Component or Service ID associated with this environment, if there is one. This is required if you indicated that your environment is in scope for any regulatory items above.'
    } as FormOptions,
    businessJustification: {
      component: FormTextField,
      label: 'Business Justification',
      name: 'businessJustification',
      required: true,
      tooltip: 'Environments must be created for business purposes only, and cannot be used for demos, proof of concepts or Hello World environments. For information about creating a demo tenant, visit aka.ms/MSDigitalTenant.'
    } as FormOptions,
    environmentDescription: {
      component: FormTextField,
      label: 'Environment Description',
      name: 'environmentDescription',
      tooltip: 'Provide the description of the environment.',
      required: true
    } as FormOptions,
    environmentType: {
      component: FormDropdown,
      label: 'Scenario',
      name: 'environmentType',
      required: true,
      tooltip: `Pick the smallest environment that meets your environment's needs. This can be upgraded later.`
    } as FormOptions,
    environmentPurpose: {
      component: FormDropdown,
      label: 'Environment Purpose',
      name: 'environmentPurpose',
      required: true
    } as FormOptions,
    sku: {
      component: FormDropdown,
      label: 'Environment Type',
      name: 'sku',
      required: true
    } as FormOptions,
    requestedDLPPolicy: {
      component: FormDropdown,
      label: 'DLP Policy',
      name: 'requestedDLPPolicy',
      required: true,
      tooltip: 'For information on DLP policy options, visit aka.ms/MSPowerPlatformWiki.'
    } as FormOptions,
    CDSidentifier: {
      component: FormTextField,
      label: 'Environment URL',
      name: 'CDSidentifier',
      required: true
    } as FormOptions,
    region: {
      component: FormDropdown,
      label: 'Region',
      name: 'region',
      required: true,
      tooltip: `Note the United States has two flavors of environments: Preview and Production. The preview environment gets new releases earlier than a standard production environment.`
    } as FormOptions,
    currency: {
      component: FormDropdown,
      label: 'Currency',
      name: 'currency',
      required: true
    } as FormOptions,
    language: {
      component: FormDropdown,
      label: 'Language',
      name: 'language',
      required: true
    } as FormOptions,
    existingSecurityGroup: {
      component: FormTextField,
      label: 'Existing Security Group',
      name: 'existingSecurityGroup',
      required: false,
      tooltip: `Use this field to limit the users that can be provisioned to the environment. Provide the Security Group email address.`
    } as FormOptions,
    dataClassification: {
      component: FormDropdown,
      label: 'Data Classification',
      name: 'dataClassification',
      required: true,
      tooltip: 'For information on Data Classification Labels, visit aka.ms/classification.'
    } as FormOptions,
    hipaa: {
      component: FormCheckbox,
      label: 'HIPAA Data',
      name: 'hipaa',
      required: true
    } as FormOptions,
    gdpr: {
      component: FormCheckbox,
      label: 'Personal (GDPR) Data',
      name: 'gdpr',
      required: true,
      tooltip: 'For information on privacy policies of Microsoft, GDPR and personal data, visit aka.ms/NGP.'
    } as FormOptions,
    tentedInformation: {
      component: FormCheckbox,
      label: 'Tented or Sensitive Information',
      name: 'tentedInformation',
      required: true
    } as FormOptions,
    soxApplication: {
      component: FormCheckbox,
      label: 'SOX Applications',
      name: 'soxApplication',
      required: true
    } as FormOptions
  }
};
