import { ILoading, LoadingState } from '../ILoading';
import { GraphObjectTypes } from './GraphObjectTypes';
import { IGraphObjectInfo } from './IGraphObjectInfo';
import { IQueryToSearchGraphObjectsInfo } from './IQueryToSearchGraphObjectsInfo';

export interface ISearchGraphObjectsInfo extends IQueryToSearchGraphObjectsInfo, ILoading {
  graphObjectsInfo: IGraphObjectInfo[];
  isByPage: boolean;
  iPageNum: number;
}

export const emptySearchGraphObjectsInfo: ISearchGraphObjectsInfo = {
  keyWords: '',
  objectType: GraphObjectTypes.User,
  graphObjectsInfo: [] as IGraphObjectInfo[],
  isLoading: false,
  loadingState: LoadingState.notLoaded
} as ISearchGraphObjectsInfo;
