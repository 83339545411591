import { createAction } from 'typesafe-actions';
import {
  GET_FEATURE_FLAGS,
  GET_FEATURE_FLAGS_FAIL,
  GET_FEATURE_FLAGS_SUCCESS
} from '../../constants';
import { IHttpError } from '../../../models/IHttpError';

export const getFeatureFlags = createAction(GET_FEATURE_FLAGS, () => {})();
export const getFeatureFlagsFailed = createAction(GET_FEATURE_FLAGS_FAIL, (err: IHttpError) => err)<IHttpError>();
export const getFeatureFlagsSucceeded = createAction(GET_FEATURE_FLAGS_SUCCESS, 
  (features: {[feature: string]: boolean}) => features)<{[feature: string]: boolean}>();
