// NOTE
// DO NOT USE dynamic string operations(like template string) as action type property.
// see more details: https://github.com/piotrwitek/typesafe-actions#--the-actions
export * from './featureFlags';

export const GET_LIST_MAILBOX_SSS_REQUESTS = '@@mailboxSSS/getListMailboxSSSRequests/GET';
export const GET_LIST_MAILBOX_SSS_REQUESTS_SUCCESS = '@@mailboxSSS/getListMailboxSSSRequests/SUCCESS';
export const GET_LIST_MAILBOX_SSS_REQUESTS_FAIL = '@@mailboxSSS/getListMailboxSSSRequests/FAIL';

export const INIT_LIST_APPROVAL_MAILBOX_SSS_REQUESTS_STATE = '@@mailboxSSS/listApprvalMailboxSSSRequestsState/INIT';
export const COMPLETE_LIST_APPROVAL_MAILBOX_SSS_REQUESTS_STATE =
  '@@mailboxSSS/listApprvalMailboxSSSRequestsState/COMPLETE';

export const ENSURE_SYSTEM_ADMIN = '@@mailboxSSS/ensureSystemAdmin/GET';
export const ENSURE_SYSTEM_ADMIN_SUCCESS = '@@mailboxSSS/ensureSystemAdmin/SUCCESS';
export const ENSURE_SYSTEM_ADMIN_FAIL = '@@mailboxSSS/ensureSystemAdmin/FAIL';

export const USER_EMAIL_APPROVAL = '@@mailboxSSS/userEmailApproval/GET';
export const USER_EMAIL_APPROVAL_SUCCESS = '@@mailboxSSS/userEmailApproval/SUCCESS';
export const USER_EMAIL_APPROVAL_FAIL = '@@mailboxSSS/userEmailApproval/FAIL';

export const CHANGE_NOTIFICATION = '@@notification/change';

export const ONE_ACCESS_REVOKE_ASSIGNMENT = '@@oneAccessView/oneAccessRevokeAssignment/DELETE';
export const ONE_ACCESS_REVOKE_ASSIGNMENT_SUCCESS = '@@oneAccessView/oneAccessRevokeAssignment/SUCCESS';
export const ONE_ACCESS_REVOKE_ASSIGNMENT_FAIL = '@@oneAccessView/oneAccessRevokeAssignment/FAIL';

export const SUBMIT_ENVIRONMENT_REQUEST = '@@environment/submitEnvironmentRequest/POST';
export const SUBMIT_ENVIRONMENT_REQUEST_SUCCESS = '@@environment/submitEnvironmentRequest/SUCCESS';
export const SUBMIT_ENVIRONMENT_REQUEST_FAIL = '@@environment/submitEnvironmentRequest/FAIL';

export const SUBMIT_MAILBOXSSS_REQUEST = '@@mailboxSSS/submitMailboxSSSRequest/POST';
export const SUBMIT_MAILBOXSSS_REQUEST_SUCCESS = '@@mailboxSSS/submitMailboxSSSRequest/SUCCESS';
export const SUBMIT_MAILBOXSSS_REQUEST_FAIL = '@@mailboxSSS/submitMailboxSSSRequest/FAIL';


export const GET_ENVIRONMENTS = '@@environment/getEnvironments/GET';
export const GET_ENVIRONMENTS_SUCCESS = '@@environment/getEnvironments/SUCCESS';
export const GET_ENVIRONMENTS_FAIL = '@@environment/getEnvironmentRequest/FAIL';

export const GET_SHAREPOINT_DETAIL = '@@sharePoint/detail/GET';
export const GET_SHAREPOINT_DETAIL_SUCCESS = '@@sharePoint/detail/SUCCESS';
export const GET_SHAREPOINT_DETAIL_FAIL = '@@sharePoint/detail/FAIL';

export const GET_ENVIRONMENT_DETAIL = '@@environment/detail/GET';
export const GET_ENVIRONMENT_DETAIL_SUCCESS = '@@environment/detail/SUCCESS';
export const GET_ENVIRONMENT_DETAIL_FAIL = '@@environment/detail/FAIL';

export const SET_ENVIRONMENT_ATTESTATION = '@@environment/attestation/set/POST';
export const SET_ENVIRONMENT_ATTESTATION_FAIL = '@@environment/attestation/set/FAIL';
export const SET_ENVIRONMENT_ATTESTATION_SUCCESS = '@@environment/attestation/set/SUCCESS';

export const GET_LIST_MY_REQUEST_STATUS = '@@myRequestStatus/getListMyRequestStatus/GET';
export const GET_LIST_MY_REQUEST_STATUS_SUCCESS = '@@myRequestStatus/getListMyRequestStatus/SUCCESS';
export const GET_LIST_MY_REQUEST_STATUS_FAIL = '@@myRequestStatus/getListMyRequestStatus/FAIL';

export const GET_REQUEST_STATUS_Mailbox_SSS = '@@myRequestStatus/getRequestStatusMailboxSSS/GET';
export const GET_REQUEST_STATUS_Mailbox_SSS_SUCCESS = '@@myRequestStatus/getRequestStatusMailboxSSS/SUCCESS';
export const GET_REQUEST_STATUS_Mailbox_SSS_FAIL = '@@myRequestStatus/getRequestStatusMailboxSSS/FAIL';

export const GET_REQUEST_STATUS_Environment = '@@myRequestStatus/getRequestStatusEnvironment/GET';
export const GET_REQUEST_STATUS_Environment_SUCCESS = '@@myRequestStatus/getRequestStatusEnvironment/SUCCESS';
export const GET_REQUEST_STATUS_Environment_FAIL = '@@myRequestStatus/getRequestStatusEnvironment/FAIL';

export const GET_SERVICE_TREE_OBJECT = '@@serviceTree/getServiceTreeObject/GET';
export const GET_SERVICE_TREE_OBJECT_SUCCESS = '@@serviceTree/getServiceTreeObject/SUCCESS';
export const GET_SERVICE_TREE_OBJECT_FAIL = '@@serviceTree/getServiceTreeObject/FAIL';

export const GET_APP_LIST_OWNED = '@@appList/owned/GET';
export const GET_APP_LIST_OWNED_SUCCESS = '@@appList/owned/SUCCESS';
export const GET_APP_LIST_OWNED_FAIL = '@@appList/owned/FAIL';

export const DELETE_APP = '@@deleteApp/DELETE';
export const DELETE_APP_FAIL = '@@deleteApp/FAIL';
export const DELETE_APP_SUCCESS = '@@deleteApp/SUCCESS';

export const GET_APP_METADATA = '@@appList/metadata/GET';
export const GET_APP_METADATA_FAIL = '@@appList/metadata/FAIL';
export const GET_APP_METADATA_SUCCESS = '@@appList/metadata/SUCCESS';

export const SET_APP_METADATA = '@@appList/metadata/set/POST';
export const SET_APP_METADATA_FAIL = '@@appList/metadata/set/FAIL';
export const SET_APP_METADATA_SUCCESS = '@@appList/metadata/set/SUCCESS';

export const GET_PRIMARY_FTE_CONTACTS = '@@primaryFTEContacts/getprimaryFTEContactsList/GET';
export const GET_PRIMARY_FTE_CONTACTS_SUCCESS = '@@primaryFTEContacts/getprimaryFTEContactsList/SUCCESS';
export const GET_PRIMARY_FTE_CONTACTS_FAIL = '@@primaryFTEContacts/getprimaryFTEContactsList/FAIL';

export const GET_FEATURE_FLAGS = '@@featureFlags/GET';
export const GET_FEATURE_FLAGS_FAIL = '@@featureFlags/FAIL';
export const GET_FEATURE_FLAGS_SUCCESS = '@@featureFlags/SUCCESS';

export const GET_POLICIES = '@@policies/GET';
export const GET_POLICIES_SUCCESS = '@@policies/SUCCESS';
export const GET_POLICIES_FAIL = '@@policies/FAIL';

export const GET_SHAREPOINT_POLICIES = '@@sharePointPolicies/GET';
export const GET_SHAREPOINT_POLICIES_SUCCESS = '@@sharePointPolicies/SUCCESS';
export const GET_SHAREPOINT_POLICIES_FAIL = '@@sharePointPolicies/FAIL';

export const RESCAN_ENVIRONMENT = '@@environment/rescan/set/POST';
export const RESCAN_ENVIRONMENT_FAIL = '@@environment/rescan/set/FAIL';
export const RESCAN_ENVIRONMENT_SUCCESS = '@@environment/rescan/set/SUCCESS';

export const GET_SHAREPOINTSITES = '@@sharePointSite/getSharePointSites/GET';
export const GET_SHAREPOINTSITES_SUCCESS = '@@sharePointSite/getSharePointSites/SUCCESS';
export const GET_SHAREPOINTSITES_FAIL = '@@sharePointSite/getSharePointSites/FAIL';