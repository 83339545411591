import { Epic } from 'redux-observable';
import { from, of, timer } from 'rxjs';
import { filter, mergeMap, map, catchError, debounce } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { IServiceTreeObject } from '../../../models/ServiceTree/IServiceTreeObject';
import { getServiceTreeSearchUrl, httpGet } from '../../../services';
import {
  searchServiceTreeObject,
  searchServiceTreeObjectFail,
  searchServiceTreeObjectSuccess
} from '../../actions/serviceTree';
import { IRootState } from '../../IRootState';
import { RootActions } from '../../RootActions';

export const GetServiceTreeObjectEpic: Epic<RootActions, RootActions, IRootState> = (action$, _store) =>
  action$.pipe(debounce(() => timer(200))).pipe(
    filter(isActionOf(searchServiceTreeObject)),
    mergeMap((action$) => {
      return from(httpGet(getServiceTreeSearchUrl(action$.payload.serviceTreeId)))
        .pipe(
          map((res) => {
            return res.response as IServiceTreeObject[];
          })
        )
        .pipe(
          mergeMap((result) => {
            return of(searchServiceTreeObjectSuccess(result, action$.payload.fieldName));
          }),
          catchError((error) => {
            return of(searchServiceTreeObjectFail(error));
          })
        );
    })
  );

export default [GetServiceTreeObjectEpic];
