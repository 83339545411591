import { IAppMetadataRequest, initialAppMetadata } from '../models/AppsList/IAppMetadataRequest';
import { IListApprvalMailboxSSSRequestsState } from '../models/MailboxSSS/IListApprvalMailboxSSSRequestsState';
import { IListMailboxSSSRequestsState } from '../models/MailboxSSS/IMailboxSSSRequest';
import { IRevokedRoleAssignment } from '../models/aadPAP/roleAssignment/IRevokedRoleAssignment';
import { IRootState } from './IRootState';
import { ISubmitEnvironmentRequestState } from '../models/Environment/ISubmitEnvironmentRequestState';
import { IListMyRequestStatusState } from '../models/myRequestStatus/IMyRequestStatus';
import { IRequestStatusMailboxSSSState } from '../models/myRequestStatus/IRequestStatusMailboxSSS';
import { IRequestStatusEnvironmentState } from '../models/myRequestStatus/IRequestStatusEnvironment';
import { IServiceTreeState } from '../models/ServiceTree/IServiceTreeState';
import { IAppsRequestState } from '../models/AppsList/IAppsRequest';
import { emptySearchGraphObjectsInfo } from '../models/graph';
import { LoadingState } from '../models/ILoading';
import { IEnvironmentsRequest } from '../models/Environment/IEnvironmentRequest';
import { IEnvironmentDetailRequest, initialEnvironmentDetail } from '../models/Environment/IEnvironmentDetailRequest';
import { IPoliciesRequest } from '../models/policies/IPolicies';
import { ISubmitMailboxSSSRequestState } from '../models/MailboxSSS/ISubmitMailboxSSSRequestState';
import { ISharePointSitesLists } from '../models/SharepointSites/ISharePointSitesList';
import { ISharepointPoliciesRequest } from '../models/policies/ISharepointPolicies';
import { ISharePointDetailRequest, initialSharePointDetail } from '../models/SharepointSites/ISharePointDetailRequests';

export const initialRootState = {
  lstMailboxSSSRequestsState: { loadingState: LoadingState.notLoaded } as IListMailboxSSSRequestsState,
  lstApprvalMailboxSSSRequestsState: {} as IListApprvalMailboxSSSRequestsState,
  revokedRoleAssignment: {} as IRevokedRoleAssignment,
  submitEnvironmentRequestState: { loadingState: LoadingState.notLoaded } as ISubmitEnvironmentRequestState,
  submitMailboxSSSRequestState: { loadingState: LoadingState.notLoaded } as ISubmitMailboxSSSRequestState,
  lstMyRequestStatusState: {} as IListMyRequestStatusState,
  lstMyAppsRequestState: { isLoading: false, LoadingState: LoadingState.notLoaded, apps: [] } as IAppsRequestState,
  deleteMyAppRequestState: { isLoading: false, LoadingState: LoadingState.notLoaded },
  requestStatusMailboxSSSState: {} as IRequestStatusMailboxSSSState,
  requestStatusEnvironmentState: {} as IRequestStatusEnvironmentState,
  serviceTreeState: { isLoading: false, loadingState: LoadingState.notLoaded } as IServiceTreeState,
  primaryFTEContactsState: { ...emptySearchGraphObjectsInfo },
  featureFlagsRequestState: { isLoading: false, loadingState: LoadingState.notLoaded, features: {} },
  setAppMetadataRequestState: { isLoading: false, loadingState: LoadingState.notLoaded },
  setEnvironmentRescanRequestState: { isLoading: false, loadingState: LoadingState.notLoaded },
  getAppMetadataRequestState: {
    isLoading: false,
    loadingState: LoadingState.notLoaded,
    data: initialAppMetadata
  } as IAppMetadataRequest,
  getEnvironmentsState: {
    isLoading: false,
    loadingState: LoadingState.notLoaded
  } as IEnvironmentsRequest,
  getEnvironmentDetailRequestState: {
    isLoading: false,
    loadingState: LoadingState.notLoaded,
    data: initialEnvironmentDetail
  } as IEnvironmentDetailRequest,
  setEnvironmentAttestationRequestState: { isLoading: false, loadingState: LoadingState.notLoaded },
  policiesRequestState: {
    isLoading: false,
    loadingState: LoadingState.notLoaded,
    data: {}
  } as IPoliciesRequest,
  sharePointPoliciesRequestState: {
    isLoading: false,
    loadingState: LoadingState.notLoaded,
    data: {}
  } as ISharepointPoliciesRequest,
  getSharePointDetailRequestState: {
    isLoading: false,
    loadingState: LoadingState.notLoaded,
    data: initialSharePointDetail
  } as ISharePointDetailRequest,
  getSharePointSitesState: {
    isLoading: false,
    loadingState: LoadingState.notLoaded
  } as ISharePointSitesLists
} as unknown as IRootState;
