/* eslint-disable @typescript-eslint/no-explicit-any */
export const isNullOrUndefined = (object: any) => {
  return object === null || object === undefined;
};

export const validObj = (obj: object | undefined | null) => (isNullOrUndefined(obj) ? null : obj);

export const validStr = (val: object | undefined | null) => {
  if (isNullOrUndefined(val)) {
    return '';
  }
  if (typeof val !== 'string' && !(val instanceof String)) {
    return '';
  }
  return val;
};

// export const deepClone = (obj: Object) => {
//   return JSON.parse(JSON.stringify(obj));
// };

// export const isObjectEquivalent = (a: Object, b: Object) => {
//   // if (isNullOrUndefined(a) && isNullOrUndefined(b)) {
//   //   return true;
//   // }

//   if (isNullOrUndefined(a) || isNullOrUndefined(b)) {
//     return false;
//   }
//   // Create arrays of property names
//   let aProps = Object.getOwnPropertyNames(a);
//   let bProps = Object.getOwnPropertyNames(b);

//   // If number of properties is different,
//   // objects are not equivalent
//   if (aProps.length !== bProps.length) {
//     return false;
//   }

//   for (let i = 0; i < aProps.length; i++) {
//     let propName = aProps[i];

//     // If values of same property are not equal,
//     // objects are not equivalent
//     if (a[propName] !== b[propName]) {
//       return false;
//     }
//   }

//   // If we made it this far, objects
//   // are considered equivalent
//   return true;
// };

// TODO change to isEmpty to isObjectEmpty
// export const isEmpty = (obj: Object) => {
//   if (isNullOrUndefined(obj)) {
//     return true;
//   }
//   for (let key in obj) {
//     if (obj.hasOwnProperty(key)) {
//       return false;
//     }
//   }
//   return true;
// };

// export const compareObjectList = (arr1: Object[], arr2: Object[]) => {
//   if (isUndefined(arr1) || isUndefined(arr2)) {
//     return false;
//   }
//   if (arr1.length !== arr2.length) {
//     return false;
//   }
//   for (let i = 0, len = arr1.length; i < len; i++) {
//     if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
//       return false;
//     }
//   }
//   return true;
// };
