/* eslint-disable jsx-a11y/no-noninteractive-tabindex */  
import * as React from 'react';
import { Icon, Label, Stack, css } from "@fluentui/react";
import { ISharePointBasicDetail, ISharePointBasicDetailProps } from "./SharePointViolation.model";
import { endSpacingTokens, violaltionStyle } from "./SharePointViolation.style";

export const SharePointViolationDetail: React.FunctionComponent<ISharePointBasicDetailProps> = (props) => {
    const detail: ISharePointBasicDetail | null = props.basicDetails;
  
    return (
      <Stack tokens={endSpacingTokens} className={violaltionStyle.detailWrapper}>
        <Stack horizontal>
            <Icon
              className={violaltionStyle.openAppIconStyle}
              onClick={() => {
                window.open(detail?.assetUri, '_blank', 'noopener,noreferrer');
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  window.open(detail?.assetUri, '_blank', 'noopener,noreferrer');
                }
              }}
              iconName="OpenInNewWindow"
              aria-label="Open Site link"
              tabIndex={0}
            />
          <span style={{ padding: '0px 4px', fontSize: '14px', color: '#201F1E' }}>Open Site</span>
        </Stack>
        <Stack>
          <Label className={violaltionStyle.labelSize}>SharePoint:</Label>
          <div className={violaltionStyle.labelSize}>{detail?.displayName}</div>
        </Stack>
        {detail?.desc ? (
          <Stack>
            <Label className={css(violaltionStyle.labelSize)}>Loop WorkSpace URL:</Label>
            <div className={css(violaltionStyle.labelSize)}>{detail?.workspaceUrl || 'NA'}</div>
          </Stack>
        ) : (
          <Stack>
            <Label className={css(violaltionStyle.labelSize)}>Description:</Label>
            <div className={css(violaltionStyle.labelSize, violaltionStyle.disabledLabel)}> {'Not Available'}</div>
          </Stack>
        )}
      </Stack>
    );
  };