import { toast, ToastOptions } from 'react-toastify';
import { IHttpError } from '../../models/IHttpError';

export const toastSuccess = (successMessage: string, opts?: ToastOptions | undefined) => {
  toast.success(successMessage, opts);
};

/* eslint-disable no-param-reassign */
export const toastError = (errorMessage: string, httpError: IHttpError, opts?: ToastOptions | undefined) => {
  if (opts) {
    opts.autoClose = false;
  } else {
    opts = { autoClose: false };
  }
  if (httpError.response != null) {
    toast.error(`${errorMessage} [${httpError.status}]: ${httpError.response.error}`, opts);
  } else {
    toast.error(`${errorMessage} [${httpError.status}]: ${httpError.message}`, opts);
  }
};
