import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, map, catchError, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { IDisplayItemRoleAssignment } from '../../../models/aadPAP/roleAssignment/ITableDisplayRoleAssignment';
import { deleteRoleAssignment, deleteRoleAssignmentFail, deleteRoleAssignmentSuccess } from '../../actions';
import { IRootState } from '../../IRootState';
import { RootActions } from '../../RootActions';
import { revokeRoleAssignmentUrl, httpDelete } from '../../../services';

export const DeleteRoleAssignmentRequestEpic: Epic<RootActions, RootActions, IRootState> = (action$, _store) =>
  action$.pipe(
    filter(isActionOf(deleteRoleAssignment)),
    mergeMap((action$) => {
      return from(httpDelete(revokeRoleAssignmentUrl(action$.payload.id, action$.payload.appId)))
        .pipe(
          map((res) => {
            return res.response as IDisplayItemRoleAssignment;
          })
        )
        .pipe(
          mergeMap((result) => {
            return of(deleteRoleAssignmentSuccess(result));
          }),
          catchError((error) => {
            return of(deleteRoleAssignmentFail(error));
          })
        );
    })
  );

export default [DeleteRoleAssignmentRequestEpic];
