import { createAction } from 'typesafe-actions';
import { IHttpError } from '../../../models/IHttpError';
import { IMyRequestStatus } from '../../../models/myRequestStatus/IMyRequestStatus';
import {
  GET_LIST_MY_REQUEST_STATUS,
  GET_LIST_MY_REQUEST_STATUS_SUCCESS,
  GET_LIST_MY_REQUEST_STATUS_FAIL
} from '../../constants';

export const getListMyRequestStatus = createAction(GET_LIST_MY_REQUEST_STATUS, () => {})();

// export const getListMyRequestStatus = createAction(
//   GET_LIST_MY_REQUEST_STATUS,
//   (query: IRoleAssignmentQueryParams) => query,
//   // (appId: string) => appId
// )<IRoleAssignmentQueryParams>();

export const getListMyRequestStatusSuccess = createAction(
  GET_LIST_MY_REQUEST_STATUS_SUCCESS,
  (lstMyRequestStatus: IMyRequestStatus[], hasMore: boolean) => ({ lstMyRequestStatus, hasMore })
)<{ lstMyRequestStatus: IMyRequestStatus[]; hasMore: boolean }>();

export const getListMyRequestStatusFail = createAction(
  GET_LIST_MY_REQUEST_STATUS_FAIL,
  (err: IHttpError) => err
)<IHttpError>();
