import { IStyle, mergeStyleSets, mergeStyles } from '@fluentui/react';

export const getDividerLineWidth = (steps: number) => {
  switch (steps) {
    case 1:
    case 2:
      return mergeStyles({ width: `calc(300%/${steps - 1} - 15%)` });
    case 3:
      return mergeStyles({ width: '125%' });
    case 4:
      return mergeStyles({ width: '71%' });
    case 5:
      return mergeStyles({ width: `calc(61%)` });
    case 6:
      return mergeStyles({ width: `calc(58%)` });
    case 7:
      return mergeStyles({ width: `calc(55%)` });
    default:
      return mergeStyles({ width: `calc(((435% - ${steps} * 20px) / ${steps}) - 25%)` });
  }
};

export const HorizontalProgressStepperStyles = mergeStyleSets({
  ProgressStepperWrapperHorizontal: {
    display: 'flex',
    marginBottom: '10px',
    width: '100%',
    justifyContent: 'center'
  } as IStyle,
  ProgressStepperWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'top',
    paddingLeft: '4px',
    paddingRight: '4px',
    position: 'relative',
    fontSize: '13px',
    width: '20%'
  } as IStyle,
  ProgressStepperNumber: {
    borderRadius: '80%',
    border: '1px solid grey',
    width: '20px',
    height: '20px',
    padding: '3px',
    textAlign: 'center'
  } as IStyle,
  iconWrapper: {
    width: '140%',
    display: 'flex',
    justifyContent: 'center'
  } as IStyle,
  horizBarWrapper: {
    width: 'calc(50% - 15px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  } as IStyle,
  horizBar: {
    height: '1px',
    width: '100%',
    backgroundColor: 'grey'
  } as IStyle,
  dividerLine: {
    height: '1px',
    backgroundColor: '#bdbdbd',
    position: 'absolute',
    top: '19%',
    left: '70%'
  } as IStyle,
  descriptionLg: {
    fontSize: '18px',
    fontWeight: '600',
    textAlign: 'center'
  },
  descriptionM: {
    fontSize: '14px',
    fontWeight: '600',
    textAlign: 'center'
  },
  descriptionSm: {
    fontSize: '10px',
    fontWeight: '600',
    textAlign: 'center'
  },
  descriptionXS: {
    fontSize: '6px',
    fontWeight: '600',
    textAlign: 'center'
  },
  subDescription: {},
  linkWithoutOnclick: {
    padding: '1px',
    lineHeight: '16px',
    fontSize: '16px'
  },
  colorCodeW: {
    color: '#ffffff'
  },
  colorCodeB: {
    color: '#000000'
  }
});

export const VerticalProgressStepperStyles = mergeStyleSets({
  ProgressStepperWrapperVertical: {},
  ProgressStepperWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.2rem',
    position: 'relative'
  },
  ProgressStepperNumber: {
    borderRadius: '50%',
    width: '18px',
    height: '18px',
    padding: '4.5px 4px 4px',
    textAlign: 'center',
    fontSize: '95%'
  },
  stepDescription: { marginLeft: '1.2rem', paddingBottom: '3px' },
  dividerLine: {
    height: '17px',
    width: '1px',
    backgroundColor: '#bdbdbd',
    position: 'absolute',
    top: '100%',
    left: '6.4%'
  }
});

export const ProgressStepperStateStyles = mergeStyleSets({
  stepNumberEmpty: {
    border: '4px double #9b9b9b',
    color: 'gray'
  },
  stepNumberInProgress: {
    border: '4px dashed #0078D4',
    color: '#0078D4'
  },
  stepNumberActive: {
    border: '4px double #0078D4',
    color: '#0078D4',
    fontWeight: 'bolder'
  },
  stepNumberActiveError: {
    border: '4px solid Red',
    color: 'Red',
    fontWeight: 'bolder',
    button: {
      color: 'Red'
    }
  },
  stepNumberComplete: {
    border: '4px double #ffffff',
    background: '#0078D4',
    color: '#FFFFFF',
    button: {
      color: '#FFFFFF'
    }
  },
  stepNumberError: {
    border: '4px solid red',
    background: 'red',
    color: '#FFFFFF',
    button: {
      color: '#FFFFFF'
    }
  }
});

export const ProgressStepperHideTextStyle = mergeStyleSets({
  hideText : {
    position: 'absolute',
    clip: 'rect(1px 1px 1px 1px)'
  }
})
