import { createAction } from 'typesafe-actions';
import { IDisplayItemRoleAssignment } from '../../../models/aadPAP/roleAssignment';
import { IHttpError } from '../../../models/IHttpError';
import {
  ONE_ACCESS_REVOKE_ASSIGNMENT,
  ONE_ACCESS_REVOKE_ASSIGNMENT_SUCCESS,
  ONE_ACCESS_REVOKE_ASSIGNMENT_FAIL
} from '../../constants';

export const deleteRoleAssignment = createAction(
  ONE_ACCESS_REVOKE_ASSIGNMENT,
  (roleAssignment: IDisplayItemRoleAssignment) => roleAssignment)<IDisplayItemRoleAssignment>();

export const deleteRoleAssignmentSuccess = createAction(ONE_ACCESS_REVOKE_ASSIGNMENT_SUCCESS, (roleAssignment: IDisplayItemRoleAssignment) => roleAssignment)<IDisplayItemRoleAssignment>();

export const deleteRoleAssignmentFail = createAction(ONE_ACCESS_REVOKE_ASSIGNMENT_FAIL, (err: IHttpError) => err)<
  IHttpError
>();
