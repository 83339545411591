/* eslint-disable no-else-return */
/* eslint-disable no-loop-func */
import * as Yup from 'yup';
import { GUID_PATTERN } from '../../../shared/utils/guid';
import 'regenerator-runtime/runtime';

export interface IEnvironmentRequest {
  requestorEmail: string;
  displayName: string;
  primaryFTEContacts: string;
  owningTeamGuid: string;
  bussinessTeamGuid: string | null;
  environmentDescription: string;
  environmentType: string;
  environmentPurpose: string;
  sku: string;
  requestedDLPPolicy: string;
  CDSidentifier: string;
  CDSinstanceURL: string;
  region: string;
  currency: string;
  language: string;
  existingSecurityGroup: string;
  dataClassification: string;
  hipaa: boolean;
  tentedInformation: boolean;
  gdpr: boolean;
  soxApplication: boolean;
  businessJustification: string;
  serviceTreeId: string | null;
}

export interface EnvironmentRequestFormValue extends IEnvironmentRequest {}

export const initialEnvironmentRequestFormValue = {
  requestorEmail: '',
  displayName: '',
  // primaryFTEContacts: (authContext.getCachedUser() && authContext.getCachedUser().userName) || '',
  primaryFTEContacts: '',
  owningTeamGuid: '',
  bussinessTeamGuid: null,
  businessJustification: '',
  environmentDescription: '',
  environmentType: 'Power Platform(without Dataverse)',
  environmentPurpose: 'Pre-Production',
  sku: 'Sandbox',
  requestedDLPPolicy: 'Enterprise Standard Developer',
  CDSidentifier: '',
  CDSinstanceURL: '',
  region: 'United States',
  currency: 'USD',
  language: '1033',
  existingSecurityGroup: '',
  dataClassification: 'General',
  hipaa: false,
  tentedInformation: false,
  gdpr: false,
  soxApplication: false,
  serviceTreeId: null
} as EnvironmentRequestFormValue;

export const environmentRequestFormValueValidationSchema = Yup.object({
  requestorEmail: Yup.string().required('requester is required!'),
  displayName: Yup.string().min(5, 'Must be 5 characters or more').required('Display Name field is Required'),
  primaryFTEContacts: Yup.string().test({
    name: 'FTE-Contacts-Validation',
    // eslint-disable-next-line func-names
    test: function (value) {
      const { createError } = this;
      const validDomains = ['microsoft.com', 'linkedin.biz'];
      if (value) {
        const values = value.split(';');
        let count = 0;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        if (this.parent.requestorEmail.indexOf('-') > 0) {
          for (let i = 0; i < values.length; i += 1) {
            if (!validDomains.some(domain => values[i].endsWith(domain))) {
              return createError({
                message: `${values[i]} is not a valid email address`,
                path: 'primaryFTEContacts'
              });
            } else if (values[i].indexOf('-') < 1) {
              count +=1;
            } else if (values[i].indexOf('#EXT#') > 0) {
              return createError({
                message: `${values[i]} is an external account`,
                path: 'primaryFTEContacts'
              });
            }
          }
          if(count < 1){
            return createError({
              message: `Choose atleast one FTE`,
              path: 'primaryFTEContacts'
            });
          }
          return true;
        }
        else{
          for (let i = 0; i < values.length; i += 1) {
            if (!validDomains.some(domain => values[i].endsWith(domain))) {
              return createError({
                message: `${values[i]} is not a valid email address`,
                path: 'primaryFTEContacts'
              });
            } 
            else if (values[i].indexOf('#EXT#') > 0) {
              return createError({
                message: `${values[i]} is an external account`,
                path: 'primaryFTEContacts'
              });
            }
          }
        }
       
        return true;
      }
      return createError({
        message: 'Additional Environment Admins field is required',
        path: 'primaryFTEContacts'
      });
    }
  }),
  owningTeamGuid: Yup.string()
  .required('Owning Team Id name field is Required')
  .matches(new RegExp(GUID_PATTERN), 'Owning Team Id should be valid Guid'),
  // bussinessTeamGuid: Yup.string().matches(new RegExp(GUID_PATTERN), 'Business Team Id should be valid Guid'),
  businessJustification: Yup.string().required('Business Justification field is Required'),
  environmentDescription: Yup.string().required('Environment Description field is Required'),
  hipaa: Yup.boolean().required('required'),
  tentedInformation: Yup.boolean().required('required'),
  gdpr: Yup.boolean().required('required'),
  soxApplication: Yup.boolean().required('required'),
  existingSecurityGroup: Yup.string().optional(),
  CDSidentifier: Yup.string().when('environmentType',{
    is: (val: string) => ['Dataverse Org','D365'].includes(val),
    then: Yup.string()
    .matches(/^(?!.*\.crm\.dynamics\.com).+$/, 'Domain should not contain .crm.dynamics.com')
    .max(32, 'Domain length can be no greater than 32')
    .matches(/^(?!.*--)([a-zA-z0-9])([a-zA-z0-9-]*)([a-zA-z0-9])$/i, 'Please enter a valid domain name')
  })
});
