/* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call */

import { connect } from 'react-redux';
import { getFeatureFlags, getPolicies } from '../shared/store/actions';
import { IRootState } from '../shared/store/IRootState';
import { App } from './App';
import { ConnectedAppProps, AppActions, AppMappedProps } from './App.types';
import { getSharePointPolicies } from '../shared/store/actions/policies/getSharepointPolicies';

/**
 * Maps the external (i.e. own) props and the state to the properties of the App control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IAppProps) for the App control
 */
const mapStateToProps = (_state: IRootState, _ownProps: ConnectedAppProps): AppMappedProps => {
  const { featureFlagsRequestState, policiesRequestState, sharePointPoliciesRequestState } = _state;
  return {
    featureFlagsState: featureFlagsRequestState,
    policiesRequestState: policiesRequestState,
    sharePointPoliciesRequestState: sharePointPoliciesRequestState
  };
};

/**
 * Maps the dispatch actions to the props for the App control.
 * @param dispatch Dispatches actions to the store
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IAppProps) for the App control
 */
const mapDispatchToProps = (dispatch: any): AppActions => {
  return {
    getFeatureFlags: () => dispatch(getFeatureFlags()),
    getPolicies: () => dispatch(getPolicies()),
    getSharepointPolicies: () => dispatch(getSharePointPolicies())
  };
};

/**
 * A redux connected App control.
 * @description Supports translation
 */
export const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
