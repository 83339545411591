import { IDropdownOption } from "@fluentui/react";

export const DataClassificationOptions : IDropdownOption[] = [
  {
    key: 0,
    text: 'Non Business'
  },
  {
    key: 1,
    text: 'Public'
  },
  {
    key: 2,
    text: 'General'
  },
  {
    key: 3,
    text: 'Confidential'
  },
  {
    key: 4,
    text: 'Highly Confidential'
  }
];

export const Purpose = {
  0: 'Pre-Production',
  1: 'Production'
};

export const EXECUTED_POLICIES = {
  ATTESTATION_POLICY: 'Governance.Core.Policies.AttestationPolicy'
}

export const EXECUTED_POLICIES_RESULT = {
  PASS: 'Pass',
  FAIL: 'Fail'
}

