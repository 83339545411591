import {
  ScrollablePane,
  CommandBar,
  css,
  ShimmeredDetailsList,
  CheckboxVisibility,
  DetailsListLayoutMode,
  Pivot,
  PivotItem,
  Label
} from '@fluentui/react';
import * as React from 'react';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { RequestStatusCreateMailSSSProps, RequestStatusCreateMailSSSState } from './RequestStatusCreateMailSSS.types';
import { getRequestStatusCreateMailSSSCommandBarItems } from './RequestStatusCreateMailSSS.commands';
import { getTableRequestStatusCreateMailSSSColumns } from './RequestStatusCreateMailSSS.columns';
import {
  RequestStatusCreateMailSSSPageCommandBarStyle,
  RequestStatusCreateMailSSSPageContentStyles,
  RequestStatusCreateMailSSSPageStyle,
  RequestStatusCreateMailSSSPivotStyle
} from './RequestStatusCreateMailSSS.styles';
import { _Styles } from '../../../shared/styles/Page.styles';
import { renderEveryOtherRow } from '../../../shared/utils/fabric/renderEveryOtherRow';
import { isNullOrUndefined } from '../../../shared/utils/tools/object';
import ProgressStepper from '../../../components/ProgressStepper/ProgressStepper';
import {
  ProgressStepperNodeState,
  ProgressStepperNodeTypes
} from '../../../components/ProgressStepper/ProgressStepper.types';
import { dateTimeToLocalString } from '../../../shared/utils/tools/string';
import Loading from '../../../components/Loading/Loading';

const EmptyMailboxSSSSteps = [
  {
    description: 'Submission',
    nodeType: ProgressStepperNodeTypes.Empty
  } as ProgressStepperNodeState,
  {
    description: 'Owner Approval',
    nodeType: ProgressStepperNodeTypes.Empty
  } as ProgressStepperNodeState,
  {
    description: 'GA Approval',
    nodeType: ProgressStepperNodeTypes.Empty
  } as ProgressStepperNodeState,
  {
    description: 'Mailbox Sync Enabled',
    nodeType: ProgressStepperNodeTypes.Empty
  } as ProgressStepperNodeState
];

export class RequestStatusCreateMailSSS extends React.Component<
  RequestStatusCreateMailSSSProps,
  RequestStatusCreateMailSSSState
> {
  constructor(props: RequestStatusCreateMailSSSProps) {
    super(props);
    this.state = {
      columns: getTableRequestStatusCreateMailSSSColumns(),
      currentStepNumber: -1,
      lstSteps: [...EmptyMailboxSSSSteps]
    };
  }

  public componentDidMount() {
    this.refresh();
  }

  public componentDidUpdate(preProps: RequestStatusCreateMailSSSProps, _preState: RequestStatusCreateMailSSSState) {
    if (
      preProps.requestStatusMailboxSSSState.isLoading === true &&
      this.props.requestStatusMailboxSSSState.isLoading === false
    ) {
      if (this.props.requestStatusMailboxSSSState.lstAuditLogs === null) {
        return;
      }

      const lstAuditLogs = [...this.props.requestStatusMailboxSSSState.lstAuditLogs];
      // eslint-disable-next-line react/no-access-state-in-setstate
      const lstSteps = [...this.state.lstSteps];
      for (let i = 0; i < lstAuditLogs.length; i += 1) {
        switch (lstAuditLogs[i].footPace) {
          case 'ReadyForOwnerConsent':
            lstSteps[0].nodeType = ProgressStepperNodeTypes.Complete;
            lstSteps[0].subDescription = `Complete ${dateTimeToLocalString(lstAuditLogs[i].timeStamp)}`;
            break;
          case 'RequestProcessed':
            if (
              this.props.requestStatusMailboxSSSState.mailboxSSSRequest.requesterEmail ===
              this.props.requestStatusMailboxSSSState.mailboxSSSRequest.mailboxEmail
            ) {
              lstSteps[0].nodeType = ProgressStepperNodeTypes.Complete;
              lstSteps[0].subDescription = `Complete ${dateTimeToLocalString(lstAuditLogs[i].timeStamp)}`;
            }
            lstSteps[1].nodeType = ProgressStepperNodeTypes.Complete;
            lstSteps[1].subDescription = `Complete ${dateTimeToLocalString(lstAuditLogs[i].timeStamp)}`;
            break;
          case 'Stage1ApprovalReplied':
            lstSteps[2].nodeType = ProgressStepperNodeTypes.Complete;
            lstSteps[2].subDescription = `Complete ${dateTimeToLocalString(lstAuditLogs[i].timeStamp)}`;
            break;
          case 'RequestRejectedWithNotification':
            if (lstAuditLogs[i].status === 'Global Admin rejected') {
              lstSteps[2].nodeType = ProgressStepperNodeTypes.Error;
              lstSteps[2].subDescription = `Rejected ${dateTimeToLocalString(lstAuditLogs[i].timeStamp)}`;
            } else {
              lstSteps[1].nodeType = ProgressStepperNodeTypes.Error;
              lstSteps[1].subDescription = `Rejected ${dateTimeToLocalString(lstAuditLogs[i].timeStamp)}`;
            }
            break;
          case 'ProvisionComplete':
            lstSteps[3].nodeType = ProgressStepperNodeTypes.Complete;
            lstSteps[3].subDescription = `Complete ${dateTimeToLocalString(lstAuditLogs[i].timeStamp)}`;
            break;
          case 'RequestErrorWithNotification':
            for (let j = 0; lstSteps.length; j += 1) {
              if (lstSteps[j].subDescription === undefined) {
                lstSteps[j].nodeType = ProgressStepperNodeTypes.Error;
                lstSteps[j].subDescription = `Error ${dateTimeToLocalString(lstAuditLogs[i].timeStamp)}`;
                break;
              }
            }
            break;
          default:
            break;
        }
      }

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        lstSteps: lstSteps
      });
    }
  }

  public render(): React.ReactNode {
    const commands = getRequestStatusCreateMailSSSCommandBarItems(this.refresh);
    return (
      <ScrollablePane
        className={this.props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}
      >
        <main
          data-automation-id="RequestStatusCreateMailSSS"
          className={css(RequestStatusCreateMailSSSPageStyle.pageBackground)}
          tabIndex={-1}
        >
          <PageHeader pageTitle={'Request Status Create Mailbox SSS'} />
          <CommandBar
            styles={RequestStatusCreateMailSSSPageCommandBarStyle}
            items={commands}
            ariaLabel={'Request status detail Commands'}
          />
          <ProgressStepper
            lstSteps={this.state.lstSteps}
            enableSubDescription={true}
            isVertical={false}
            currentStepNumber={this.state.currentStepNumber}
            onStepClicked={this.onStepClicked}
          />
          {this.props.requestStatusMailboxSSSState.isLoading ? (
            <Loading />
          ) : (
            <Pivot aria-label="Mailbox SSS detail request status" styles={RequestStatusCreateMailSSSPivotStyle}>
              <PivotItem
                headerText="Request Details"
                headerButtonProps={{
                  'data-order': 2,
                  'data-title': 'Request Details'
                }}
              >
                {this.props.requestStatusMailboxSSSState.isLoading === false &&
                this.props.requestStatusMailboxSSSState.mailboxSSSRequest !== null ? (
                  <div className={RequestStatusCreateMailSSSPageContentStyles.detailInfo}>
                    <div className={RequestStatusCreateMailSSSPageContentStyles.detailLine}>
                      <Label className={RequestStatusCreateMailSSSPageContentStyles.detailLabel}>Request Id</Label>
                      <div className={RequestStatusCreateMailSSSPageContentStyles.detailData}>
                        {this.props.requestStatusMailboxSSSState.mailboxSSSRequest.requestId}
                      </div>
                    </div>
                    <div className={RequestStatusCreateMailSSSPageContentStyles.detailLine}>
                      <Label className={RequestStatusCreateMailSSSPageContentStyles.detailLabel}>
                        CRM Organization URL
                      </Label>
                      <div className={RequestStatusCreateMailSSSPageContentStyles.detailData}>
                        {this.props.requestStatusMailboxSSSState.mailboxSSSRequest.crmOrganizationUrl}
                      </div>
                    </div>
                    <div className={RequestStatusCreateMailSSSPageContentStyles.detailLine}>
                      <Label className={RequestStatusCreateMailSSSPageContentStyles.detailLabel}>Mailbox Email</Label>
                      <div className={RequestStatusCreateMailSSSPageContentStyles.detailData}>
                        {this.props.requestStatusMailboxSSSState.mailboxSSSRequest.mailboxEmail}
                      </div>
                    </div>
                    <div className={RequestStatusCreateMailSSSPageContentStyles.detailLine}>
                      <Label className={RequestStatusCreateMailSSSPageContentStyles.detailLabel}>
                        Enable Incoming Email Delivery
                      </Label>
                      <div className={RequestStatusCreateMailSSSPageContentStyles.detailData}>
                        {this.props.requestStatusMailboxSSSState.mailboxSSSRequest.enableIncomingEmailDelivery
                          ? 'True'
                          : 'False'}
                      </div>
                    </div>
                    <div className={RequestStatusCreateMailSSSPageContentStyles.detailLine}>
                      <Label className={RequestStatusCreateMailSSSPageContentStyles.detailLabel}>
                        Enable Outgoing Email Delivery
                      </Label>
                      <div className={RequestStatusCreateMailSSSPageContentStyles.detailData}>
                        {this.props.requestStatusMailboxSSSState.mailboxSSSRequest.enableOutgoingEmailDelivery
                          ? 'True'
                          : 'False'}
                      </div>
                    </div>
                    <div className={RequestStatusCreateMailSSSPageContentStyles.detailLine}>
                      <Label className={RequestStatusCreateMailSSSPageContentStyles.detailLabel}>
                        Enable Appointments, Contacts and Tasks
                      </Label>
                      <div className={RequestStatusCreateMailSSSPageContentStyles.detailData}>
                        {this.props.requestStatusMailboxSSSState.mailboxSSSRequest.enableActDeliveryMethod
                          ? 'True'
                          : 'False'}
                      </div>
                    </div>
                    <div className={RequestStatusCreateMailSSSPageContentStyles.detailLine}>
                      <Label className={RequestStatusCreateMailSSSPageContentStyles.detailLabel}>
                        Enable Email From Date (Local Time)
                      </Label>
                      <div className={RequestStatusCreateMailSSSPageContentStyles.detailData}>
                        {dateTimeToLocalString(
                          this.props.requestStatusMailboxSSSState.mailboxSSSRequest.processEmailFrom
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </PivotItem>
              <PivotItem
                headerText="History"
                headerButtonProps={{
                  'data-order': 1,
                  'data-title': 'History'
                }}
              >
                <ShimmeredDetailsList
                  setKey="requestId"
                  className={RequestStatusCreateMailSSSPageContentStyles.table}
                  ariaLabelForGrid={'My Mailbox SSS Request Statuses'}
                  ariaLabelForSelectionColumn="Toggle my request status selection"
                  isHeaderVisible={true}
                  checkboxVisibility={CheckboxVisibility.hidden}
                  items={
                    !isNullOrUndefined(this.props.requestStatusMailboxSSSState.lstAuditLogs) &&
                    !this.props.requestStatusMailboxSSSState.isLoading
                      ? this.props.requestStatusMailboxSSSState.lstAuditLogs
                      : []
                  }
                  // onColumnHeaderClick={this.onColumnClick}
                  enableShimmer={this.props.requestStatusMailboxSSSState.isLoading}
                  onRenderRow={renderEveryOtherRow}
                  columns={this.state.columns}
                  layoutMode={DetailsListLayoutMode.fixedColumns}
                />
              </PivotItem>
            </Pivot>
          )}
        </main>
      </ScrollablePane>
    );
  }

  private refresh = () => {
    const lstSteps = [...this.state.lstSteps];
    for (let i = 0; i < lstSteps.length; i += 1) {
      lstSteps[i].subDescription = undefined;
      lstSteps[i].nodeType = ProgressStepperNodeTypes.Empty;
    }

    this.setState({
      lstSteps: [...lstSteps]
    });
    this.props.getRequestStatusMailSSS(
      this.props.match.params.requestId === undefined ? '' : this.props.match.params.requestId
    );
  };

  private onStepClicked = (index: number) => {
    this.setState({
      currentStepNumber: index
    });
  };
}
