import { isActionOf } from 'typesafe-actions';
import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, catchError, mergeMap } from 'rxjs/operators';
import { IRootState } from '../../IRootState';
import { RootActions } from '../../RootActions';
import { getAppMetadataUrl, httpPost } from '../../../services';
import { setAppMetadata, setAppMetadataFailed, setAppMetadataSucceeded } from '../../actions/appsList/actions';

export const SetAppMetadataEpic: Epic<RootActions, RootActions, IRootState> = (action$, _store) =>
  action$.pipe(
    filter(isActionOf(setAppMetadata)),
    mergeMap((action$) => {
      return from(
        httpPost(getAppMetadataUrl(action$.payload.appId), action$.payload.data).pipe(
          mergeMap(() => {
            return of(setAppMetadataSucceeded());
          }),
          catchError((error) => {
            return of(setAppMetadataFailed(error));
          })
        )
      );
    })
  );

export default [SetAppMetadataEpic];
