import { createReducer } from 'typesafe-actions';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { toastError } from '../../../utils/tools/toast';
import { getFeatureFlags, getFeatureFlagsSucceeded, getFeatureFlagsFailed } from '../../actions';
import { LoadingState } from '../../../models/ILoading';

export const getFeatureFlagsReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(getFeatureFlags, (state) => {
    return {
      ...state,
      featureFlagsRequestState: {
        ...state.featureFlagsRequestState,
        features: {},
        isLoading: true,
        loadingState: LoadingState.loading
      }
    };
  })
  .handleAction(getFeatureFlagsSucceeded, (state, action) => {
    return {
      ...state,
      featureFlagsRequestState: {
        ...state.featureFlagsRequestState,
        features: action.payload,
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(getFeatureFlagsFailed, (state, action) => {
    toastError('Failed to fetch feature flags', action.payload);
    return {
      ...state,
      featureFlagsRequestState: {
        ...state.featureFlagsRequestState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    }
  })
