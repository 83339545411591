import { IDocumentCardStyles, IStackItemStyles, IStackTokens, IStyle, mergeStyleSets } from '@fluentui/react';

export const HomePageStyle = mergeStyleSets({
  pageBackground: {
    selectors: {
      ':global(.ms-DetailsList)': {
        overflow: 'visible'
      }
    }
  } as IStyle
});

export const HomePageStackItemStyles: IStackItemStyles = {
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  }
};

export const HomePageCustomSpacingStackTokens: IStackTokens = {
  padding: 's1 s1'
};

export const HomePageCardStyles: IDocumentCardStyles = {
  root: {
    display: 'inline-block',
    marginRight: 25,
    marginLeft: 25,
    marginBottom: 20,
    width: 320,
    boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px',
    transition: 'all .3s ease-in-out',
    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.22) 0px 19px 43px',
      transform: 'translate3d(0px, -1px, 0px)'
    }
  }
};
